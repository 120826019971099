import LoaderComponent from "@/components/common/LoaderComponent.vue";
import DTHeaderComponent from "@/components/common/datatable/DTHeaderComponent.vue";
import DTComponent from "@/components/common/datatable/DTComponent.vue";
import DTPaginatorComponent from "@/components/common/datatable/DTPaginatorComponent.vue";
import GenericIndexPage from "@/pages/generic/GenericIndexPage.vue";
import GenericVForm from "@/pages/generic/GenericVForm.vue";
import CustomStoreSelect from "@/components/customv/CustomStoreSelect";
import GenericVArraySchema from "@/pages/generic/GenericVArraySchema";
import CustomTime from "@/components/customv/CustomTime";
import CustomSpacer from "@/components/customv/CustomSpacer";
import ConfirmComponent from "@/components/common/ConfirmComponent.vue";
import TabsComponent from "@/components/common/TabsComponent.vue";
import StepperComponent from "@/components/common/StepperComponent.vue";
import UploadComponent from "@/components/common/UploadComponent.vue";
import ProfileHeaderButton from "@/components/layout/ProfileHeaderButton.vue";
import NotifyHeaderButton from "@/components/layout/NotifyHeaderButton.vue";
import LeftLogo from "@/components/layout/LeftLogo.vue";
import SidebarItemGroupComponent from "@/components/layout/sidebar/SidebarItemGroupComponent.vue";
import SidebarItemComponent from "@/components/layout/sidebar/SidebarItemComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import MainContent from "@/components/layout/MainContent.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import VuetifySidebar from "@/components/layout/VuetifySidebar.vue";
import WidgetComponent from "@/components/widgets/WidgetComponent.vue";
import Main from "@/components/Main.vue";
import CustomFileList from "@/components/customv/CustomFileList.vue";
import ExpanderComponent from "@/components/common/expander/ExpanderComponent.vue";
import HistoryComponent from "@/components/common/HistoryComponent.vue";
import {WidgetStepSlot, WidgetTreeSlot, WidgetDatatableSlot} from "@/components/widgets/slots";
import UploadFiles from "@/components/customv/UploadFiles.vue";
import Tickets from "@/pages/Tickets.vue";
import ErrorPage from "@/pages/ErrorPage.vue";
import ImportPage from "@/pages/ImportPage.vue";
import ZEvents from "@/pages/ZEvents.vue";
const InitGlobalComponents = (app) => {
    app.component('DTHeaderComponent', DTHeaderComponent)
    app.component('DTComponent', DTComponent)
    app.component('DTPaginatorComponent', DTPaginatorComponent)
    app.component("LoaderComponent", LoaderComponent)
    app.component("GenericIndexPage", GenericIndexPage)
    app.component("GenericVForm", GenericVForm)
    app.component('v-custom-select', CustomStoreSelect)
    app.component('v-custom-time', CustomTime)
    app.component('v-custom-file-list', CustomFileList)
    app.component('v-custom-spacer', CustomSpacer)
    app.component('GenericVArraySchema', GenericVArraySchema)
    app.component('ConfirmComponent', ConfirmComponent)
    app.component('TabsComponent', TabsComponent)
    app.component('StepperComponent', StepperComponent)
    app.component('UploadComponent', UploadComponent)
    app.component('ProfileHeaderButton', ProfileHeaderButton)
    app.component('NotifyHeaderButton', NotifyHeaderButton)
    app.component('LeftLogo', LeftLogo)
    app.component('Sidebar', Sidebar)
    app.component('SidebarItemGroupComponent', SidebarItemGroupComponent)
    app.component('SidebarItemComponent', SidebarItemComponent)
    app.component('FooterComponent', FooterComponent)
    app.component('MainContent', MainContent)
    app.component('HeaderComponent', HeaderComponent)
    app.component('VuetifySidebar', VuetifySidebar)
    app.component('MainWrapper', Main)
    app.component('ExpanderComponent', ExpanderComponent)
    app.component('HistoryComponent', HistoryComponent)
    app.component('UploadFiles', UploadFiles)
    app.component('WidgetComponent', WidgetComponent)
    app.component('WidgetStepSlot', WidgetStepSlot)
    app.component('WidgetTreeSlot', WidgetTreeSlot)
    app.component('WidgetDatatableSlot', WidgetDatatableSlot)
    app.component('Tickets', Tickets)
    app.component('ErrorPage', ErrorPage)
    app.component('ImportPage', ImportPage)
    app.component('ZEvents', ZEvents)
}

export default InitGlobalComponents;