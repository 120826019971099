
const Widgets_getters = {

    getTopWidgets(state) {
        return state.topWidgets;
    },

    getBottomWidgets(state) {
        return state.bottomWidgets;
    }

};

export default Widgets_getters;
