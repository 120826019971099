import primeIcons from "@/constants/PrimeIcons";
import MOCK_DATA from "@/constants/mock_data";

const INITIAL_STATE = {
    MAIN: () => ({
        logged: false,
        ready: false,
        options: {
            idle: {
                duration:
                    300
            }
        },
        install: {
            status: null
        },
        configs: {},
        userMenu: [
        ],
        sidebar: {
            sidebar_opened: true,
            items: [],
            // options: {
            isModal: false,
            dismissable: true,
            show_close_icon: true,
            // }
        },
        language: {},
        langs: [],
    }),
    ACCOUNT: () => ({
        avatar: "",
        name: "",
        email: "",
        id: null,
    }),
    UTILITIES: () => ({
        municipalities: [],
        regions: [],
        provinces: [],
        ticket: {
            group_states: [],
            states: []
        }
    }),
    GLOBAL: () => ({
        item: [],
        history: null,
        step: 1,
        displayDialog: false,
        dialog: MOCK_DATA.dialogs().INFO,
        displayToast: false,
        toast: {
            position: 'top-center',
            severity: 'info', // ---> success | info | warn | error
            summary: null,
            detail: '',
            closable: true,
            life: 3000,
            styleClass: null,
            contentStyleClass: null,
            group: 'topbar'
        },
        wip: false
    }),
    ROUTING_ROUTES: () => ({
        current_route: {},
        store_route: {},
        sync_server_params: {}
    }),
    WIDGETS: () => ({
        topWidgets: [],
        bottomWidgets: []
    }),
    DATATABLE:() => ({
        apiUrl: null,
        paginate: {},

        activeFilter: 0,
        actionFilters: [],
        showActions: true,
        titleIcon: null,
        sortMode: 'single',
        multiSortMeta: [],
        primeIcons: primeIcons,
        currentPageTemplate: "Elementi da {first} a {last} di {totalRecords}",
        headerItems: [],
        columns: [],
        rowGroupMode: "subheader",
        rowGroupBy: null,
        items: [],
        rows: 15,
        rowPerPage: [2, 15, 25, 50, 100],
        showDatatable: false
    }),
    TICKETS: () => ({
        tickets: [],
        paginator: {},
        history: [],
        current: null,
        index_observer: null,
        currentPage: null,
        openRightSidebar: false,
        aside: null,
        filterData: null,
        insertTicketPushTop:[],
        actions: [
            {
                "icon": null,
                "color": "#FF7F24",
                "iconColor": "",
                "items": [],
                "to": "ticket/create-new",
                "url": null,
                "label": "APP_UI_ACTS_CREATE",
                "follow": "https://tosca.adel.cloud/api/v1/global-schema/ticket"
            }
            // {
            //     "icon": null,
            //     "color": "#FF7F24",
            //     "iconColor": "",
            //     "items": [],
            //     "to": "ticket/global-form",
            //     "url": null,
            //     "label": "APP_UI_ACTS_CREATE",
            //     "follow": "https://tosca.adel.cloud/api/v1/global-schema/ticket"
            // }
        ],
        model:{},
        schema:{},
        external:false,
        currentProps:{}
    }),
    IMPORTS: () => ({
        items: null
        // dealers: null,
        // pdv: null,
        // scanners: null,
        // warehouse: null
    })
}

export default INITIAL_STATE;