<template>
    <v-list-group
        :value="opened"
    >
      <template v-slot:activator>
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </template>

      <v-list-item
          v-for="(itm, i) in item.items"
          :key="`sub_menu_${item.label}`"
      >
        <SidebarItemGroupComponent v-if="itm.items && itm.items.length > 0" :item="itm" />
<!--        <v-list-item-title v-else v-text="itm.label"></v-list-item-title>-->
        <SidebarItemComponent v-else :key="`sidebar_item_${itm.label}`" :item="itm"/>

        <!--          <v-list-item-icon>-->
        <!--            <v-icon v-text="icon"></v-icon>-->
        <!--          </v-list-item-icon>-->
      </v-list-item>

<!--      <v-list-group-->
<!--          :value="true"-->
<!--          no-action-->
<!--          sub-group-->
<!--      >-->
<!--        <template v-slot:activator>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Admin</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </template>-->

<!--        <v-list-item-->
<!--            v-for="(itm, i) in item.items"-->
<!--            :key="`sub_menu_${i}`"-->
<!--            link-->
<!--        >-->
<!--          <v-list-item-title v-text="itm.label"></v-list-item-title>-->

<!--&lt;!&ndash;          <v-list-item-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            <v-icon v-text="icon"></v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;          </v-list-item-icon>&ndash;&gt;-->
<!--        </v-list-item>-->
<!--      </v-list-group>-->
    </v-list-group>
</template>
<!--
 * Created by PhpStorm.
 * User: Dario Pietroleonardo
 * Date: 08/08/2022
 * Time: 10:01
 -->
<script>
// import SidebarItemComponent from "@/components/layout/sidebar/SidebarItemComponent.vue";

export default {
  name: "SidebarItemGroupComponent",
  props: ['item'],
  // components: [
  //     SidebarItemComponent
  // ],

  data: () => ({
    opened: false
  }),

  methods: {

    toggleOpened: (value) => {
      this.opened = !this.opened
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/primevue/variables";

.sidebar__item {

  a {
    padding-left: 20px;
    padding-right: 20px;
  }

  .v-list-item {
    min-height: 54px;


    &:hover {
      background: rgba(233, 188, 165, 0.26) !important;
      border-radius: 25px !important;
      width: 100%;
    }

    color: #FFF !important;

    * {
      text-align: center;
    }

    &:before {
      border-radius: 25px !important;
    }

  }
}
</style>