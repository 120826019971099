<template>
  <v-combobox
      v-model="select"

      :items="curitemsComputed"
      item-text="item_text"
      item-value="item_value"
      :label="obj.schema.label"
      :color="obj.schema.color"
      :readonly="obj.schema.readonly"
      :rules="obj.schema.rules"
      @change="getItems"
      @load="getItems"
  ></v-combobox>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "v-custom-select",
  props: ['type', 'value', 'obj'],
  data() {
    return {
      curitems: [],
      select: null
    }
  },
  watch: {
    select: {
      handler() {
        this.input=this.select['item_value']
      }
    }
  },
  mounted() {
    this.getItems();

    if(this.value!=='')
      this.select=this.$store.getters[this.obj.schema.items].filter(e=>e.item_value===this.value)[0]


  },
  methods: {
    getItems() {
      this.curitems = this.$store.getters[this.obj.schema.items];

      // (this.obj.value && null === this.select) ? this.select = this.curitems.find(itm => itm.id === this.obj.value) : null;

      if (!this.obj.schema.disable &&  undefined !== this.obj.schema.deeps && this.obj.schema.deeps[0] !== undefined) {
        let filter = 0;
        var sub = this.$store.getters[this.obj.schema.items];

        let to_filter = this.currentItem[this.currentStep - 1].schema[this.obj.schema.deeps[0]].items;
        typeof to_filter === 'string' ? to_filter = this.$store.getters[to_filter] : null;
        let filtered = to_filter.filter(e=>
            e['item-value'] === this.currentItem[this.currentStep - 1].values[this.obj.schema.deeps[0]]).map(e=>e.accetable);
        if(null!==filtered){

          var abilities = filtered && filtered.length > 0 ? [].concat(...filtered.filter(e=>
              e['item-value'] === this.currentItem[this.currentStep - 1].values[this.obj.schema.deeps[0]]).map(e=>e.accetable))[0] : [];
        }



        this.obj.schema.deeps.map(e => {
          filter = this.currentItem[this.currentStep - 1].values[e];

          sub = sub.filter(ee => ee[e] === filter)

          if(abilities && abilities.length)
            sub = sub.filter(ee=> abilities.includes(ee.id) )

        })
        this.curitems = sub;
      }
    }
  },

  computed: {
    ...mapGetters({
      currentItem: "Global/getCurrentItem",
      currentStep: "Global/getCurrentStep",
    }),
    curitemsComputed:function(){
      // console.log(this.obj, "---> obj");
      // debugger;
      this.curitems = this.$store.getters[this.obj.schema.items];
      // (this.obj.value && null === this.select) ? this.select = this.curitems.find(itm => itm.id === this.obj.value) : null;
      if (!this.obj.schema.disable && undefined !== this.obj.schema.deeps  && this.obj.schema.deeps[0] !== undefined && this.currentItem[this.currentStep - 1].schema[this.obj.schema.deeps[0]]) {
        let filter = 0;
        var sub = this.$store.getters[this.obj.schema.items];
        let to_filter = this.currentItem[this.currentStep - 1].schema[this.obj.schema.deeps[0]].items;
        let filtered = this.currentItem[this.currentStep - 1].schema[this.obj.schema.deeps[0]].items;
        typeof filtered === 'string' ? filtered = this.$store.getters[to_filter] : null;
        // let filtered = this.$store.getters[this.currentItem[this.currentStep - 1].schema[this.obj.schema.deeps[0]].items];
        console.log(filtered)

        var abilities = filtered && filtered.length > 0 ? [].concat(...filtered.filter(e=>
            e['item-value']!==undefined?e['item-value']:e['item_value'] === this.currentItem[this.currentStep - 1].values[this.obj.schema.deeps[0]]).map(e=>e.accetable)) : [];



        if(typeof abilities === 'object'){
          abilities = abilities.values()
        }

        // console.log(sub, this.obj.schema.deeps, this.currentStep - 1 )
        this.obj.schema.deeps.map(e => {
          filter = this.currentItem[this.currentStep - 1].values[e];
          sub = sub.filter(ee => ee[e] === filter)


          if(abilities.length)
            sub = sub.filter(ee=> abilities.includes(ee.id) )
        })
        this.curitems = sub;
      }
      return this.curitems
    },

    input: {
      get() {
        return this.getItems()
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }

}
</script>

<style scoped>

</style>