

import Main_getters from "./_getters";
import Main_mutations from "./_mutations";
import Main_actions from "./_actions";
import INITIAL_STATE from "@/constants/InitialState";

const Main = {

    namespaced: true,
    state: INITIAL_STATE.MAIN(),

    getters:{
        ...Main_getters
    },
    mutations:{
        ...Main_mutations
    },
   actions:{
        ...Main_actions
    }

}

export default Main;
