<template>
  <div v-if="logged" class="backend__container">
<!--    <PToast position="top-right" group="tr"/>-->
<!--    <PToast position="bottom-right" group="br"/>-->
<!--    <PToast v-if="displayToast" :position="pos" group="tc"/>-->
    <PToast v-if="displayToast" v-bind="toast"/>
<!--    <PToast position="bottom-center" group="bc"/>-->
<!--    <PToast position="center" group="c"/>-->
<!--    <PToast position="center" group="confirmation" styleClass="confirmation">-->
<!--      <template #message="slotProps">-->
<!--        <PCard class="confirmation__toast">-->
<!--          <template #title>-->
<!--            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>-->
<!--            <h4>{{ slotProps.message.summary }}</h4>-->
<!--          </template>-->
<!--          <template #content>-->
<!--            <p>{{ slotProps.message.detail }}</p>-->
<!--            <div class="toast__actions">-->
<!--              <PButton class="p-button-success" label="Yes" @click="slotProps.message.onConfirm"></PButton>-->
<!--              <PButton class="p-button-secondary" label="No" @click="slotProps.message.onReject"></PButton>-->
<!--            </div>-->
<!--          </template>-->
<!--        </PCard>-->
<!--      </template>-->
<!--    </PToast>-->
    <HeaderComponent/>
    <!--    <VuetifySidebar/>-->
    <Sidebar/>
    <MainContent/>
    <FooterComponent/>
  </div>
</template>

<script>
// import FooterComponent from "@/components/layout/FooterComponent.vue";
// import MainContent from "@/components/layout/MainContent.vue";
// import HeaderComponent from "@/components/layout/HeaderComponent.vue";
// import Sidebar from "@/components/layout/Sidebar.vue";
import {mapGetters} from "vuex";
// import VuetifySidebar from "@/components/layout/VuetifySidebar.vue";

export default {
  name: "Backend",

  // components: {
  //   VuetifySidebar,
  //   FooterComponent,
  //   MainContent,
  //   HeaderComponent,
  //   Sidebar
  // },

  watch: {
    logged: {
      handler() {
        if (this.logged === true) {
          localStorage.removeItem('verify_resend')
          localStorage.removeItem('verify_send')
        }
      }
      // setTimeout(() => {
      // !this.logged ? this.$navigateName('login') : null //this.$navigateName('backend')
      // }, 1200)
    }
  },

  computed: {
    ...mapGetters({
      logged: "Main/getLogged",
      toast: "Global/getToast",
      displayToast: "Global/getDisplayToast",
      // pos: "Global/getToastPosition"
    }),
  },

  mounted() {
    // setTimeout(() => {
    // !this.logged ? this.$navigateName('login') : null//this.$navigateName('backend')
    if (this.logged === true) {
      localStorage.removeItem('verify_resend')
      localStorage.removeItem('verify_send')
    }
    // }, 200)
  },

}
</script>

<style scoped>

</style>