<template>
    <div class="generic__page">
        <div class="card__menu_header" style="gap: .3em">
            <PButton class="p-button-help" :label="$t_('APP__DIALOG__BUTTON_LABEL__IMPORT')" icon="fa-solid fa-upload"
                     @click="clickUpload"/>
            <PButton v-if="datatables" class="p-button-help" :label="$t_('APP__DIALOG__BUTTON_LABEL__SAVE')"
                     icon="fa-solid fa-floppy-disk" @click="clickSave"/>
        </div>
        <div v-for="(dt, idx) in datatables">
            <PCard class="elevate" v-if="dt">
                <!--    <PCard class="elevate" v-if="withFilter || $store.state.Datatable.showDatatable">-->
                <template #title>
                    <h3><i v-if="dt.icon" :class="primeIcons[dt.icon ?? 'fa fa-database']"/> {{ idx }}</h3>
                </template>
                <template #content>
                    <!--          <DTComponent :datatable="dt"/>-->
                    <v-data-table
                            :headers="dt.headers"
                            :items="dt.data"
                            :items-per-page="15"
                            class="elevation-0"
                    ></v-data-table>
                </template>
            </PCard>
        </div>
    </div>
</template>

<script>
import MOCK_DATA from "@/constants/mock_data";
import {mapActions, mapGetters, mapMutations} from "vuex";
import initialState from "@/constants/InitialState";

export default {
    name: "ImportPage",
    props: [
        'title',
        'titleIcon',
        'routeName',
        'apiPath',
        'apiPathSave',
        'method',
        'config',
        'ui',
        'page_id'
    ],
    data: () => ({
        dialog: null,
        headerActions: [
            {
                color: "#A040E9",
                follow: null,
                icon: null,
                iconColor: "",
                items: [],
                label: "APP_UI_ACTS_IMPORT",
                to: null,
                url: null,
            }
        ]
    }),

    computed: {
        ...mapGetters({
            datatables: "Import/getImport"
        }),

        cnf() {
            return (null === this.config || this.config === undefined) ? {headers: this.$getheaders()} : this.config;
        },
    },

    methods: {
        ...mapMutations({
            setGlobalObject: "Global/setObject",
            setMainObj: "Main/setObject"
        }),
        ...mapActions({
            confirmData: "Utilities/confirmData"
        }),

        clickUpload() {
            this.dialog = MOCK_DATA.dialogs(this.$t_).UPLOAD;
            this.dialog.parentProps = {
                url: this.$props.apiPath,
                ...this.$getheaders(),
                method: this.$props.method,
            }
            this.setGlobalObject({dialog: this.dialog, displayDialog: true})
        },

        clickSave() {
            this.dialog = MOCK_DATA.dialogs(this.$t_).QUESTION;
            this.dialog.onYes = () => {
                this.setMainObj({ready: false});
                this.confirmData({
                    config: {
                        url: this.$props.apiPathSave,
                        ...this.$getheaders(),
                        method: this.$props.method,
                    },
                    data: this.datatables,
                    _token: this.$getTnk()
                })
                    .then(res => {
                        this.setMainObj({ready: true});
                            res ? this.$showToast({
                                    severity: 'success',
                                    summary: "ToSca",
                                    detail: this.$t_('UPLOAD_FILE__MESSAGE_SUCCESS'),
                                    life: 3000,
                                })
                                : this.$showToast({
                                    severity: 'error',
                                    summary: "ToSca",
                                    detail: this.$t_('UPLOAD_FILE__MESSAGE_FAILED'),
                                    life: 3000,
                                })
                        }
                    )
                    .catch(err => {
                        this.setMainObj({ready: true});
                    })
            }
            this.setGlobalObject({dialog: this.dialog, displayDialog: true})
        }
    },

    beforeMount() {
        this.$store.commit("Import/setObject", initialState.IMPORTS());
    }
}
</script>

<style scoped>

</style>