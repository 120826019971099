
const Routing_Route_getters = {

    getCurrentRoute(state){
        return state.current_route
    },

    getAllRoutes(state){
        return state.store_route;
    }

};

export default Routing_Route_getters;
