import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'

import Vidle from 'v-idle'
// Vue.use(Vidle)

import axios from "axios";

// const daxios = axios.create({
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json'
//   }
// })

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'Application/json';
window.axios.defaults.headers.common['Accept'] = 'Application/json';
// window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false
Vue.config.devtools = true

// store.$axios = daxios

// Vuetify
import {vuetifyComponents, vuetifyConfig} from "@/plugins/vuetify";
vuetifyComponents(Vue)
// End Vuetify

// Primevue
import "primevue/resources/themes/md-light-indigo/theme.css"
import "primevue/resources/primevue.min.css"
import InitPrimevue from "@/plugins/primevue";
InitPrimevue(Vue)
// End Primevue

// Inizializzazione componenti globali
import InitGlobalComponents from "@/plugins/GlobalComponents";
InitGlobalComponents(Vue)
// End componenti globali

import VueMask from 'v-mask'
Vue.use(VueMask)

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

// inizializzazione plugin personale
import Dacho from "@/plugins/Dacho";
Vue.use(Dacho, {
  appTknName: '_app_option',
  toastMutation: "Global/setObject"
});
// End inizializzazione plugin personale

import Utils from "@/utility/utilsFunct";

Vue.mixin(Utils)
import Tooltip from 'primevue/tooltip';

Vue.directive('tooltip', Tooltip);

Vue.filter('filterLabel', (val) => {
  let resp = "";
  switch (val) {
    case "meta":
      resp = "Proprietà";
      break;
    case "people":
      resp = "Referenti";
      break;
    default:
      resp = val;
  }
  return resp;
})

// import customFunctions from "@/utility/customFunctions";
// Vue.mixin(customFunctions)


// import {loadFonts} from "@/plugins/webfontloader";
// loadFonts()

import "@/assets/primevue/template.scss"

new Vue({
  router,
  store,
  vuetify: vuetifyConfig,
  render: h => h(App)
}).$mount('#app')
