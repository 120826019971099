<template>
  <!--    <div v-if="ready" class="auth__container">-->
  <PCard>
    <template #title>
      <p>To<span>Sca</span></p>
    </template>
    <template #subtitle>
      {{ $t_('AUTH__SUBTITLE__FORGOT_PASSWORD') }}
    </template>
    <template #content>
      <div :class="{'p-inputgroup': true, 'invalid': !usernameValid}">
        <PInputText :disabled="disableInput" required type="text" :placeholder="$t_('AUTH__PLACEHOLDER__USERNAME')"
                    class="border-left"
                    v-model.prevent="username" @keydown.enter="requestCode"/>
        <i class="pi pi-user icon border-right"/>
      </div>

      <div class="auth__forgot_password" v-if="disableInput">
        <p class="forgot_password_insert_code">
          {{ $t_("AUTH__INSERT_CODE__FORGOT_PASSWORD") }}
        </p>
        <div :class="{'p-inputgroup': true, 'invalid': false, 'number__mask': true}">
          <PInputMask :disabled="!disableInput" v-for="idx in  maxDigits" :id="`mask_input_code_${idx}`"
                      :key="`mask_input_code_${idx}`" mask="9"
                      v-model="value[idx]" @keyup="makeCode" @click="onCodeClick" @change="makeCode"
                      @keydown.enter="sendCode"/>
        </div>
      </div>

      <div class="auth__actions">
        <div class="p-inputgroup" v-if="!disableInput">
          <PButton :disabled.sync="disableResend" class="border-all"
                   v-tooltip.top="$t_('AUTH__BUTTON__TITLE__RESET_PASSWORD')" @click.prevent="requestCode">
            {{ $t_('AUTH__BUTTON__TITLE__RESET_PASSWORD') }}
          </PButton>
        </div>
        <div class="p-inputgroup" v-else-if="disableInput">
          <PButton :disabled.sync="disableVerify" class="border-all secondary-btn"
                   v-tooltip.top="$t_('AUTH__BUTTON__TITLE__SEND_TEMP__PASSWORD__FORGOT_PASSWORD')" @click.prevent="sendCode">
            {{ $t_('AUTH__BUTTON__TITLE__SEND_TEMP__PASSWORD__FORGOT_PASSWORD') }}
          </PButton>
        </div>
        <div class="p-inputgroup right">
          <PButton class="border-all p-button-link" v-tooltip.top="$t_('AUTH__BUTTON__TITLE__GO_BACK')"
                   @click="$navigateName('login')">
            {{ $t_('AUTH__BUTTON__TITLE__GO_BACK') }}
          </PButton>
        </div>
      </div>

    </template>
  </PCard>
  <!--    </div>-->
  <!--    <LoaderComponent :value="!ready" />-->
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MOCK_DATA from "@/constants/mock_data";

export default {

  data: () => ({
    ready: false,
    username: '',
    usernameValid: false,
    resendUrl: localStorage.getItem('forgot_resend'),
    sendUrl: localStorage.getItem(('forgot_send')),
    token: null,
    emailCode: '',
    value: {},
    disableInput: false,
    maxDigits: 5
  }),

  watch: {
    logged: {
      handler() {
        setTimeout(() => {
          this.logged ? this.$navigateName('backend') : this.ready = true //this.$navigateName('backend')
        }, 1200)
      }
    },
    username: {
      handler() {
        this.usernameValid = this.username && this.$minlen(this.username, 5);
      }
    },
  },

  computed: {
    ...mapGetters({
      logged: "Main/getLogged"
    }),

    disableResend() {
      return !this.usernameValid || this.disableInput;
    },

    disableVerify() {
      return (this.emailCode.length !== this.maxDigits || !this.sendUrl || !this.token);
    }

  },

  methods: {
    ...mapMutations({
      setMainObject: "Main/setObject",
      setGlobalObj: "Global/setObject",
    }),

    getFromLocalStorage() {
      // this.token = this.$getTnk()//sessionStorage.getItem('jwt_token')
      this.username = this.$getLocalStorageTTL('forgot_username')
      this.sendUrl = this.$getLocalStorageTTL('forgot_send')
      this.resendUrl = this.$getLocalStorageTTL('forgot_resend')
    },

    resetInputs() {
      this.username = '';
    },

    makeCode(e) {
      this.$validateMasckedCode(e);

      this.emailCode = Object.values(this.value).join('')
    },

    onCodeClick(e) {
      e.currentTarget.select();
    },

    requestCode(event) {
      // this.$setLocalStorageTTL([{name: "code_sended", value: this.emailCode}]);
      // this.disableInput = true;
      if (this.usernameValid) {
        this.setMainObject({ready: false});
        axios.post(`/api/v1/reset-password/req`, {
          uname: this.username,
        })
            .then(response => {
              console.log(response.data, "---> Response");
              return response.data
            })
            .then(response => {
              this.token = response.token

              this.$setTnk(response.token, "_reset_options");

              this.sendUrl = process.env.MODE === 'development' ? `${response.send}?watch=watch` : response.send
              this.resendUrl = response.resend
              this.$setLocalStorageTTL([
                {name: 'forgot_username', value: this.username},
                {name: 'forgot_send', value: this.sendUrl},
                {name: 'forgot_resend', value: this.resendUrl}
              ])
              this.disableInput = true;
            })
            .catch(error => console.log(error.message))
            .finally(() => this.setMainObject({ready: true}))
      }
    },

    sendCode() {
      this.getFromLocalStorage();
      this.setMainObject({ready: false})
      axios.post(this.sendUrl, {
        code: this.emailCode,
      }, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
          .then(res => res.data)
          .then(res => {
            this.$setTnk(res.token, '_reset_options');
            this.$removeFromLocalStorage(["forgot_username", "forgot_send", "forgot_resend"], true, '_reset_options')
              this.setMainObject({ready: true});
              let dialog = MOCK_DATA.dialogs(this.$t_).INFO;
              dialog.text = this.$t_("AUTH__DIALOG__TEXT__PWD_RESENDED");
              dialog.noLabel = this.$t_('APP__DIALOG__BUTTON_LABEL__CLOSE')
              dialog.onClose = () => {
                this.$navigateName('login');
              }
              dialog.onNo = dialog.onClose;
              this.setGlobalObj({dialog: dialog});
              this.setGlobalObj({displayDialog: true});
          })
          .catch(error => console.log(error.message))
          /*
          .finally(() => {
            let dialog = MOCK_DATA.dialogs(this.$t_).INFO;
            dialog.text = this.$t_("AUTH__DIALOG__TEXT__PWD_RESENDED");
            // dialog.loader = {
            //   // mode: "indeterminate",
            //   fakeProgress: true,
            //   fakeMessages: [
            //     'La richiesta è stata presa in carico...',
            //     'La richiesta è in elaborazione...',
            //     'L\'operazione è stata completata.'
            //   ],
            //   fakeDelay: 1000,
            //   closeOnEnd: () => {
            //     this.$navigateName('login');
            //     this.setMainObject({ready: true});
            //   }
            // }
            // dialog.hideButtons = true;
            this.setGlobalObj({dialog: dialog});
            this.setGlobalObj({displayDialog: true});
          })
           */
    }
  },

  mounted() {
    this.token = this.$getTnk('_reset_options');
    this.getFromLocalStorage();
    this.resendUrl && this.sendUrl && this.token ? this.disableInput = true : null;
    // sessionStorage.removeItem('_reset_options')
  },

}
</script>

<style scoped>

</style>