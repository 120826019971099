<template>
  <PSidebar v-if="sidebar" :visible.sync="$store.state.Main.sidebar.sidebar_opened"
            :modal="false" :dismissable="false" :showCloseIcon="sidebar.show_close_icon"
            role="region">
    <!--            :modal="options.isModal" :dismissable="true" :showCloseIcon="true" role="region">-->
    <template #header>
      <LeftLogo/>
    </template>
    <PPanelMenu :model="menuItems"/>
    <!--      <template #item="{item}">-->
    <!--        <router-link v-if="item.to" :to="item.to" custom v-slot="{href, route, navigate, isActive, isExactActive}">-->
    <!--          <div :class="{'custom__sidebar-item': true, 'active-link': isActive, 'active-link-exact': isExactActive}">-->
    <!--            <a :href="href" @click="navigate" :class="{'p-panelmenu-header-action': true}">-->
    <!--              <span class="p-menuitem-text">{{ item.label }}</span>-->
    <!--            </a>-->
    <!--            <span v-if="item.items && item.items.length > 0"-->
    <!--                  :class="{'p-submenu-icon pi pi-chevron-down': expandedKeys[item.key] === true, 'p-submenu-icon pi pi-chevron-right': !expandedKeys[item.key]}"></span>-->
    <!--          </div>-->
    <!--        </router-link>-->
    <!--      </template>-->
    <!--    </PPanelMenu>-->
  </PSidebar>
</template>

<script>
// import PVSidebar from 'primevue/sidebar';
// import LeftLogo from "@/components/layout/LeftLogo.vue";
import {mapGetters, mapMutations} from 'vuex';
import {ref} from "vue";

/**
 * Sidebar
 *
 * - appBar: boolean (def. false)
 */
export default {
  name: "Sidebar",
  // components: {LeftLogo, PVSidebar},
  // props: ['sidebarOpened', 'menuItems', 'options'],

  data: () => ({
    expandedKeys: {},
    // sidebarOpened: true,
    // options: [],
    // menuItems: [],
  }),

  computed: {
    ...mapGetters({
      // getSidebarOpened: "Main/getSidebarOpened",
      sidebar: "Main/getSidebar",
      menuItems: "Main/getSidebarItems",
      // options: "Main/getSidebarOptions"
    }),

    // sidebarOpened: {
    //   get() {
    //     return this.getSidebarOpened
    //   },
    //   set(value) {
    //     this.setSidebarOpened(value)
    //   }
    // }
  },

  methods: {
    ...mapMutations({
      setSidebarObj: "Main/setSidebarObject"
    }),

    setSidebarOptions() {
      this.setSidebarObj({
        sidebar_opened: this.$vuetify.breakpoint.width > 1260,
        isModal: this.$vuetify.breakpoint.width <= 1260,
        // dismissable: this.$vuetify.breakpoint.width <= 1260
      });
    }

  },

  mounted() {
    this.$vuetify.breakpoint.width <= 1260 ? this.setSidebarOptions() : null;
    // this.expandedKeys[this.menuItems[0].key] = true;

    // this.sidebarOpened = this.sidebar.sidebar_opened
    // this.options = this.sidebar.options ?? []
    // this.menuItems = this.sidebar.items ?? []
    // console.log(this.menuItems)
  },

  watch: {
    $vuetify: {
      handler() {
        this.setSidebarOptions();
      }, deep: true
    }
    //   sidebarOpened: {
    //     handler(value) {
    //       // this.$store.commit("Main/setSidebarOpened", value)
    //     }
    //   },
    //   sidebar:{
    //     handler(){
    //     }
    //   }
  },


}
</script>

<style lang="scss">

//span.p-menuitem-text:after {
//  font-family: "primeicons";
//  content: '\e901';
//}

</style>