<template>
    <v-data-table
            :headers="headers"
            :items="current"
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            class="vdt__generic elevation-1"
            v-if="visible"
    >
        <template v-slot:item.closed="{ item }">
            <i :class="`dt_custom_closed fa-solid ${item.closed?'fa-lock':'fa-lock-open'}`"></i>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="dt__child-table">
                <v-simple-table >
                    <template v-slot:default>
                        <thead>
                        <tr>

                            <th class="text-left">
                                Azione
                            </th>
                            <th class="text-left">
                                Stato
                            </th>
                            <th class="text-left">
                                Data
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="el in item.history"
                                :key="`${item.id}__${el.date}__${el.status}`"
                        >
                            <td>{{ el.action }}</td>
                            <td>{{ el.status }}</td>
                            <td>{{ el.date }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </td>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "DatatableExpands",
    props: ["items"],
    data() {
        return {
            visible: false,
            headers: [
                {text: "#", value: 'id', class: "dt__vheader"},
                {text: "Pdv", value: "pdv", class: "dt__vheader"},
                {text: "Scanner", value: "scanner", class: "dt__vheader"},
                {text: "Magazzino", value: "warehouse", class: "dt__vheader"},
                {text: "Grouppo Ticket", value: "ticket_group", class: "dt__vheader"},
                {text: "Stato", value: "stato", class: "dt__vheader"},
                {text: "Creazione", value: "created_at", class: "dt__vheader"},
                {text: "Aggiornamento", value: "updated_at", class: "dt__vheader"},
                {text: "Chiusura", value: "closed_at", class: "dt__vheader"},
                {text: "Chiuso", value: "closed", class: "dt__vheader"},
            ],
            headerSimple: [],

            expanded: [],
            current: []
        }
    },
    mounted() {
        this.current.push(this.items.items)
        this.recursiveGetElements(this.items.items)
        // console.log(this.current)
        this.visible = true
    },
    methods: {

        recursiveGetElements(data) {
            data.children.map(e => this.recurMe(e))
        },
        recurMe(e) {
            this.current.push(e)
            this.recursiveGetElements(e)
        }

    }
}
</script>

<style scoped lang="scss">



//.vdt__generic {
//  &.v-data-table {
//    & > .v-data-table__wrapper {
//        tr {
//          &.v-data-table__expanded__content {
//            td {
//              padding-left: 3em;
//            }
//          }
//      }
//    }
//  }
//}

</style>