const MOK_DIALOG = (translateFn = (val) => (val)) => ({
    QUESTION: {
        style: null,
        showIcon: true,
        type: "dialog-question",
        title: "ToSca",
        text: "",
        yesIcon: "pi pi-check",
        yesLabel: translateFn("APP__DIALOG__BUTTON_LABEL__SAVE"),
        noIcon: "pi pi-times",
        noLabel: translateFn("APP__DIALOG__BUTTON_LABEL__CANCEL"),
        onClose: () => null,
        onYes: () => null,
        onNo: () => null,
        toast_group: 'topbar'
    },
    ERROR: {
        style: null,
        showIcon: true,
        type: "dialog-error",
        title: "ToSca",
        text: "",
        yesIcon: "pi pi-check",
        yesLabel: translateFn("APP__DIALOG__BUTTON_LABEL__SAVE"),
        noIcon: "pi pi-times",
        noLabel: translateFn("APP__DIALOG__BUTTON_LABEL__CANCEL"),
        onClose: () => null,
        onYes: () => null,
        onNo: () => null,
        toast_group: 'topbar'
    },
    INFO: {
        style: null,
        showIcon: true,
        type: "dialog-info",
        title: "ToSca",
        text: "",
        onYes: null,
        yesIcon: null,
        yesLabel: null,
        onNo: () => null,
        noIcon: "pi pi-times",
        noLabel: translateFn("APP__DIALOG__BUTTON_LABEL__CANCEL"),
        onClose: () => null,
        toast_group: 'topbar'
    },
    WARNING: {
        style: null,
        showIcon: true,
        type: "dialog-warning",
        title: "ToSca",
        text: "",
        yesIcon: "pi pi-check",
        yesLabel: translateFn("APP__DIALOG__BUTTON_LABEL__CONFIRM"),
        noIcon: "pi pi-times",
        noLabel: translateFn("APP__DIALOG__BUTTON_LABEL__CANCEL"),
        onClose: () => null,
        onYes: () => null,
        onNo: () => null,
        toast_group: 'topbar'
    },
    UPLOAD: {
        style: null,
        showIcon: true,
        type: "dialog-upload",
        title: "ToSca",
        text: translateFn("APP__UPLOAD__TEXT__DRAG__HERE"),
        yesIcon: "pi pi-upload",
        yesLabel: translateFn("APP__DIALOG__BUTTON_LABEL__IMPORT"),
        noIcon: "pi pi-times",
        noLabel: translateFn("APP__DIALOG__BUTTON_LABEL__CANCEL"),
        onClose: () => null,
        onYes: () => null,
        onNo: () => null,
        toast_group: 'topbar'
    }
})

export default MOK_DIALOG;