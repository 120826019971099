const Global_getters = {

    getCurrentItem(state) {
        return state.item
    },

    getCurrentStep(state) {
        return state.step
    },

    getDisplayDialog(state) {
        return state.displayDialog
    },

    getDialog(state) {
        return state.dialog
    },

    getToast(state) {
        return state.toast
    },

    getToastPosition(state) {
        return state.toast.position;
    },

    getDisplayToast(state) {
        return state.displayToast
    },

    getWIP(state) {
        return state.wip;
    }

};

export default Global_getters;
