import initialState from "@/constants/InitialState";

const Utilities_actions = {

    sendFile({commit, dispatch, state}, {config, data, _token = null}) {
        // console.log(config, data, Object.keys(data));
        // return;
        if (!data || Object.keys(data).length <= 0) return null;

        let formData = new FormData();
        (data && Object.keys(data).length > 0) ? Object.keys(data).forEach(el => formData.append('imported_file', data[el].file))
            : formData.append('imported_file', data.file);
        commit("Import/setObject", initialState.IMPORTS(), {root: true});

        axios({
            method: config.method ?? 'GET',
            url: process.env.VUE_APP_API_PATH + process.env.VUE_APP_API_VER + config.url,
            headers: {...config.headers, "content-Type": "multipart/form-data", "Authorization": `Bearer ${_token}`},
            data: formData,
        })
            .then(response => response.data)
            .then(response => {
                // let dt = [];
                // Object.keys(response).map(k => dt.push({...response[k], title: k, identifier: k}))
                commit("Import/setObject", {items: response}, {root: true});
            })
            .catch(error => {
                commit("Import/setObject", initialState.IMPORTS(), {root: true})
                console.log(error.message, "---> Utility sendFile Catch");
            })
    },

    async confirmData({commit, dispatch, state}, {config, data, _token = null}) {
        let status = false;
        await axios({
            method: config.method ?? 'GET',
            url: process.env.VUE_APP_API_PATH + process.env.VUE_APP_API_VER + config.url,
            headers: {...config.headers, "Authorization": `Bearer ${_token}`},
            data: data,
        })
            .then(response => {
                // console.log(response);
                status = true
            })
            .catch(error => console.log(error.message, "---> Utility confirmData Catch"));
        return status
    }

}

export default Utilities_actions;
