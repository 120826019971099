

const Datatable_mutations = {
    setObject(state, data) {
        Object.keys(data).forEach(e => state[e] = data[e])
    },

}

export default Datatable_mutations;
