
const Utilities_getters = {

    getStates(state) {
        return state.states
    },

    getUtilities(state) {
        return state
    },

    getMunicipalities(state) {
        return state.municipalities
    },

    getRegions(state) {
        return state.regions
    },

    getProvinces(state) {
        return state.provinces
    },

    getTicketObj(state) {
        return state.ticket
    },

    getTicketStates(state) {
        return state.ticket.states
    },

    getTicketGroupStates(state) {
        return state.ticket.group_states
    }

};

export default Utilities_getters;
