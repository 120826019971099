<template>
  <div class="widget__tree">
<!--    <PCard :key="`widget_tree_container_${idx}`" v-for="(el, name, idx) in elements"-->
<!--           :class="`elevate tk__${name} ${idx > 0 ? 'ml-' + (idx * 5) : ''}`"-->
<!--           :style="el.length <= 0 ? 'display: none;' : ''">-->
<!--      <template #content>-->
<!--        <div v-for="(itm, ind) in el" :key="`widget_tree_element_${ind}`" class="tk__card__container" :underline="Boolean(el.length > 1)">-->
<!--          <div class="tk__card__container_left">-->
<!--            <a class="title__link__tk" href="#" @click.prevent="$router.push(`/details-ticket/${itm.id}/view`)">-->
<!--              <span>#{{ itm.id }}</span>-->
<!--            </a>-->
<!--            <p>-->
<!--              <span>{{ $t_('TK__CARD__LABEL__GROUP') }}:</span>{{ itm.ticket_group }}-->
<!--            </p>-->
<!--            <p>-->
<!--              <span>{{ $t_('TK__CARD__LABEL__STATE') }}:</span>{{ itm.stato }}-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="tk__card__container-right">-->
<!--&lt;!&ndash;            <PButton&ndash;&gt;-->
<!--&lt;!&ndash;                v-if="name !== 'current'"&ndash;&gt;-->
<!--&lt;!&ndash;                icon="pi pi-info"&ndash;&gt;-->
<!--&lt;!&ndash;                class="p-button-rounded p-button-secondary"&ndash;&gt;-->
<!--&lt;!&ndash;                :title="$t_('APP__BUTTON__TITLE__DETAILS')"&ndash;&gt;-->
<!--&lt;!&ndash;                :v-tooltip.top="$t_('APP__BUTTON__TITLE__DETAILS')"&ndash;&gt;-->
<!--&lt;!&ndash;                @click="$router.push(`/details-ticket/${itm.id}/view`)"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--            <p v-if="name === 'current'">{{ $t_('TK__LABEL__CURRENT') }}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
<!--    </PCard>-->

        <RecursiveIndent v-if="items.items.derived_from!== null" :items="[items.items.derived_from]"></RecursiveIndent>

        <RecursiveIndent :items="[items.items]"></RecursiveIndent>

<!--        <PCard :key="`widget_tree_container_${idx}`" v-for="(el, idx) in items"
               :class="`elevate tk__${key}`">



        </PCard>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RecursiveIndent from "@/components/widgets/slots/RecursiveIndent.vue";

export default {
  name: "WidgetTreeSlot",
  components: {RecursiveIndent},
  props: ['items'],
  mounted() {
    // console.log(this.items)
  },
  computed: {
    ...mapGetters({}),

    elements() {
      // console.log(this.items)
      let obj = {};
      if (this.items && this.items.items) {
        this.items.items.derived_from ? obj.derived_from = [this.items.items.derived_from] : null;
        obj.current = [this.items.items];
        this.items.items.children ? obj.children = this.items.items.children : null;
      }
      return obj;
    },

  }
}
</script>

<style scoped>

</style>