<template>


  <div class="container">

    <v-card v-if="values.length <= 0" class="no-data-message">
      <v-card-title>{{ $t_("APP_NESSUN_DATO") }}</v-card-title>
    </v-card>
    <v-card v-else :key="`vform_generic_card_array_${k}_${cardId}`" v-for="(el,k) in values" outlined class="content">

      <v-card-title style="text-transform: capitalize">
        {{ k + 1 }}
      </v-card-title>

      <v-card-text>
        <v-form-base :id="`v_form_generic_array_${formBaseId}`"
                     :schema="$getRulesInput(model)" :model="values[k]"
                     @input="(props) => updateSubForm(props, k)"
                     @update="(props) => updateSubForm(props, k)"
        ></v-form-base>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <PButton v-if="showActions" @click="(props) => removeElements(k, props)" icon small tile icon="mdi mdi-delete"
                 class="p-button-danger"/>
        <!--        <v-btn @click="removeElements(k)" icon small tile>-->
        <!--          <v-icon>-->
        <!--            mdi-delete-->
        <!--          </v-icon>-->
        <!--        </v-btn>-->
      </v-card-actions>


    </v-card>
    <div class="add-array-actions">
      <PButton v-if="showActions" @click="addElements" icon absolute fab left bottom icon="mdi mdi-plus"
               class="add-array p-button-success"/>
      <!--      <v-icon>-->
      <!--        mdi-plus-->
      <!--      </v-icon>-->
      <!--      </PButton>-->
    </div>


  </div>


</template>

<script>
export default {
  name: "GenericVArraySchema",
  props: ["items", "showActions"],
  data() {
    return {
      cardId: 0,
      formBaseId: 0,
      curId: null,
      emptyModelEdited: {},
      curModel: {},
      model: {},
      schema: {},
      values: [],
    }
  },
  watch: {
    // curId: {
    //   handler() {
    //     console.log(this.curId)
    //   }
    // }
  },
  computed: {},
  filters: {
    capitalize(el) {
      return el.toUpperCase()
    }
  },

  mounted() {

    // this.items.values = [
    //   {
    //     mail:'',
    //     mobile:'',
    //     name:'aaaaaaaaa',
    //     pec:'',
    //     phone:'',
    //     role:'',
    //     surname:'',
    //   },
    //   {
    //     mail:'',
    //     mobile:'',
    //     name:'ccccccccccccccc',
    //     pec:'',
    //     phone:'',
    //     role:'',
    //     surname:'',
    //   }
    // ]

    this.model = this.items.schema
    this.values = this.items.values
    this.curId = 0
    this.cardId = 0
    this.formBaseId = 0
    this.curModel = this.values[0] ?? {}
  },

  methods: {
    addElements() {
      let local = {}
      Object.keys(this.curModel).forEach(e => local[e] = '')
      this.values.push(local)
      this.formBaseId++;
    },
    removeElements(idx, props) {
      this.$delete(this.values, idx);
      this.commitEv({...this.items, values: this.values});
      this.cardId++;
      this.formBaseId++;
    },
    commitEv(e) {
      this.$emit('update', e)
    },
    updateSubForm(props, idx) {
      const {on, id, key, value, params, obj, data, schema, parent, index, event} = props
      let a = null

      let findKey = ['item_value','item-value']
      // console.log(value instanceof Object)
      if(value instanceof Object){
        for(let i in findKey)
          a = value[findKey[i]]!==undefined && null===a ? value[findKey[i]] : a
      }else a = value

      this.values[idx][key] = a
      // this.commitEv({...this.items, values: this.values})
      this.commitEv(on, id, key,  this.values, params, obj, data, schema, parent, index, event)
      // this.values[this.curId][key] = value
    }
  }
}
</script>

<style scoped lang="scss">

.container {

  .no-data-message {
    display: flex;
    justify-content: center;
  }

  .content {
    margin-top: 1em;
  }

  .add-array-actions {
    display: flex;
    justify-content: center;
    padding: .5em;

    .add-array {
      min-width: 4em;
    }
  }

  .p-button {

    &:hover {
      opacity: .8;
    }

    &-icon-only {
      justify-content: center !important;
      font-size: 25px;
      padding: 0 !important;

      .p-icon {
        font-size: 1.5em;
      }
    }
  }
}

</style>