<template>
  <div class="generic__page">
    <PCard v-if="topWidgets && topWidgets.length > 0" class="widgets_container">
      <template #content>
        <v-row v-for="(tw, idx) in topWidgets" :key="`top_w_${tw.name}_${idx}`">
          <WidgetComponent v-for="(itm, index) in tw.items" :key="`top_w_${itm.name}_${idx}_${index}`" :item="itm"/>
        </v-row>
      </template>
    </PCard>
    <PCard v-if="showDatatable" class="elevate">
<!--    <PCard class="elevate" v-if="withFilter || $store.state.Datatable.showDatatable">-->
      <template #title v-if="withFilter && title">
        <h3><i v-if="titleIcon" :class="primeIcons[titleIcon ?? 'ticket']"/> {{ $t_(title) }}</h3>
      </template>
      <template #content>
        <DTComponent :with-filter="withFilter" :apiPath="apiPath" />
      </template>
    </PCard>
    <PCard v-if="bottomWidgets && bottomWidgets.length > 0" class="widgets_container">
      <template #content>
        <v-row v-for="(bw, idx) in bottomWidgets" :key="`bottom_w_${bw.name}_${idx}`">
          <WidgetComponent v-for="(itm, index) in bw.items" :key="`bottom_w_${itm.name}_${idx}_${index}_${Math.floor(Math.random() * 10000)}`" :item="itm"/>
        </v-row>
      </template>
    </PCard>
  </div>
</template>

<script>

import primeIcons from "@/constants/PrimeIcons";
import {mapActions, mapGetters, mapMutations} from "vuex";
import initialState from "@/constants/InitialState";
import COLORS from "@/constants/Colors";

export default {
  name: "GenericIndexPage",
  props: [
    'title',
    'titleIcon',
    'routeName',
    'apiPath',
    'method',
    'config',
    'ui'
  ],

  data() {
    return {
      primeIcons: primeIcons,
    }
  },

  // filters: {
  //   checkTypeView: function (val) {
  //     let res = '';
  //     if (val instanceof Object)
  //       Object.keys(val).forEach(e => res += `${e}: ${val[e]}\r\n\r\n`)
  //     else res = val
  //
  //     return res
  //   }
  // },
  computed: {

    ...mapGetters({
      topWidgets: "Widgets/getTopWidgets",
      bottomWidgets: "Widgets/getBottomWidgets",
      dtFromStore: "Datatable/getDatatable"
    }),

    showDatatable() {
      return this.dtFromStore && this.dtFromStore.columns && this.dtFromStore.columns.length > 0;
    },

    withFilter() {
      return this.ui && this.ui === 'filtered';
    },

  },

  methods: {
    ...mapMutations({
      setWidgets: "Widgets/setObject",
      // setDT: "Datatable/setObject"
    }),

    ...mapActions({
      fetchPageData: "Global/fetchPageData"
    }),

     fetchData({activeFilter = 0, params = null}) {
      let cnf = this.config
       if(null===this.config){
         // console.log(this.$getheaders())
         cnf = {headers: this.$getheaders()}
       }

      this.fetchPageData({
        apiPath: this.apiPath,
        method: this.method ?? "GET",
        headers: cnf.headers,
        withFilter: this.withFilter,
        activeFilter: activeFilter,
        params: params
      })

      /*
      let url = params ? `${this.apiPath}?params=${params}` : this.apiPath;

      return await axios({
        url: url,
        method: this.method ?? "GET",
        headers: this.config.headers
      })
          .then(res => res.data)
          .then(res => {
            let topWidgets = [];
            let bottomWidgets = [];

            if (url.includes('details')) {
              res.map(el => el.position && el.position === "bottom" ? bottomWidgets.push(el) : topWidgets.push(el));
            }

            this.setWidgets({topWidgets: topWidgets, bottomWidgets: bottomWidgets});

            // let dt = {};
            // let response = res;
            // if (this.withFilter === true) {
            //   dt.actionFilters = res;
            //   !params ? dt.activeFilter = 0 : null;
            //   response = res.length > 0 && res[this.$store.state.Datatable.activeFilter].items ? res[this.$store.state.Datatable.activeFilter].items : [];
            // }
            //
            // if (response.headers) {
            //   dt.columns = response.headers ? response.headers : (response.data && response.data.length > 0) ? Object.keys(response.data[0]).map(itm => ({
            //     field: itm,
            //     header: itm,
            //     sortable: true,
            //     color: COLORS.ICONA_DEALERS
            //   })) : [];
            //   response.data ? dt.items = response.data : null
            //   response.paginate ? dt.paginate = response.paginate : null
            //   response.actions ? dt.headerItems = response.actions : null
            // }
            //
            // (response.length > 0 || typeof response === 'object') ? this.setDT(response) : this.setDT(initialState.DATATABLE());

            return res;
          })
       */
    }
  },

  mounted() {
    this.fetchData({});
    // console.log(this.dtFromStore);
  }

}
</script>

<style scoped>

</style>