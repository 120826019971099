
const Main_getters = {

    getSidebarOpened(state) {
        return state.sidebar.sidebar_opened;
    },

    getSidebarItems(state) {
        return state.sidebar.items;
    },

    // getSidebarOptions(state) {
    //     return state.sidebar.options;
    // },

    getUserMenu(state) {
        return state.userMenu;
    },

    getConfigs(state) {
        return state.configs
    },

    getOptions(state) {
        return state.options
    },

    getSidebar(state) {
        return state.sidebar
    },

    getSidebarOverlayContent(state) {
        return (state.sidebar.sidebar_opened && state.sidebar.isModal && state.sidebar.dismissable);
    },

    getReady(state) {
        return state.ready
    },

    getLogged(state) {
        return state.logged
    },

    getStatusApplication(state){
        return state.install
    }
};

export default Main_getters;
