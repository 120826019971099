<template>

  <v-layout row wrap>
    <v-flex xs12>

      <v-card elevation="1">
        <v-card-title><h4 class="display-1">Faqs</h4></v-card-title>
        <v-card-text>
          <v-card-text v-if="faqs!==null" >
            <v-row justify="center">

              <v-col cols="12"  v-for="(item,i) in faqs" :key="i">
                <v-card elevation="0">

                  <v-card-text>
                    <VueMarkdown>{{item.text}}</VueMarkdown>
                  </v-card-text>
                </v-card>

              </v-col>
            </v-row>

          </v-card-text>
        </v-card-text>
      </v-card>


    </v-flex>
  </v-layout>

</template>

<script>
import VueMarkdown from 'vue-markdown'
export default {
  name: "FaqsElements",
  components: {
    VueMarkdown
  },

  data(){
    return{
      faqs:null,
    }
  },
  mounted() {
    this.featchDataFaqs();
  },
  methods:{
    featchDataFaqs(){
      axios({
        method:'get',
        url:'/api/v1/faqs',
        headers: this.$getTnk()
            ? {Authorization: `Bearer ${this.$getTnk()}`} : null,
      }).then(res=>{
        this.faqs=res.data.faqs
      }).catch(err=>{

      })
    }
  }
}
</script>

<style scoped>

</style>