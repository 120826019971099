

const Routing_Route_mutations = {

    setCurrentRoute(state,data){
        Object.assign(state.current_route, data)
    },

    setAllRoutes(state,data){
        Object.assign(state.store_route, data)
    },

    setCurrentSyncServer(state,data){
        Object.assign(state.sync_server_params,data)
    }

}

export default Routing_Route_mutations;
