

const Widgets_mutations = {
    setObject(state, data) {
        Object.keys(data).forEach(e => state[e] = data[e])
        // Object.keys(data).forEach(e => (typeof data[e] === "object") ? Object.assign(state[e], data[e]) : state[e] = data[e])
    },

}

export default Widgets_mutations;
