const MOCK_TABS = {
    tabs: [
        {
            title: "Anagrafica",
            icon: "fa-regular fa-user",
            content: "Pulchritudines studere in lutetia!Cobaltums nocere in brigantium!A falsis, cannabis neuter homo.",
            contentType: "details",
            items: [
                {label: "Codice", name: "cod", value: "01234"},
                {label: "Codice POS", name: "cod_pos", value: "56789"},
                {label: "Codice 9Mld", name: "cod_mld", value: "012345678"},
            ]
        },
        {
            title: "Pdv",
            icon: "fa-solid fa-ticket",
            content: "Cum urbs observare, omnes frondatores experientia ferox, superbus lactaes.Heu, fides!Castus demissio patienter amors diatria est.Pol.",
            contentType: "details",
            items: [
            ]
        },
        {
            title: "Tickets",
            icon: "fa-solid fa-ticket",
            content: "Est noster frondator, cesaris.A falsis, pes germanus messor.",
            contentType: "details",
            items: [
            ]
        }
    ],

}

export default MOCK_TABS;