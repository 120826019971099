<template>

  <v-layout row wrap v-if="server_model!==null">

    <v-flex xs12>

      <v-card elevation="1">

        <v-card-title>
          Esportazione con filtri
        </v-card-title>
        <v-card-subtitle>
          <v-row dense>
            <v-col>
              <v-select :items="utility.state_id"
                        clearable
                        label="Stato"
                        item-text="item-text" item-value="item-value"
                        v-model="filters.state_id" />
            </v-col>

            <v-col>
              <v-menu ref="created_at_from" v-model="shows.created_at_from" :close-on-content-click="false"
                  :return-value.sync="filters.created_at_from" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="filters.created_at_from" label="Data Da:" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="filters.created_at_from" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="shows.created_at_from = false">
                    Cancella
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.created_at_from.save(filters.created_at_from)">
                    Salva</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu ref="created_at_to" v-model="shows.created_at_to" :close-on-content-click="false"
                      :return-value.sync="filters.created_at_to" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="filters.created_at_to" label="Data A:" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="filters.created_at_to" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="shows.created_at_to = false">
                    Cancella
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.created_at_to.save(filters.created_at_to)">
                    Salva</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-btn

                  x-small
                  class="white--text mt-3 pa-5"
                  @click="prepareDownloadLink"
                  color="purple">
                <v-icon>
                  mdi-download
                </v-icon>
                Download
              </v-btn>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
              :headers="datatable.headers"
              :items="getTableItems"
              >

          </v-data-table>
        </v-card-text>

      </v-card>



    </v-flex>


  </v-layout>


</template>

<script>
export default {
  name: "ExportFiltering",
  data(){
    return{
      server_model:null,
      current:null,
      datatable:null,
      filters:null,
      utility:null,
      shows:{
        created_at_from:false,
        updated_at:false
      }
    }
  },
  mounted() {
    this.fetchDatum()
  },
  watch:{
    filters:{
      handler(){
        console.log(this.filters)
      },
      deep:true
    }
  },

  computed:{
    getTableItems(){
      let obj = [];
      let from = this.filters.created_at_from!==null ? new Date(this.filters.created_at_from).getTime()/1000:0
      let to = this.filters.created_at_to!==null ? new Date(this.filters.created_at_to).getTime()/1000:new Date().getTime()

      obj = this.datatable.dati.filter(e=>e.filter.created_at>=from && e.filter.created_at<=to?e:null)
                                .filter(e=>e!=null)

      if(this.filters.state_id!==null){
        obj = obj.filter(e=>e.filter.state_id === this.filters.state_id)
      }

      return obj
    }
  },
  methods:{

    fetchDatum(){

      axios({
        method:'get',
        url: '/api/v1/exporter-filters',
        headers: this.$getTnk()
            ? {Authorization: `Bearer ${this.$getTnk()}`} : null,
      }).then(res=>{
        this.server_model=res.data
        this.datatable=res.data.datatable
        this.filters=res.data.filters
        this.utility=res.data.utils
      }).catch(error=>{

      })

    },
    prepareDownloadLink(){
      axios({
        method:'post',
        url:'/api/v1/prepare-export-filtering',
        headers: this.$getTnk()
            ? {Authorization: `Bearer ${this.$getTnk()}`} : null,
        data:{
          ids:this.getTableItems.map(e=>e.id)
        }
      }).then(response=>{
        window.open(response.data.follow)
      }).catch(err=>{
      })
    }


  }
}
</script>

<style scoped>

</style>