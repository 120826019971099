

import Import_getters from "./_getters";
import Import_mutations from "./_mutations";
import Import_actions from "./_actions";
import initialState from "@/constants/InitialState";

const Import = {

    namespaced: true,
    state: () => initialState.IMPORTS(),

    getters:{
        ...Import_getters
    },
    mutations:{
        ...Import_mutations
    },
   actions:{
        ...Import_actions
    }

}

export default Import;
