import INITIAL_STATE from "@/constants/InitialState";
import router from "@/router";
import GenericIndexPage from "@/pages/generic/GenericIndexPage.vue";
import IncludedPage from "@/pages/IncludedPage.vue";

const addNewRoute = (sb, configs) => {
    if (sb.name === 'frontend.pdvs.index') {
        let filtered = sb.items.filter((el) => el.label === 'pdv.import');
        if (filtered.length) {
            filtered[0].name = 'pdvs.import';
            filtered[0].uri = '/frst-upload-wresp';
            filtered[0].uri_save = '/finalize-upload-wresp';
            filtered[0].method = "POST"
            filtered[0].to = "/pdvs/import"
        }
    }

    if (sb.name && (sb.name.includes("index") || sb.name.includes("details") || sb.name.includes("import"))) {

        // let k = sb.name.replace('frontend.', process.env.BASE_URL).replace(/\./g, '/').split('/');

        // if (k.length > 3 && (k[3] === 'index' || k3[3] === 'details')) {
        // console.log(sb.to, sb.name);
        let props = sb.props ?? {};
        // props.title = sb.name;
        props.routeName = sb.name;
        props.apiPath = sb.uri ?? "";
        props.config = configs[sb.name];
        props.method = sb.method ?? "GET";

        sb.uri_save ? props.apiPathSave = sb.uri_save : null;

        router.addRoute('backend', {
            path: sb.to ?? '#',
            name: sb.name,
            component: (sb.name.includes("tickets") || sb.name.includes("import")) ? IncludedPage : GenericIndexPage,
            props: props,
        });

        // console.log(router.getRoutes(), "---> eccomi");


        // }

        (sb.items && sb.items.length > 0) ? sb.items.map(el => addNewRoute(el, configs)) : null
    }
}

const Main_actions = {

    async initRequests({commit, dispatch, state}, data) {
        const {_token, reload} = data;
        //
        //  const xhr = new XMLHttpRequest();
        //  xhr.open('HEAD', process.env.VUE_APP_API_PATH + process.env.VUE_APP_API_VER + process.env.VUE_APP_API_CONFIG, false);
        //  xhr.withCredentials = true;
        //  xhr.setRequestHeader('Authorization',   sessionStorage.getItem('jwt_token')
        //      ? `Bearer ${sessionStorage.getItem('jwt_token')}` : null)
        //
        //  xhr.send();
        // if(xhr.status<300){

        let headers = _token ? {Authorization: `Bearer ${_token}`} : {};

        headers['Accept'] = 'Application/Json';
        headers['Content-Type'] = 'Application/Json';
        axios({
            method: 'get',
            url: process.env.VUE_APP_API_PATH + process.env.VUE_APP_API_VER + process.env.VUE_APP_API_CONFIG,
            headers: headers,

        })
            .then(response => {
                // console.log(response);
                return response.data
            })
            .then(res => {
                if (!res.webUi) return null;

                commit('Main/setConfigInitial', res.webUi, {root: true});
                commit('Main/setInstall', res.install, {root: true});
                commit('Routing_Route/setAllRoutes', res.webUi.configs, {root: true})
                commit('Utilities/initUtilities', res.utilities, {root: true});
                commit('Main/setObject', {logged: res.isLogged ?? false}, {root: true});
                commit('Main/setObject', {language: res.lang ?? {}}, {root: true});
                res.lang ? localStorage.setItem('lang', JSON.stringify(res.lang)) : null;
                commit('Account/setAccount', res.user ?? INITIAL_STATE.ACCOUNT(), {root: true});
                // commit('Dashboard/setDashboard', res.dashboard ?? INITIAL_STATE.MAIN().dashboard, {root: true});

                if (!res.install.status) {
                    commit('Global/setCustomDialog', res.install.popup, {root: true})
                }


                return res.webUi
            })
            .then(res => {
                if (res && res.configs && res.sidebar) {
                    (res.sidebar.items && res.sidebar.items.length > 0) ? res.sidebar.items.map(sb => addNewRoute(sb, res.configs)) : null;
                    // Object.keys(res.configs).map(k => {
                    //     let props = [];
                    //     props.title = k;
                    //     props.routeName = k;
                    //     props.apiPath = res.configs[k].options.base;
                    //     props.config = res.configs[k];
                    //     props.method = res.configs[k].options.method;
                    //
                    //     router.addRoute('backend', {
                    //         path: sb.to ?? '#',
                    //         name: sb.name,
                    //         component: GenericIndexPage,
                    //         props: props,
                    //     });
                    // })
                }
            })
            .catch((error) => {
                if(error.response && error.response.data && error.response.data.lang) {
                    commit('Main/setObject', {language: error.response.data.lang}, {root: true});
                    localStorage.setItem('lang', JSON.stringify(error.response.data.lang));
                }
                if (error.response && error.response.status === 401 && reload === true) {
                    window.location.reload();
                } else {
                    commit('Main/setObject', {language: error.response.data.lang ?? {}}, {root: true});
                    // console.log(error.message, "---> initRequests")
                }
            })
            .finally((res) => {
                // console.log(router.getRoutes());
                commit('Main/setObject', {ready: true}, {root: true});
            })
        //  }


    },

}

export default Main_actions;
