<template>
  <PCard class="generic__page elevate" style="margin-top: 1em;">
    <template #title v-if="title">
      <p :class="`generic__page-title ${contentType ?? ''}`">
        {{ $t_("APP__TITLE__STORICO_TK_N") + " " +  title }}
      </p>
    </template>
    <template #content>
      <div class="generic__page-content">
        <slot name="content">
          <h2>Content</h2>
        </slot>
      </div>
    </template>
  </PCard>
</template>

<script>
export default {
  name: "HistoryComponent",
  props: ['title']
}
</script>

<style scoped>

</style>