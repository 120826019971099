<template>
  <div :class="['main__content', sidebarOpened ? 'main__content-sidebar_opened' : '']"
       v-styleclass="{ selector: '@next', toggleClass: 'main__content-no_sidebar'}" :curr_route="$route.name">

<!--    <h3 v-if="wip === true" class="mock__message">&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; MOCK DATA (WIP) &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;</h3>-->

    <router-view :key="$route.path" @routeEvents="chooseAction"></router-view>
    <!--    <ConfirmComponent v-if="displayConfirm"/>-->
    <v-overlay v-if="sidebarOverlayContent" key="sidebar_custom-overlay" class="" v-model="sidebarOverlayContent"
               @click="$store.commit('Main/setSidebarOpened', false)">
    </v-overlay>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MainContent",

  computed: {
    ...mapGetters({
      sidebarOpened: "Main/getSidebarOpened",
      sidebarOverlayContent: "Main/getSidebarOverlayContent",
      wip: "Global/getWIP"
      // displayConfirm: "Global/getDisplayDialog"
    })
  },

  watch: {
    $route() {
      this.wip ? this.$store.commit("Global/setObject", {wip: false}) : null;
    }
  },

  methods: {
    chooseAction(obj){
      console.log(obj)
      if(obj.run !==undefined){
        this.$pushRoute(obj.run)

      }
    }
  }

}
</script>

<style scoped>

</style>