<template>
  <div class="sidebar__logo title">
    <a :href="basePath" v-tooltip.left="title ? $t_(title) : $t_('APP_TITLE_GO_HOME')">
      <p>To<span>Sca</span></p>
    </a>
  </div>
</template>

<script>
export default {
  name: "LeftLogo",
  props: ['title'],

  data: () => ({
    basePath: process.env.BASE_URL
  })

}
</script>

<style scoped>

</style>