<template>
  <div v-if="totalLinks > 0" class="text-center">
    <v-pagination
        v-model="page"
        :length="totalLinks"
        :total-visible="7"
        @input="clickPage"
    ></v-pagination>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "DTPaginatorComponent",
  props: ['paginate'],
  data: () => ({
    page: 1,
    links: [],
    pageLinkSize: 5,
    currentPageTemplate: "Elementi da {first} a {last} di {totalRecords}",
    rowPerPage: [2, 15, 25, 50, 100],
  }),
  mounted() {
    // console.log(this.paginate)
  },
  watch: {
    paginate: {
      handler() {
        (this.paginate && this.paginate.links) ? this.links = this.paginate.links : null;
        this.page = this.paginator.current_page
      }, deep: true
    }
  },

  computed: {
    ...mapGetters({
      paginator: "Ticket/getPaginator",
      aside: "Ticket/getRightSidebar"
    }),
    totalLinks() {
      return this.paginate.last_page !== undefined ? this.paginate.last_page : (this.links.length >= 2 ? this.links.length - 2 : 0)
    },
    sideFilter() {
      let filter = {};
      Object.keys(this.aside.values).filter(e => Boolean(this.aside.values[e])).map(e => filter[e] = this.aside.values[e]['item-value'] !== undefined
          ? this.aside.values[e]['item-value'] : this.aside.values[e])
      return filter;
    }
  },

  methods: {
    clickPage(event) {
      // this.$store.state.Ticket.aside.values['page']=event;

      this.$router.push({name: "frontend.tickets.index_paginatore", params: {id: event}})

      let url = `${this.paginator.path}?page=${event}`;
      // Object.keys(this.sideFilter).map((el, idx) => {
      //   url += `${el}=${this.sideFilter[el]}`
      // })
      this.$emit('selectPage', {url: url, page: event})
      // (this.links[event] && this.links[event].url) ? this.$emit('selectPage', `${this.paginator.path}?page=${event}`) : null;
      // console.log(this.links[event].url,event)
      // console.log(this.paginator);
      this.page = this.paginator.current_page
    }
  }

}
</script>

<style scoped>

</style>