import router from "@/router";
const Global_mutations = {

    setObject(state, data) {
        Object.keys(data).forEach(e =>  state[e] = data[e])
        // Object.keys(data).forEach(e =>  (typeof data[e] === "object") ? Object.assign(state[e], data[e]) : state[e] = data[e])
    },

    setitems(state,data){

        Object.assign(state.item, data)
    },

    updateValueStore(state,data){
        state.item[data.step].values[data.key] = data.value
    },

    setObjectOne(state, data){
        data.arrayValues
            ? state.item[data['step']].values = data['arrayValues']
            : state.item[data['step']].values[data['chiave']]=data['value']
    },

    resetStore(state){
        state.item=[]
        state.step=1
    },

    initToast(state, group) {
        state.displayToast = true;
        state.toast.group = group
    },

    setCustomDialog(state,data){
        let actions = {
            'navigate': (val) => {
                // console.log(val);
                window.location = val;
            }
        }
        let intersezione = Object.keys(state.dialog).filter(x =>  Object.keys(data.dialog).includes(x));
        for(let i in intersezione) {
            state.dialog[intersezione[i]] = data.dialog[intersezione[i]];
        }


        // console.log((typeof state.dialog.onYes === 'object'), "---> Check");
        // debugger;
        if(state.dialog.onYes === 'object') {
        // if(typeof state.dialog.onYes === 'object') {
            // console.log(Object.keys(state.dialog.onYes));
            console.log(state.dialog.onYes[Object.keys(state.dialog.onYes)[0]], "---> Mutation");
            state.dialog.onYes = () => actions[Object.keys(state.dialog.onYes)[0]](state.dialog.onYes[Object.keys(state.dialog.onYes)[0]])
        }

        setTimeout(()=>{
            state.displayDialog=data.displayDialog;
        },1000)
    },

    setWIP(state, data) {
        state.wip = data;
    }

}

export default Global_mutations;
