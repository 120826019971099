<template>
  <PDialog style="--maxContentWidth: '90%'; --contentWidth: 800px" v-if="confirmation.type === 'dialog-upload'"
           :header="confirmation.title" :visible.sync="$store.state.Global.displayDialog"
           :modal="true"
           @update:visible="onCloseConfirmation">
<!--    <v-custom-file-list :value="files" />-->
    <div class="file_upload__container">
      <UploadComponent @update="updateFiles" accept=".xlsx" :multiple="false" :parentProps="confirmation.parentProps" :send-route="true"/>
    </div>
<!--    <UploadComponent accept=".csv" :maxFileSize="100000"-->
<!--                     :icon="icons[confirmation.type]" :text="confirmation.text"/>-->
  </PDialog>
  <PDialog v-else :header="confirmation.title" :visible.sync="$store.state.Global.displayDialog" :modal="true"
           @update:visible="onCloseConfirmation" :closable="!confirmation.loader || value >= 100">
    <div class="confirmation__content">
      <i v-if="confirmation.showIcon" :class="icons[confirmation.type ?? 'dialog-info']"
         :dialogType="confirmation.type"/>
      <div v-if="typeof text === 'object'" class="multi-line">
          <p v-for="(itm, idx) in text" :key="`dialog__text__${idx}`"><span>{{itm.title}}: </span>{{ itm.text }}</p>
      </div>
      <span v-else>{{ text }}</span>
    </div>
    <PProgressBar v-if="confirmation.loader"
                  :mode="!confirmation.loader.fakeProgress && confirmation.loader.mode ? confirmation.loader.mode : undefined"
                  :value="value"
                  :showValue="confirmation.loader.showValue ?? true" :style="confirmation.loader.styles"/>
    <template #footer v-if="!confirmation.hideButtons">
      <PButton v-if="confirmation.onYes" :label="confirmation.yesLabel" :icon="confirmation.yesIcon"
               @click="actionButton('onYes')" class="p-button-success"/>
      <PButton v-if="confirmation.onNo" :label="confirmation.noLabel" :icon="confirmation.noIcon"
               @click="actionButton('onNo')" class="p-button-secondary"/>
    </template>
  </PDialog>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import PrimeIcons from "@/constants/PrimeIcons";

export default {
  name: "ConfirmComponent",
  data: () => ({
    icons: PrimeIcons,
    value: 0,
    text: '',
    fakeMessages: [],
    files: []
  }),

  intervall: null,

  computed: {
    ...mapGetters({
      confirmation: "Global/getDialog",
      // displayDialog: "Global/getDisplayDialog"
    })
  },

  watch: {
    value() {
      if(this.confirmation.loader) {
        (typeof this.confirmation.loader.closeOnEnd === 'function' && this.value >= 100) ? setTimeout(() => {
          this.closeConfirmation();
        }, this.confirmation.loader.closeTimeout ?? 1000) : null;
      }
    }
  },

  methods: {
    ...mapMutations({
      setGlobalObj: "Global/setObject"
    }),

    updateFiles(val) {
      console.log(val, "---> Uploaded");
    },

    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.value + Math.floor(Math.random() * 10) + 25;
        if (newValue >= 100) {
          newValue = 100;
          this.fakeMessages.length >= 3 ? this.text = this.fakeMessages[this.fakeMessages.length - 1] : null;
        } else if (newValue >= 50){
          this.fakeMessages.length >= 2 ? this.text = this.fakeMessages[1] : null;
        } else {
          this.fakeMessages.length >= 1 ? this.text = this.fakeMessages[0] : null;
        }
        this.value = newValue;
      }, this.confirmation.loader.fakeDelay ?? 1000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },

    onCloseConfirmation(value) {
      this.confirmation.onClose ? this.confirmation.onClose() : null;

      !value ? this.closeConfirmation() : null;
      // if(!value) {
      //   this.displayDialog = false;
      //   this.setGlobalObj({confirmation: this.confirmation});
      // }
    },

    closeConfirmation() {
      this.setGlobalObj({displayDialog: false});
      // this.displayDialog === true ? this.setGlobalObj({displayDialog: false}) : null;
    },

    actionButton(action) {

      if(typeof(this.confirmation[action]) === 'object'){
        this.$makeMapConfirmationActionButtons(this.confirmation,action)
      }else{
        (this.confirmation[action] instanceof Function) ? this.confirmation[action]() : this.confirmation[action];
      }

      this.closeConfirmation();
    },
  },

  mounted() {
    this.text = this.confirmation.text ?? '';

    if (this.confirmation.loader && this.confirmation.loader.fakeProgress) {
      this.fakeMessages = this.confirmation.loader.fakeMessages ?? [];
      (this.confirmation.loader.value >= 0 && this.confirmation.loader.value <= 100) ? this.value = this.confirmation.loader.value : null;
      this.startProgress();
    }
  },
  beforeDestroy() {
    if(this.confirmation.loader) {
      this.confirmation.loader.fakeProgress ? this.endProgress() : null;

      typeof this.confirmation.loader.closeOnEnd === 'function' ? setTimeout(() => {
        this.confirmation.loader.closeOnEnd();
      }, this.confirmation.loader.closeTimeout ?? 1000) : null;
    }
  },

  beforeMount() {
    this.$store.commit("Global/initToast", this.$store.state.Global.dialog.toast_group ?? 'topbar')
  },

}
</script>

<style scoped>
</style>