

import Ticket_getters from "./_getters";
import Ticket_mutations from "./_mutations";
import Ticket_actions from "./_actions";
import initialState from "@/constants/InitialState";

const Ticket = {

    namespaced: true,
    state: initialState.TICKETS(),

    getters:{
        ...Ticket_getters
    },
    mutations:{
        ...Ticket_mutations
    },
   actions:{
        ...Ticket_actions
    }

}

export default Ticket;
