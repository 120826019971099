import Vuetify from "vuetify";
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css'
import VFormBase from 'vuetify-form-base';

export const vuetifyComponents = (app) => {
    app.use(Vuetify)
    app.component('v-form-base', VFormBase);
}

export const vuetifyConfig = new Vuetify(
    {
        icons: {
            iconfont: 'mdi', // default - only for display purposes
        },
        rtl: false,
        theme: {dark: false},
        /*lang: {
            current: 'it',
        },*/
        // preset,
        // rtl: true,
        // theme: { dark: true },
    },
)