<template>
  <div class="notify__header-container">
    <PButton icon="pi pi-bell" :badge="notifiche.length > 99 ? '99+' : notifiche.length.toString()"
             :badgeClass="`p-badge-danger ${notifiche.length >= 10 ? 'big' : ''}`"
             @click="toggle" aria-haspopup="true" aria-controls="notify_menu"></PButton>
    <POverlayPanel ref="notify_menu" :dismissable="true" :showCloseIcon="false">
      <div v-for="(n, idx) in notifiche" :key="`notify_${idx}`">
        <h3>
          {{ n.title }}
        </h3>
        <p>
          {{ n.text }}
        </p>
      </div>

    </POverlayPanel>
  </div>
</template>

<script>
export default {
  name: "NotifyHeaderButton",

  data: () => ({
    notifiche: [
      {
        id: 0,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 1,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 2,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 3,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 4,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
    ]
  }),

  methods: {
    toggle(event) {
      this.$refs.notify_menu.toggle(event);
    }
  },

}
</script>

<style scoped>

</style>