<template>
  <v-container v-if="widgets && widgets.length > 0">
    <v-row v-for="(w, idx) in widgets" :key="w.name + '_' + idx">
<!--      <WidgetComponent v-for="(itm, index) in w.items" :key="itm.name + '_' + index"-->
<!--                       :layout="itm.layout" :icon="itm.icon" :title="itm.title"-->
<!--                       :colors="itm.colors" :align-title="itm.align_title" :type="itm.type" :items="itm.items" :datatable="itm.datatable" :value="itm.value"/>-->
      <WidgetComponent v-for="(itm, index) in w.items" :key="itm.name + '_' + index" :item="itm"/>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Home",

  data: () => ({
    widgets: [],
      ready: false
  }),

  computed: {
    ...mapGetters({
      // widgets: "Dashboard/getDashboardWidgets"
    })
  },

  created() {
    this.$store.commit("Global/setObject", {wip: true})
  },

}
</script>

<style scoped>
</style>