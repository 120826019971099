export const MOCK_VFORM = {
    "schema": {
        "space1": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {
                "xs": 12,
                "sm": 12,
                "md": 12,
                "lg": 12,
                "pa": 2
            },
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "",
            "mask": "",
            "multiple": false,
            "name": "space1",
            "order": 20,
            "outlined": false,
            "placeholder": "",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [],
            "selected": [],
            "shaped": false,
            "sort": 20,
            "tooltip": false,
            "type": "custom-spacer",
            "value": ""
        },
        "space2": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {
                "xs": 12,
                "sm": 12,
                "md": 12,
                "lg": 12,
                "pa": 2
            },
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "",
            "mask": "",
            "multiple": false,
            "name": "space2",
            "order": 30,
            "outlined": false,
            "placeholder": "",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [],
            "selected": [],
            "shaped": false,
            "sort": 30,
            "tooltip": false,
            "type": "custom-spacer",
            "value": ""
        },
        "email": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 6, "pa": 1},
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "Email utente",
            "mask": "",
            "multiple": false,
            "name": "email",
            "order": 30,
            "outlined": false,
            "placeholder": "Caio.tizio@gmail.com",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [["validEmail", "L'email dell'utente \u00e8 richiesta"]],
            "selected": [],
            "shaped": false,
            "sort": 30,
            "tooltip": false,
            "type": "text",
            "value": "mario.mennella@rrdl.it"
        },
        "id": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 6, "pa": 1},
            "fn": null,
            "hidden": true,
            "hint": "",
            "label": "ID utente",
            "mask": "",
            "multiple": false,
            "name": "id",
            "order": 40,
            "outlined": false,
            "placeholder": "X",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [],
            "selected": [],
            "shaped": false,
            "sort": 40,
            "tooltip": false,
            "type": "text",
            "value": 1
        },
        "name": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 6, "pa": 1},
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "Username",
            "mask": "",
            "multiple": false,
            "name": "name",
            "order": 50,
            "outlined": false,
            "placeholder": "Caio",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [["required", "Lo username \u00e8 richiesto"]],
            "selected": [],
            "shaped": false,
            "sort": 50,
            "tooltip": false,
            "type": "text",
            "value": "utente1"
        },
        "password": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 6, "pa": 1},
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "Password utente",
            "mask": "",
            "multiple": false,
            "name": "password",
            "order": 60,
            "outlined": false,
            "placeholder": "**********",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [["required", "La password dell'utente \u00e8 richiesta"]],
            "selected": [],
            "shaped": false,
            "sort": 60,
            "tooltip": false,
            "type": "text",
            "value": ""
        },
        "remember_token": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 6, "pa": 1},
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "Ricorda token",
            "mask": "",
            "multiple": false,
            "name": "remember_token",
            "order": 70,
            "outlined": false,
            "placeholder": "Vero",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [["required", "Il campo ricorda token \u00e8 richiesto"]],
            "selected": [],
            "shaped": false,
            "sort": 70,
            "tooltip": false,
            "type": "checkbox",
            "value": ""
        },
        "roles": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 6, "pa": 1},
            "fn": ["getRoles"],
            "hidden": false,
            "hint": "",
            "item-text": "item-text",
            "item-value": "item-value",
            "label": "Selezione multipla ruolo",
            "mask": "",
            "multiple": true,
            "name": "permissions",
            "order": 80,
            "outlined": false,
            "placeholder": "",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [["required", "Il campo selezione multipla ruolo \u00e8 richiesto"]],
            "selected": [],
            "shaped": false,
            "sort": 80,
            "tooltip": false,
            "type": "combobox",
            "value": "",
            "items": [{"id": 1, "item-text": "admin", "item-value": 1, "name": "admin"}, {
                "id": 2,
                "item-text": "sales",
                "item-value": 2,
                "name": "sales"
            }, {"id": 3, "item-text": "logistic_operator", "item-value": 3, "name": "logistic_operator"}, {
                "id": 4,
                "item-text": "rti_gesel",
                "item-value": 4,
                "name": "rti_gesel"
            }, {"id": 5, "item-text": "rti_ssgrr", "item-value": 5, "name": "rti_ssgrr"}, {
                "id": 6,
                "item-text": "dealer_care",
                "item-value": 6,
                "name": "dealer_care"
            }, {"id": 7, "item-text": "fraud", "item-value": 7, "name": "fraud"}]
        },
        "files": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 12, "pa": 1},
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "Documents",
            "mask": "",
            "multiple": false,
            "name": "name",
            "order": 90,
            "outlined": false,
            "placeholder": "Caio",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [["required", "Lo username \u00e8 richiesto"]],
            "selected": [],
            "shaped": false,
            "sort": 90,
            "tooltip": false,
            "type": "custom-file-list",
            "value": "",
            "items": []
        }
    },
    "values": {
        "space1": {
            "title": "Riepilogo",
            "icon": "",
            "class": "",
            "style": ""
        },
        "space2": {
            "title": "Anagrafica Dealer",
            "icon": "far fa-user",
            "class": "outlined",
            "style": ""
        },
        "id": 1,
        "name": "utente1",
        "email": "mario.mennella@rrdl.it",
        "two_factor_code": "39572",
        "two_factor_expires_at": "2023-01-19 12:52:39",
        "created_at": "2022-10-14T15:56:35.000000Z",
        "updated_at": "2023-01-19T11:42:39.000000Z",
        "files": [
            {"id": 1, "item-text": "documento1.pdf", "item-value": 1, "name": "doc1", "icon": "fas fa-file-pdf", "file": null},
            {"id": 2, "item-text": "documento2.pdf", "item-value": 2, "name": "doc2", "icon": "fas fa-file-pdf", "file": null},
            {"id": 3, "item-text": "documento3.pdf", "item-value": 3, "name": "doc3", "icon": "fas fa-file-pdf", "file": null},
            {"id": 4, "item-text": "documento4.pdf", "item-value": 4, "name": "doc4", "icon": null, "file": null}
        ],
    },
    label: "Test Form"
}

export const MOCK_VFORM_UPLOAD = {
    "label": "Allegati",
    "schema": {
        "files": {
            "backgroundColor": "",
            "chips": false,
            "class": "pl-3 pr-3",
            "color": "orange",
            "component": "",
            "css": "pa-1",
            "data": [],
            "default": "",
            "dense": true,
            "disable": false,
            "filled": false,
            "flex": {"xs": 12, "sm": 12, "md": 12, "lg": 12, "pa": 1},
            "fn": null,
            "hidden": false,
            "hint": "",
            "label": "Documents",
            "mask": "",
            "multiple": false,
            "name": "name",
            "order": 10,
            "outlined": false,
            "placeholder": "Caio",
            "readonly": false,
            "require": true,
            "rounded": false,
            "rules": [],
            "rules_fn": [["required", "Lo username \u00e8 richiesto"]],
            "selected": [],
            "shaped": false,
            "sort": 10,
            "tooltip": false,
            "type": "custom-file-list",
            "value": "",
            "items": []
        }
    },
    "values": {
        "files": [
            {"id": 1, "item-text": "documento1.pdf", "item-value": 1, "name": "doc1", "icon": "fas fa-file-pdf", "file": null},
            {"id": 2, "item-text": "documento2.pdf", "item-value": 2, "name": "doc2", "icon": "fas fa-file-pdf", "file": null},
            {"id": 3, "item-text": "documento3.pdf", "item-value": 3, "name": "doc3", "icon": "fas fa-file-pdf", "file": null},
            {"id": 4, "item-text": "documento4.pdf", "item-value": 4, "name": "doc4", "icon": null, "file": null}
        ]
    }
};