<template>
  <div :class="withFilter ? 'with_filter' : ''">
    <!--    <div v-if="withFilter" class="filter">-->
    <!--      <slot name="dt_filter_slot">-->
    <!--        <PButton :key="`filter_btn_${k}`" v-for="(el, k) in dt.actionFilters"-->
    <!--                 :class="`border-all filter-btn ${k === dt.activeFilter ? 'active' : ''}`"-->
    <!--                 v-tooltip="$t_(el.title)" @click.prevent="clickFilter(k, el)">-->
    <!--          <div class="counter">-->
    <!--            <div class="counter__value">{{ el.counter }}</div>-->
    <!--          </div>-->
    <!--          <span>{{ $t_(el.title) }}</span>-->
    <!--        </PButton>-->
    <!--      </slot>-->
    <!--    </div>-->
    <div :class="withFilter ? 'content' : ''">
      <slot name="dt_header_slot" v-if="dt.headerItems && dt.headerItems.length > 0">
        <div class="card__menu_header">
          <DTHeaderComponent v-for="(hitem, idx) in dt.headerItems" :item="hitem" :reference="`dt_header_menu_${idx}`"
                             :key="`dt_header_menu_${idx}`"/>
        </div>
      </slot>
      <!--      <v-data-table-->
      <!--          :headers="dt.headerItems"-->
      <!--          :items="dt.items"-->
      <!--          item-key="field"-->
      <!--          class="elevation-1"-->
      <!--          :custom-filter="test(dt)"-->
      <!--      >-->
      <!--      </v-data-table>-->
      <PDataTable :value="dt.items" :paginator="showPaginator"
                  :rows="dt.rows"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="dt.rowPerPage" responsiveLayout="scroll"
                  :currentPageReportTemplate="dt.currentPageTemplate" :sortMode="dt.sortMode"
                  :multiSortMeta="dt.multiSortMeta"
                  filterDisplay="menu"
                  :filters.sync="filterData"
                  :globalFilterFields="filterFields"
                  :class="`dt__generic ${classes ?? ''}`"
                  :rowGroupMode="dt.rowGroupMode" :groupRowsBy="dt.rowGroupBy"
      >
        <template #header>
          <div style="display:flex;justify-content: space-between;gap: 1em;">
            <span class="p-input-icon-left" style="width: 100%">
                    <i class="pi pi-search"/>
                    <PInputText :disabled="filterActived" v-model="filterString" placeholder="Keyword Search" @change="changeFilter"/>
            </span>
            <PButton :disabled="filterActived || filterString.length <= 0" type="icon-button" icon="pi pi-search" class="p-button"
                     @click="sendFilter"/>
            <PButton :disabled="disableClearFilter" type="icon-button" icon="pi pi-times"
                     class="p-button p-button-secondary"
                     @click="clearFilter"/>
          </div>
        </template>
        <PColumn v-for="col of dt.columns" :field="col.field" :header="$t_(col.header)" :key="col.field"
                 :sortable="(col.sortable && col.field !== 'actions')">

          <template #body="slotProps">
            <template v-if="slotProps.field === 'actions'">
              <slot name="dt_actions_slot">
                <div class="actions">
                  <PButton v-model="showActions" v-for="(sd,ind) in slotProps.data.actions"
                           :key="`_ind_general_key_${ind}`"
                           :icon="`${sd.icon !== '' ? sd.icon : Object.keys(primeIcons).includes(sd.text) ? primeIcons[sd.text] : 'pi pi-info'}`"
                           :class="`p-button-rounded ${sd.class}`"
                           v-tooltip.top="$t_(sd.text)"
                           @click="clickAction(sd,slotProps.data)"/>
                </div>
              </slot>
            </template>

            <!--            <template v-else-if="slotProps.field === 'stato'">-->
            <!--              <div class="dt__field_stato">-->
            <!--                <p :class="`col_state__${$transformString(slotProps.data.stato)}`">{{ slotProps.data.stato }}</p>-->
            <!--              </div>-->
            <!--            </template>-->
            <template v-else>
              <div v-if="slotProps.data[slotProps.field] instanceof Object">
                <v-expansion-panels>
                  <v-expansion-panel class="transparent">
                    <v-expansion-panel-header>Show Data</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p v-for="idx in Object.keys(slotProps.data[slotProps.field])" :key="`${slotProps.field}_${idx}`">
                        <b>{{ idx }}</b>: <i>{{ slotProps.data[slotProps.field][idx] }}</i>
                      </p>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                </v-expansion-panels>

              </div>
              <div v-else>
                {{ slotProps.data[slotProps.field] }}
              </div>
            </template>

          </template>
          <!--          <template #filter="slotProps">-->
          <!--            <PInputText type="text" v-model="slotProps.filterModel.value" class="p-column-filter" placeholder="Search" @click="test(slotProps)"/>-->
          <!--          </template>-->
          <!--          <template #filterapply="{filterCallback}">-->
          <!--            <PButton type="button" icon="pi pi-check" @click="test('ciao')" class="p-button-success"></PButton>-->
          <!--          </template>-->
        </PColumn>
        <template v-if="dt.rowGroupMode && dt.rowGroupBy" #groupheader="slotProps">
          <!--              <img :alt="slotProps.data.name" src="no-image.jpg" width="32" style="vertical-align: middle" />-->
          <span class="dt__row_group">{{ slotProps.data[rowGroupBy] }}</span>
        </template>
        <template #empty>
          {{ $t_('DATATABLE__NO_RECORDS_FOUND_MESSAGE') }}
        </template>
      </PDataTable>
      <template v-if="paginator.last_page !== undefined">
        <!--        <DTPaginatorComponent  :paginate="paginator" @selectPage="selectPage"/>-->
        <v-pagination
            v-model="currentPage"
            :length="paginator.last_page"
            color="purple"
            :total-visible="7"
            @input="selectPage"
        ></v-pagination>
      </template>
    </div>
  </div>
</template>

<script>
import COLORS from "@/constants/Colors";
import GenericVForm from "@/pages/generic/GenericVForm.vue";
import MOCK_DATA from "@/constants/mock_data";
import {mapActions, mapGetters, mapMutations} from "vuex";
import GenericIndexPage from "@/pages/generic/GenericIndexPage.vue";
import initialState from "@/constants/InitialState";

export default {
  name: "DTComponent",
  props: ['apiPath', 'method', 'config', 'withFilter', 'datatable', 'classes'],
  data: () => ({
    ...initialState.DATATABLE(),
    dt: initialState.DATATABLE(),
    showpage: false,
    currentPage: 1,
    filterData: {},
    filterString: '',
    filterActived: false
  }),

  watch: {
    paginate() {
      this.paginate && this.paginate.per_page ? this.setDT({rows: this.paginate.per_page}) : null;
    },

    // dt: {
    //   handler() {
    //     (this.dt && this.dt.filter) ? this.filterData = this.dt.filter : null;
    //   }, deep: true
    // }
  },

  computed: {
    ...mapGetters({
      configs: "Main/getConfigs",
      routes: "Routing_Route/getAllRoutes",
      dtFromStore: "Datatable/getDatatable",
      paginator: "Datatable/getPaginator"
    }),

    cnf() {
      return (null === this.config || this.config === undefined) ? {headers: this.$getheaders()} : this.config;
    },

    disableClearFilter() {
      return !(this.filterString && this.filterString.trim().length > 0 && this.filterActived)
    },

    filterFields() {
      return Object.keys(this.filterData);
    },

    showPaginator() {
      return Boolean(this.datatable);
    }

  },

  methods: {
    ...mapMutations({
      setObject: "Global/setObject",
      setWidgets: "Widgets/setObject",
      setDT: "Datatable/setObject",
      setCurrentProps: "Ticket/setPropsCreateScanner"
    }),
    ...mapActions({
      fetchPageData: "Global/fetchPageData"
    }),

    selectPage(url) {
      this.filterString = this.filterString.trim();
      url = this.paginator.links[url].url
      let params = this.filterActived ? this.filterString : null
      this.fetchPageData({
        apiPath: url,
        method: this.method ?? "GET",
        headers: this.cnf.headers,
        withFilter: this.withFilter,
        activeFilter: this.activeFilter,
        params: params
      })

    },

    changeFilter() {
      this.filterActived = false
    },

    clearFilter() {
      this.filterString = '';
      this.currentPage = 1;
      this.filterActived = false;

      this.fetchPageData({
        apiPath: this.apiPath,
        method: this.method ?? "GET",
        headers: this.cnf.headers,
        params: null
      })
    },

    sendFilter() {
      this.filterString = this.filterString.trim();
      !this.filterActived ? this.filterActived = true : null;

      if (!this.disableClearFilter) {
        this.currentPage = 1

        this.fetchPageData({
          apiPath: this.apiPath,
          method: this.method ?? "GET",
          headers: this.cnf.headers,
          params: this.filterString
        })
      }
    },

    // clickFilter(idx, item) {
    //   let params = item && item.params ? item.params : null;
    //   // this.callApi(params);
    //   // this.genericCall(params);
    //   this.activeFilter = idx;
    //   this.setDT({activeFilter: idx})
    //   this.fetchData({activeFilter: idx, params: params})
    // },

    dtable() {

      this.dt.columns = this.datatable.headers ? this.datatable.headers : (this.datatable.data && this.datatable.data.length > 0) ? Object.keys(this.datatable.data[0]).map(itm => ({
        field: itm,
        header: itm,
        sortable: true,
        color: COLORS.ICONA_DEALERS
      })) : [];

      this.datatable.data ? this.dt.items = this.datatable.data : null
      this.datatable.paginate ? this.dt.paginate = this.datatable.paginate : null
      this.datatable.actions ? this.dt.headerItems = this.datatable.actions : null

      this.dt.showDatatable = Boolean(this.dt.columns && this.dt.columns.length > 0);


    },

    deletehis(data,row){
      let dialog = MOCK_DATA.dialogs(this.$t_).WARNING;
      dialog.text = this.$t_("DATATABLE__DIALOG__TEXT__DELETE__RECORD"),
          dialog.onYes = () => {
            if (this.configs[data.follow] && this.configs[data.follow].headers) {
              axios.delete(data.to, {
                headers: this.configs[data.follow].headers
              }).then(res => {
                this.$showToast({
                  severity: 'success',
                  summary: this.$t_('APP__MESSAGE__TITLE__DELETED__OK'),
                  detail: this.$t_('APP__MESSAGE__DELETED__OK'),
                  life: 3000
                });
                if (!res.data.install.status) {
                  this.$store.dispatch('Main/initRequests', {_token: this.$getTnk()}, {root: true})
                }

              })
                  .catch(error => {
                    console.log(error.message);
                    this.$showToast({
                      severity: 'error',
                      summary: this.$t_('APP__MESSAGE__TITLE__DELETED__KO'),
                      detail: this.$t_('APP__MESSAGE__DELETED__KO'),
                      life: 3000
                    });
                  })
                  .finally(() => {
                    // this.callApi();
                    // this.genericCall();
                  })
            } else {
              this.$showToast({
                severity: 'error',
                summary: this.$t_('APP__MESSAGE__TITLE__DELETED__KO'),
                detail: this.$t_('APP__MESSAGE__DELETED__KO'),
                life: 3000
              });
              console.log("No api headers found")
            }
          }
      this.setObject({dialog: dialog, displayDialog: true})
    },

    createTicket(data,row){
      // console.log(data,row);
      this.setCurrentProps(data.props)
      //this.$pushRoute({'name':data.createTicket})
      this.$router.push({'name':data['action']})
    },

    normalAction(data,row){
      this.$store.commit('Routing_Route/setCurrentSyncServer', data)
      let navigation = this.$routingFromDatatable(data, row, this.routes);
      let name = data.follow.replaceAll('.', '') + '_' + data.text;
      let cmp = {
        'GenericVForm': GenericVForm,
        'GenericIndexPage': GenericIndexPage
      }

      let rou = {
        name: name,
        path: navigation[0],
        component: cmp[data.component],
        props: name.includes('details') ? {
          ...data.props,
          config: this.configs[data.follow],
          apiPath: data.to
        } : data.props
      };
      let index = this.$router.getRoutes().findIndex(e => e.name === name)
      let timeout = 500;
      if (index === -1) {
        this.$router.addRoute('backend', rou, () => {
        });
      } else {
        Object.keys(this.$router.getRoutes()[index].props.default).forEach(e => {
          if (data.props[e] !== undefined)
            this.$router.getRoutes()[index].props.default[e] = data.props[e]
        })
      }

      setTimeout(() => {
        this.$navigate(navigation[1])
      }, timeout)
    },


    clickAction(data, row) {

      if(this[data.action]!==undefined)
        this[data.action](data,row)
      else
        this.normalAction(data,row)


      // console.log(data,row,this[data.action]!==undefined)
      // debugger;
      // if (data.action !== 'deletehis') {
      //
      //
      // } else {
      //
      // }
    },

    discoverActions() {
      // this.apiUrl = this.apiPath;

      (this.datatable) ? this.dtable() : this.dt = this.dtFromStore;

    }
  },

  mounted() {
    this.discoverActions();
    setTimeout(() => {
      this.showpage = true
    }, 2300);


  },

  destroyed() {
    this.setDT(initialState.DATATABLE());
  }
}
</script>

<style scoped>

</style>