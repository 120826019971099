const MOK_TOASTS = (translateFn = (val) => (val)) =>({
    INITIAL: {
        position: 'top-center',
        severity: 'info', //(success, info, warn, error)
        summary: null,
        detail: '',
        closable: true,
        life: 3000,
        styleClass: null,
        contentStyleClass: null,
        // onConfirm: null,
        // onReject: null,
        group: 'tc'
    },
    SAVE: {
        success: {
            severity: 'success',
            summary: translateFn('APP__MESSAGE__TITLE__SAVED__OK'),
            detail: translateFn('APP__MESSAGE__SAVED__OK'),
            life: 3000,
        },
        error: {
            severity: 'error',
            summary: translateFn('APP__MESSAGE__TITLE__SAVED__KO'),
            detail: translateFn('APP__MESSAGE__SAVED__KO'),
            life: 3000,
        }
    },
    FETCH: {
        error: {
            severity: 'error',
            summary: translateFn('APP__MESSAGE__TITLE__FETCH__DATA__KO'),
            detail: translateFn('APP__MESSAGE__FETCH__DATA__KO'),
            life: 3000,
        },
    }
})

export default MOK_TOASTS;