

const Import_mutations = {
    setObject(state, data) {
        Object.keys(data).forEach(e => state[e] = data[e])
    },

}

export default Import_mutations;
