import {root} from "v-idle/.eslintrc";
import initialState from "@/constants/InitialState";
import {FilterMatchMode} from "primevue/api/";

const Global_actions = {

    deleteSession({commit, dispatch, state}, data) {
        const {dialog} = data;
        // const {group = "topbar"} = data;

        // let dialog = MOK_DIALOG.QUESTION;
        // dialog.text = "Sicuro di voler uscire?";
        // dialog.onYes = () => {
        //     commit("setObj", {
        //         toast: {
        //             severity: 'success',
        //             summary: this.$t_('APP__MESSAGE__TITLE__DELETE_SESSION__OK'),
        //             detail: this.$t_('APP__MESSAGE__DELETE_SESSION__OK'),
        //             life: 3000,
        //             group: group
        //         }
        //     });
        // };
        // dialog.onReject = () => {
        //     // $toast.add({
        //     commit("setObj", {
        //         toast: {
        //             severity: 'error',
        //             summary: this.$t_('APP__MESSAGE__TITLE__DELETE_SESSION__KO'),
        //             detail: this.$t_('APP__MESSAGE__DELETE_SESSION__KO'),
        //             life: 3000,
        //             group: group
        //         }
        //     });
        // }
        // dialog.onClose = () => {
        //     this.$destroyToast(group, initialState.GLOBAL.toast);
        // }
        commit("setObject", {dialog: dialog, displayDialog: true});
    },

    fetchPageData({commit, dispatch, state}, data) {
        let {apiPath, method, headers, withFilter = false, activeFilter = 0, params = null} = data;

        let url = params ? apiPath + (apiPath.includes(['?']) ? "&" : "?") + `bt=${params}` : apiPath;

        axios({
            url: url,
            method: method ?? "GET",
            headers: headers
        })
            .then(res => res.data)
            .then(res => {
                let topWidgets = [];
                let bottomWidgets = [];

                if (url.includes('details')) {
                    Object.keys(res).forEach(el=>{
                        res[el].position && res[el].position === "bottom" ? bottomWidgets.push(res[el]) : topWidgets.push(res[el])
                    })
                    // res.map(el => el.position && el.position === "bottom" ? bottomWidgets.push(el) : topWidgets.push(el));
                }

                commit("Widgets/setObject", {topWidgets: topWidgets, bottomWidgets: bottomWidgets}, {root: true});

                let dt = {};
                let response = res;
                if (withFilter === true) {
                    dt.actionFilters = res;
                    !params ? commit("Datatable/setObject", {activeFilter: 0}, {root: true}) : null;
                    response = res.length > 0 && res[activeFilter].items ? res[activeFilter].items : [];
                }

                if (response.headers) {
                    dt.columns = response.headers ? response.headers : (response.data && response.data.length > 0) ? Object.keys(response.data[0]).map(itm => ({
                        field: itm,
                        header: itm,
                        sortable: true,
                        color: COLORS.ICONA_DEALERS
                    })) : [];
                    response.data ? dt.items = response.data : null
                    response.paginate ? dt.paginate = response.paginate : null
                    response.actions ? dt.headerItems = response.actions : null

                    // let filter = {};
                    // Object.keys(response.filter).map(el => {
                    //     filter[el] = {
                    //         value: response.filter[el],
                    //         matchMode: FilterMatchMode.CONTAINS,
                    //     }
                    // });
                    // console.log(filter);
                    // dt.filter = filter

                }

                dt.showDatatable = Boolean(dt.columns && dt.columns.length > 0);

                commit("Datatable/setObject", (response.length > 0 || typeof response === 'object') ? dt : initialState.DATATABLE(), {root: true});

                // return res;
            })
            .catch((error) => {
                console.log(error)
                console.log(error.message, "---> fetchPageData")
            })
    }

}

export default Global_actions;
