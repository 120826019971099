<template>
  <div class="widget-content">
    <DTComponent :datatable="datatable" classes="dt_widget"/>
  </div>
</template>

<script>

export default {
  name: "WidgetDatatableSlot",
  props: ['datatable'],

  mounted() {

    // this.datatable.columns ? this.columns = this.datatable.columns : null
    // this.datatable.items ? this.items = this.datatable.items : null
    // this.datatable.showPaginator ? this.showPaginator = this.datatable.showPaginator : null
    // this.datatable.sortMode ? this.sortMode = this.datatable.sortMode : null
    // this.datatable.rows >= 0 ? this.rows = this.datatable.rows : null
    // Array.isArray(this.datatable.rowPerPage) ? this.rowPerPage = this.datatable.rowPerPage : null
    // this.datatable.currentPageTemplate ? this.currentPageTemplate = this.datatable.currentPageTemplate : null
  },

  data() {
    return {
    //   multiSortMeta: [],
    //   columns: [],
    //   items: [],
    //   showPaginator: true,
    //   sortMode: 'single',
    //   rows: 5,
    //   rowPerPage: [5, 10, 50, 100],
    //   currentPageTemplate: 'Showing {first} to {last} of {totalRecords}'
    }
  },
}
</script>

<style scoped>

</style>