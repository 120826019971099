import Vue from 'vue'
import VueRouter from 'vue-router'
import Backend from "@/pages/Backend.vue";
import Auth from "@/pages/auth/Auth.vue";
import AuthRoutes from "@/router/_AuthRoutes";
import BackendRoutes from "@/router/_BackendRoutes";
import store from "@/store";
import GenericIndexPage from "@/pages/generic/GenericIndexPage.vue";
import Tickets from "@/pages/Tickets.vue";

Vue.use(VueRouter)

const base_path = '/';
const httBQ = require('http-build-query');
// const base_path = process.env.BASE_URL ?? '/';

const routes = [
    {
        path: `${base_path}`,
        name: 'backend',
        component: Backend,
        meta: {
            requiresAuth: false
        },
        children: [
            {
                path: `${base_path}details-ticket/:id/view`,
                name: 'frontendticketdetails_view',
                component: GenericIndexPage,
                props: route => ({
                    apiPath: `/api/v1/details-ticket/${route.params.id}`,
                    base_model: route.params.id,
                    method: 'GET',
                    config: null,
                    ui: null,
                    title: 'Details',
                    titleIcon: null
                }),
                children: [],
            },
            {
                path: `${base_path}tickets/page/:id`,
                name: 'frontend.tickets.index_paginatore',
                component: Tickets,
                props: route => ({
                    apiPath: `/api/tickets?page=${route.params.id} ${store.state.Ticket.filterData ? '&' + httBQ(store.state.Ticket.filterData) : ''}`,
                    base_model: route.params.id,
                    method: 'GET',
                    config: null,
                    ui: null,
                    title: 'Details',
                    titleIcon: null,
                    page_id: route.params.id
                }),
                children: [],
            },


            ...BackendRoutes,
            // {
            //   path: ':pathMatch(.*)',
            //   name: 'not-found',
            //   component: Error,
            //   props: {
            //     errorCode: '404',
            //   }
            // }

        ],
    },
    {
        path: `${base_path}auth`,
        name: 'auth',
        component: Auth,
        children: [
            ...AuthRoutes
        ],
    },
    // {
    //   path: ':pathMatch(.*)',
    //   name: 'not-found',
    //   component: Error,
    //   props: {
    //     errorCode: '404',
    //   }
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        setTimeout(() => {
            let isLogged = store.getters["Main/getLogged"]
            // console.log(isLogged, to, next)
            if (isLogged) {
                next()
                return
            }
            next({name: 'login'})
        }, 500)
    } else {
        let i = store.getters["Main/getStatusApplication"];
        if (i.status === null) next()
        else if (!i.status && to.path !== i.run) {
            if (router.getMatchedComponents(to).length > 0) {
                router.app.$navigate(i.run)
            }
            // router.push(i.run.substring(1), () => ({}));
        } else {
            // console.log("next");
            next()
        }
    }
})

function returnHome(router, to) {

    setTimeout(() => {
        if (router.getMatchedComponents(to).length > 0) {
            router.app.$store.commit('Routing_Route/setCurrentRoute', to) +
            router.push('/' + to, () => {
            });
        } else {
            let splitted = to.fullPath.split('/')
            // to.fullPath='/'+splitted[1]

            return returnHome(router, {fullPath: '/' + splitted[1]})
        }
    }, 500)
}

router.afterEach((to, from) => {
    // returnHome(router, to)
    setTimeout(() => {
        if (router.getMatchedComponents(to).length > 0) {
            router.app.$store.commit('Routing_Route/setCurrentRoute', to)
        } else {
            //Fallback router
            let splitted = to.fullPath.split('/')
            // console.log(router.getRoutes().filter(el => el.path === '/'+ splitted[1]), 'Matching');
            router.push('/' + splitted[1], () => {
                // let resolved = router.resolve(splitted[1]);
                // console.log(resolved.route);
                // console.log(router.getRoutes());
                // console.log(router.getRoutes().filter(el => el.path === resolved.route.path));
            });
        }
    }, 500)


})

export default router