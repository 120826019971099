<template>
  <v-overlay class="page__loader" persistent v-model="value">
    <PProgressSpinner strokeWidth="5"/>
  </v-overlay>
</template>

<script>
export default {
  name: "LoaderComponent",
  props: ['value']
}
</script>

<style scoped>

</style>