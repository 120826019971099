

const Ticket_mutations = {
    setObject(state, data) {
        Object.keys(data).forEach(e => state[e] = data[e])
    },

    setUpdateAppendObject(state, data){
        var k = {
            min:  Math.min(...[].concat(state.tickets.map(e=>e.id))),
            max: Math.max(...[].concat(state.tickets.map(e=>e.id)))
        }
        // console.log(k)
        data.tickets.map(e=>{
            if(e.id < k.min){
                // console.log('append')
                state.tickets.push(e)
            }else if(e.id > k.max){

                state.tickets.push(e)
            }else{
                var index = state.tickets.findIndex(ee => ee.id === e.id)
                if(index === -1){
                    // console.log('qualcosa non funziona')
                    state.tickets.push(e)
                }else{
                    // console.log('update')
                    // console.log(index)
                    // state.tickets[index]={...e}
                    Object.assign(state.tickets[index],e)
                    // Object.keys(state.tickets[index]).forEach(e => data.tickets[e]!==undefined ?  state.tickets[index][e] = data.tickets[e] : null)
                }
            }
        })
        state.tickets.sort((a,b)=>b.id-a.id)

        if(data.paginator!==null)
            state.paginator = data.paginator;

    },

    appenIntoLocalH(state, data){
      data.map(e=>this.setUpdateAppendObject(state,e))
    },

    setHistory(state, data){
        null!==data.paginator?state.history[data.paginator.current_page] = data:null
    },

    setCurrentPage(state, data){
        state.currentPage = data
    },
    setPaginator(state,data){
        state.paginator = data;
    },

    setLocalHistoryElements(state, data){
        // console.log(data.localUpdateIndex)
        // console.log( state.tickets[data.localUpdateIndex])
        // console.log(data.tickets)

        let index = state.tickets.findIndex(e => e.id === data.tickets.id)

        Object.keys(state.tickets[index]).forEach(e => data.tickets[e]!==undefined ?  state.tickets[index][e] = data.tickets[e] : null)

        // state.tickets[index] = data.tickets

        /*        state.tickets = [
                    ...state.tickets.filter(e => e.id !== data.tickets.id ? e : data.tickets),
                    // data.tickets
                ]*/
        // state.tickets = local
        // state.history[state.currentPage].tickets[data.localUpdateIndex] = data.tickets
        // Object.keys(data.tickets).forEach(e => Object.assign({}, state.tickets[data.localUpdateIndex][e],data.tickets[e]))
        // Object.assign(state.tickets[data.localUpdateIndex],{...data.tickets})


    },

    setPropsCreateScanner(state,data){
        // console.log(state.currentProps, data)
        Object.assign(state.currentProps,data)

    },
    setModel(state,data){
        Object.assign(state.model,data)
    },
    setSchema(state,data){
        Object.assign(state.schema,data)
    },
    resetOnlyUiProps(state,data){
        // model:{},
        // schema:{},
        // external:false,
        //     currentProps:{}
        state.currentProps.model={}
        state.currentProps.schema={}
        state.currentProps.external=false
        state.currentProps={}
    }



}

export default Ticket_mutations;
