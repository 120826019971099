

import Routing_Route_getters from "./_getters";
import Routing_Route_mutations from "./_mutations";
import Routing_Route_actions from "./_actions";
import initialState from "@/constants/InitialState";

const Routing_Route = {

    namespaced: true,
    state: () => initialState.ROUTING_ROUTES(),

    getters:{
        ...Routing_Route_getters
    },
    mutations:{
        ...Routing_Route_mutations
    },
   actions:{
        ...Routing_Route_actions
    }

}

export default Routing_Route;
