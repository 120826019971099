const MOCK_TICKETS = (translateFn = (val) => (val)) => ({
    tickets: [
        {
            "id": 2044,
            "pdv": null,
            "warehouse": "asdasdasd",
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "In Magazzino",
            "state": {
                "id": 2,
                "name": "In Magazzino",
                "type": 2,
                "is_primary": 0,
                "ticket_group_id": 1,
                "description": "Stato finale. Scanner in magazzino",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2054,
                "serial_number": "d9wcWl",
                "name": "d9wcWl",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false
            },
            "closed": true,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": false,
                    "accetable": []
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_9715D983_C5FA_45BD_B2B1_885A9009FA78_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_9715D983_C5FA_45BD_B2B1_885A9009FA78_CLOSED",
                            "value": true,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_9715D983_C5FA_45BD_B2B1_885A9009FA78_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": true
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2044",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2044,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2044\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2044",
                    "props": {
                        "base_model": "ticket",
                        "find": 2044,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2044",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2044"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2045,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2055,
                "serial_number": "f6zTsk",
                "name": "f6zTsk",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_36A0DCDC_BC12_4C09_933B_07854A4F8C75_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_36A0DCDC_BC12_4C09_933B_07854A4F8C75_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_36A0DCDC_BC12_4C09_933B_07854A4F8C75_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2045",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2045,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2045\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2045",
                    "props": {
                        "base_model": "ticket",
                        "find": 2045,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2045\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2045",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2045",
                    "props": {
                        "base_model": "ticket",
                        "find": 2045,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2045",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2045"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2046,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2056,
                "serial_number": "FHHcws",
                "name": "FHHcws",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_A0D88A88_B9EA_404D_8827_5A89DDE78CEB_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_A0D88A88_B9EA_404D_8827_5A89DDE78CEB_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_A0D88A88_B9EA_404D_8827_5A89DDE78CEB_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2046",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2046,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2046\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2046",
                    "props": {
                        "base_model": "ticket",
                        "find": 2046,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2046\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2046",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2046",
                    "props": {
                        "base_model": "ticket",
                        "find": 2046,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2046",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2046"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2047,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2057,
                "serial_number": "kb8buE",
                "name": "kb8buE",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_67AD5E99_E64B_4410_8723_3CE9CE98D038_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_67AD5E99_E64B_4410_8723_3CE9CE98D038_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_67AD5E99_E64B_4410_8723_3CE9CE98D038_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2047",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2047,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2047\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2047",
                    "props": {
                        "base_model": "ticket",
                        "find": 2047,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2047\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2047",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2047",
                    "props": {
                        "base_model": "ticket",
                        "find": 2047,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2047",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2047"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2048,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2058,
                "serial_number": "vJg46C",
                "name": "vJg46C",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_6127DFD2_F01E_4B50_8145_E408E72B302F_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_6127DFD2_F01E_4B50_8145_E408E72B302F_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_6127DFD2_F01E_4B50_8145_E408E72B302F_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2048",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2048,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2048\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2048",
                    "props": {
                        "base_model": "ticket",
                        "find": 2048,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2048\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2048",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2048",
                    "props": {
                        "base_model": "ticket",
                        "find": 2048,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2048",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2048"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2049,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2059,
                "serial_number": "6Gw6XS",
                "name": "6Gw6XS",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_35E9B652_26E9_45CD_A38D_BC02255C3D44_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_35E9B652_26E9_45CD_A38D_BC02255C3D44_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_35E9B652_26E9_45CD_A38D_BC02255C3D44_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2049",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2049,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2049\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2049",
                    "props": {
                        "base_model": "ticket",
                        "find": 2049,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2049\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2049",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2049",
                    "props": {
                        "base_model": "ticket",
                        "find": 2049,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2049",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2049"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2050,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2060,
                "serial_number": "QLkG3N",
                "name": "QLkG3N",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_D1B037B4_95D2_4573_8118_5D438AC7415B_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_D1B037B4_95D2_4573_8118_5D438AC7415B_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_D1B037B4_95D2_4573_8118_5D438AC7415B_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2050",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2050,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2050\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2050",
                    "props": {
                        "base_model": "ticket",
                        "find": 2050,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2050\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2050",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2050",
                    "props": {
                        "base_model": "ticket",
                        "find": 2050,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2050",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2050"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2051,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2061,
                "serial_number": "fnBKjY",
                "name": "fnBKjY",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_3D6CBA01_44AB_4826_8A96_014BBCCE7476_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_3D6CBA01_44AB_4826_8A96_014BBCCE7476_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_3D6CBA01_44AB_4826_8A96_014BBCCE7476_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2051",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2051,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2051\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2051",
                    "props": {
                        "base_model": "ticket",
                        "find": 2051,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2051\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2051",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2051",
                    "props": {
                        "base_model": "ticket",
                        "find": 2051,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2051",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2051"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2052,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2062,
                "serial_number": "KVYVNb",
                "name": "KVYVNb",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_090413DF_E19E_460C_90A2_04863335AB76_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_090413DF_E19E_460C_90A2_04863335AB76_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_090413DF_E19E_460C_90A2_04863335AB76_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2052",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2052,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2052\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2052",
                    "props": {
                        "base_model": "ticket",
                        "find": 2052,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2052\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2052",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2052",
                    "props": {
                        "base_model": "ticket",
                        "find": 2052,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2052",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2052"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2053,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2063,
                "serial_number": "0dpeLD",
                "name": "0dpeLD",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_33D98922_AFE0_4C17_A75D_BF3805F0C08E_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_33D98922_AFE0_4C17_A75D_BF3805F0C08E_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_33D98922_AFE0_4C17_A75D_BF3805F0C08E_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2053",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2053,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2053\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2053",
                    "props": {
                        "base_model": "ticket",
                        "find": 2053,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2053\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2053",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2053",
                    "props": {
                        "base_model": "ticket",
                        "find": 2053,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2053",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2053"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2054,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2064,
                "serial_number": "79D0bE",
                "name": "79D0bE",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_D539172C_C61A_4C65_86C9_13E992BE5FFA_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_D539172C_C61A_4C65_86C9_13E992BE5FFA_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_D539172C_C61A_4C65_86C9_13E992BE5FFA_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2054",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2054,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2054\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2054",
                    "props": {
                        "base_model": "ticket",
                        "find": 2054,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2054\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2054",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2054",
                    "props": {
                        "base_model": "ticket",
                        "find": 2054,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2054",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2054"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2055,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2065,
                "serial_number": "l3Ax5O",
                "name": "l3Ax5O",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_9461668C_10C3_4198_A969_9B9547B35414_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_9461668C_10C3_4198_A969_9B9547B35414_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_9461668C_10C3_4198_A969_9B9547B35414_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2055",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2055,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2055\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2055",
                    "props": {
                        "base_model": "ticket",
                        "find": 2055,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2055\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2055",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2055",
                    "props": {
                        "base_model": "ticket",
                        "find": 2055,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2055",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2055"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2056,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2066,
                "serial_number": "Bc803N",
                "name": "Bc803N",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_6E1D01E6_4F0C_48A5_AD23_C8D999DBEC87_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_6E1D01E6_4F0C_48A5_AD23_C8D999DBEC87_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_6E1D01E6_4F0C_48A5_AD23_C8D999DBEC87_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2056",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2056,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2056\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2056",
                    "props": {
                        "base_model": "ticket",
                        "find": 2056,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2056\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2056",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2056",
                    "props": {
                        "base_model": "ticket",
                        "find": 2056,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2056",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2056"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2057,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2067,
                "serial_number": "AnESDP",
                "name": "AnESDP",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false,
                "warehouse": {
                    "id": 20,
                    "type": "TYPE_1",
                    "province": {
                        "id": 1,
                        "name": "Torino "
                    },
                    "municipality": {
                        "id": 1001,
                        "name": "Agli\u00e8"
                    },
                    "address": "asdasdad"
                }
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_7A96FE8C_9CF5_4DAF_A22E_A2037E781AB0_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_7A96FE8C_9CF5_4DAF_A22E_A2037E781AB0_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_7A96FE8C_9CF5_4DAF_A22E_A2037E781AB0_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2057",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2057,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2057\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2057",
                    "props": {
                        "base_model": "ticket",
                        "find": 2057,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2057\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2057",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2057",
                    "props": {
                        "base_model": "ticket",
                        "find": 2057,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2057",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2057"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        },
        {
            "id": 2058,
            "pdv": null,
            "warehouse": null,
            "ticket_group": "CREAZIONE MAGAZZINO",
            "stato": "Creazione Scanner",
            "state": {
                "id": 1,
                "name": "Creazione Scanner",
                "type": 1,
                "is_primary": 1,
                "ticket_group_id": 1,
                "description": "Stato iniziale. Scanner creato",
                "meta": null,
                "created_at": "2022-10-14T15:56:57.000000Z",
                "updated_at": "2022-10-14T15:56:57.000000Z",
                "deleted_at": null
            },
            "scanner": {
                "id": 2068,
                "serial_number": "jO7J70",
                "name": "jO7J70",
                "type": "USB - HD",
                "brand": "Reiss Romoli",
                "model": "Model 001",
                "state": "Creazione Scanner",
                "movable": false
            },
            "closed": false,
            "created_at": "13\/03\/2023 10:31",
            "owner": "utente2",
            "actions": {
                "state": {
                    "can_next": true,
                    "accetable": [
                        2
                    ]
                },
                "progress": {
                    "alert": false,
                    "notify": null,
                    "color": "green",
                    "show": false
                },
                "time_close": {
                    "value": 9,
                    "format": "IN DAYS"
                }
            },
            "vForms": {
                "ticket": {
                    "schema": {
                        "closed": {
                            "disable": false,
                            "component": "",
                            "type": "switch",
                            "fn": null,
                            "default": "",
                            "label": "LABEL_BEA0B563_104F_4F01_835F_A7600379A6A5_CLOSED",
                            "name": "closed",
                            "placeholder": "PLACEHOLDER_BEA0B563_104F_4F01_835F_A7600379A6A5_CLOSED",
                            "value": false,
                            "data": [],
                            "selected": [],
                            "require": true,
                            "rules": [],
                            "rules_fn": [
                                [
                                    "required",
                                    "MESSAGE_IS_REQUIRED_BEA0B563_104F_4F01_835F_A7600379A6A5_CLOSED"
                                ]
                            ],
                            "multiple": false,
                            "hint": "",
                            "tooltip": false,
                            "sort": 1,
                            "flex": {
                                "xs": 12,
                                "sm": 12,
                                "md": 12,
                                "lg": 12,
                                "pa": 1
                            },
                            "readonly": false,
                            "chips": false,
                            "mask": "",
                            "css": "pa-1",
                            "class": "pl-3 pr-3",
                            "color": "",
                            "backgroundColor": "",
                            "order": 1,
                            "hidden": false,
                            "outlined": true,
                            "shaped": false,
                            "filled": false,
                            "rounded": false,
                            "dense": true
                        }
                    },
                    "values": {
                        "closed": false
                    }
                }
            },
            "links": {
                "show": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2058",
                    "follow": "frontend.tickets.show",
                    "props": {
                        "base_model": "ticket",
                        "find": 2058,
                        "type": "show",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2058\/show"
                    },
                    "component": "GenericVForm",
                    "text": "show",
                    "action": "showThis",
                    "click": "showThis",
                    "class": "",
                    "icon": ""
                },
                "edit": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2058",
                    "props": {
                        "base_model": "ticket",
                        "find": 2058,
                        "type": "edit",
                        "saveble": true,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/ticket\/2058\/edit"
                    },
                    "follow": "frontend.tickets.show",
                    "component": "GenericVForm",
                    "text": "edit",
                    "action": "editThis",
                    "click": "editThis",
                    "class": "",
                    "icon": ""
                },
                "delete": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/tickets\/2058",
                    "flw": null,
                    "follow": "frontend.tickets.destroy",
                    "text": "delete",
                    "component": "GenericIndex",
                    "action": "deletehis",
                    "click": "deleteThis",
                    "class": "",
                    "icon": ""
                },
                "details": {
                    "to": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2058",
                    "props": {
                        "base_model": "ticket",
                        "find": 2058,
                        "type": "details",
                        "saveble": false,
                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2058",
                        "apiPath": "https:\/\/tosca.adel.cloud\/api\/v1\/details-ticket\/2058"
                    },
                    "component": "GenericIndexPage",
                    "flw": null,
                    "follow": "frontend.ticket.details",
                    "text": "view",
                    "action": "pijat queee",
                    "click": "",
                    "class": "",
                    "icon": "mdi-info"
                }
            }
        }
    ],
    paginator: {
        "current_page": 1,
        "first_page_url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=1",
        "from": 1,
        "last_page": 134,
        "last_page_url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=134",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=2",
                "label": "2",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=3",
                "label": "3",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=4",
                "label": "4",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=5",
                "label": "5",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=6",
                "label": "6",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=7",
                "label": "7",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=8",
                "label": "8",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=9",
                "label": "9",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=10",
                "label": "10",
                "active": false
            },
            {
                "url": null,
                "label": "...",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=133",
                "label": "133",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=134",
                "label": "134",
                "active": false
            },
            {
                "url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=2",
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": "https:\/\/tosca.adel.cloud\/api\/tickets?page=2",
        "path": "https:\/\/tosca.adel.cloud\/api\/tickets",
        "per_page": 15,
        "prev_page_url": null,
        "to": 15,
        "total": 2000
    }
})

export default MOCK_TICKETS;