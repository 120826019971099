<template>
  <div v-if="parentProps">
    <template v-if="parentProps.routeName ==='frontend.tickets.index'">
      <Tickets
          :title="parentProps.title"
          :titleIcon="parentProps.titleIcon"
          :routeName="parentProps.routeName"
          :apiPath="parentProps.apiPath"
          :method="parentProps.method"
          :config="parentProps.config"
          :ui="parentProps.ui"
          :page_id="parentProps.page_id"
      />
    </template>

    <template v-else-if="parentProps.routeName === 'pdvs.import'">
      <ImportPage
          :title="parentProps.title"
          :titleIcon="parentProps.titleIcon"
          :routeName="parentProps.routeName"
          :apiPath="parentProps.apiPath"
          :apiPathSave="parentProps.apiPathSave"
          :method="parentProps.method"
          :config="parentProps.config"
          :ui="parentProps.ui"
          :page_id="parentProps.page_id"
      />
    </template>
    <template v-else>
      <ErrorPage error-code="404"/>
    </template>
  </div>
</template>

<script>

export default {
  name: "IncludedPage",
  data: () => ({
    parentProps: null
  }),

  mounted() {
    this.parentProps = this.$checkObjExist(this, "$route.matched.1.props.default") ? this.$route.matched[1].props.default : null;
  }


}
</script>

<style scoped>

</style>