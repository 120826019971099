import MOK_DIALOG from "@/constants/mock_data/MockDialog";
import MOCK_PANELS from "@/constants/mock_data/MockPanels";
import MOCK_TABS from "@/constants/mock_data/MockTabs";
import MOK_TOASTS from "@/constants/mock_data/MockToast";
import {MOCK_VFORM, MOCK_VFORM_UPLOAD} from "@/constants/mock_data/MockVForm";
import MOCK_WIDGETS from "@/constants/mock_data/MockWidgets";
import MOCK_TICKETS from "@/constants/mock_data/MockTickets";

const MOCK_DATA = {
    dialogs: MOK_DIALOG,
    panels: MOCK_PANELS,
    tabs: MOCK_TABS,
    toasts: MOK_TOASTS,
    vform: MOCK_VFORM,
    vform_upload: MOCK_VFORM_UPLOAD,
    widgets: MOCK_WIDGETS,
    tickets: MOCK_TICKETS
}

export default MOCK_DATA;