import Login from "@/pages/auth/Login.vue";
import LoginVerify from "@/pages/auth/LoginVerify.vue";
import ForgotPassword from "@/pages/auth/ForgotPassword.vue";
import ResetPassword from "@/pages/auth/ResetPassword.vue";

const base_path = '/auth/';
// const base_path = (process.env.BASE_URL ?? '/') + 'auth/';

const AuthRoutes = [
    {
        path: `${base_path}login`,
        name: 'login',
        component: Login,
    },
    {
        path: `${base_path}reset-password`,
        name: 'reset-password',
        component: ForgotPassword,
    },
    {
        path: `${base_path}verify`,
        name: 'verify',
        component: LoginVerify,
    },
    {
        path: `${base_path}reset-link/:tkn`,
        name: 'reset-link',
        component: ResetPassword,
        props: route=>({
            token: route.params.tkn
        })
    }
]

export default AuthRoutes;