<template>
  <PCard v-if="item" :class="['widget__container', 'elevate', item.alignTitle ? 'align-' + item.alignTitle : '']" :type="item.type">
    <template #title>
      <slot name="widget_title_slot">
        <i :class="item.titleIcon"></i> {{ item.title ?? "" }}
      </slot>
    </template>
    <template #content>
      <slot name="widget_content_slot" v-bind:item="item">
        <div class="widget-icon">
          <i v-if="item.icon" :class="item.icon" :style="{color: item.colors && item.colors.length > 0 ? item.colors[0] : 'initial'}"></i>
          <!--            <img src="../../assets/images/no-image.jpg"/>-->
        </div>
        <div class="widget-content">
          <p class="widget-value"
             :style="{color: item.colors && item.colors.length > 0 ? item.colors[item.colors.length - 1] : 'initial'}">{{ item.value }}</p>
        </div>
      </slot>
    </template>
  </PCard>
</template>

<script>
export default {
  name: "WidgetContainer",
  props: ['itemData'],
  data: () => ({
    item: null,
  }),

  mounted() {
    this.itemData ? this.item = this.itemData : null
  }

}
</script>

<style scoped lang="scss">

.p-card-title {
  i {
    font-weight: 900;
    font-size: 1em;
  }
}

</style>