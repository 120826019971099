import Home from "@/pages/Home.vue";
import GenericVForm from "@/pages/generic/GenericVForm.vue";
import Test from "@/pages/test/Test.vue";
import CreateTicket from "@/pages/CreateTicket.vue";
import ZEvents from "@/pages/ZEvents.vue";
import ExportFiltering from "@/pages/exporter/ExportFiltering.vue";
import FaqsElements from "@/pages/FaqsElements.vue";

const base_path = '/';

const BackendRoutes = [
    {
        path: `${base_path}`,
        name: 'home',
        component: Home,
        children: [],
    },
    // {
    //     path: `${base_path}test`,
    //     name: 'test',
    //     component: Test,
    //     children: [],
    // },
    {
        path: `${base_path}:base_model/global-form`,
        name: 'globalForm',
        component: GenericVForm,
        children:  [],
        props: route=>({
            base_model: route.params.base_model
        })
    },
    {
        path:`${base_path}ticket/create-new`,
        name: 'createTicket',
        component: CreateTicket
    },
    {
        path:`${base_path}ticket/:id/edit`,
        name: 'updateTicket',
        component: CreateTicket,
        props:route=>({
            isNew: false,
            id: route.params.id,
        })
    },
    {
        path: `${base_path}events/:id/show`,
        redirect: `${base_path}custom-event/:id/edit`
    },
    {
        path: `${base_path}events/:id/edit`,
        redirect: `${base_path}custom-event/:id/edit`
    },
    {
        path: `${base_path}custom-event/:id/edit`,
        name: 'customEventsVirtual',
        component: ZEvents,
        props:route=>({
            id: route.params.id
        })
    },
    {
        path: `${base_path}merged-epxport`,
        name: 'customFilteringExport',
        component: ExportFiltering,
    },
    {
        path: `${base_path}faqs`,
        name: 'showFaqs',
        component:FaqsElements
    }
]

export default BackendRoutes