import axios from "axios";

const minLen = l => v => (v && v.length >= l) || `min. ${l} Characters`;
const maxLen = l => v => (v && v.length <= l) || `max. ${l} Characters`;
const required = msg => v => !!v || msg;
const validEmail = msg => v => /.+@.+\..+/.test(v) || msg;

const appTknName = '_app_option';

const appTknObj = {
    name: (Math.random() + 1).toString(36).substring(2),
    author: (Math.random() + 1).toString(36).substring(2),
    '_app_tkn': ''
};
export default {


    methods: {

        $getToken() {
            return document.head.querySelector('meta[name="csrf-token"]').content;
        },


        $minlen(e, l) {
            return e.length >= l
        },

        $validaEmail(email) {
            return validEmail(email);
        },
        $intersezione(ar1, ar2) {
            return ar1.filter(x => ar2.includes(x));
        },
        $differenza(ar1, ar2) {
            return ar1.filter(x => !ar2.includes(x));
        },

        $clearAllTag(data) {
            return data.replace(/(<([^>]+)>)/ig, "");
        },
        $createSubString(string, start, len) {
            return string.substring(start, len);
        },
        $createMinText(text) {
            let str = this.$clearAllTag(text);
            return this.$createSubString(str, 0, 100);
        },

        $getRulesInput(data) {
            for (var key in data) {
                if (null !== data[key] && data[key].rules_fn !== undefined && data[key].rules_fn !== '') {
                    for (var i in data[key].rules_fn) {
                        var rules = [];

                        switch (data[key].rules_fn[i][0]) {
                            case 'required':
                                data[key].rules[i] = required(data[key].rules_fn[i][1]);
                                break;
                            case 'minLen':
                                data[key].rules[i] = minLen(data[key].rules_fn[i][1]);
                                break;
                            case 'maxLen':
                                data[key].rules[i] = maxLen(data[key].rules_fn[i][1]);
                                break;
                            case 'validEmail':
                                data[key].rules[i] = validEmail(data[key].rules_fn[i][1]);
                                break;
                        }
                    }
                }
            }
            return data;
        },

        $navigate(path) {
            this.$router.push(path, () => {
            })
        },
        $navigateName(path) {
            this.$router.push({name: path}, () => {
            })
        },
        $navigateTimeout(path, number) {
            number = undefined === number ? 1200 : number
            setTimeout(() => {
                this.$navigateName(path)
            }, number)
        },
        $getNamespace(name) {
            let ns = this.$router.match({name: name}).name.split('-')[0];
            return ns.charAt(0).toUpperCase() + ns.slice(1)
        },
        $replacerBind(str, data) {
            Object.keys(data).forEach(el => {
                str = str.replace(`:${el}`, data[el]);
            })
            return str;
        },
        $pushRoute(path, data) {
            Object.keys(data).forEach(el => {
                path.base_url = path.base_url.replace(`:${el}`, data[el]);
            })
            this.$navigate(path.base_url)
        },
        $t_(item) {
            let lang = JSON.parse(localStorage.getItem('lang'));
            // let lang = this.$store.state.Main.language;
            let el = lang && lang[item]
                ? lang[item]
                : item;
            // console.log('not found ----->', item)
            if (el === 'notFound') {
                //this.$sendTranslate(item)
                setTimeout(() => {
                    // return this.$t_(item)
                    return item
                }, 1200)
            }

            return el;
        },

        $sendTranslate(item, api_route) {


            if (!this.$store.state.Layout.newLangSended.includes(item)) {
                axios({
                    method: 'post',
                    url: api_route,
                    data: {
                        el: item
                    }
                }).then(res => {
                    debugger
                    this.$store.state.Layout.language = res.data.language;

                })
            }
            this.$store.state.Layout.newLangSended.push(item);

        },
        $getSchema(name) {
            const sch = this.$store.state.Route.main_routes[name]
            console.log(sch)
            return axios.get(sch.schema, {headers: sch.headers})
            // .then(res => (res.data))
            // .catch(err => console.error(err.message.data))
        },
        $getUri(obj) {
            return axios.get(obj.uri, {headers: obj.headers})
        },
        $setObjectState(namespace, data) {
            // console.log(this.$store.state[namespace]);
            Object.keys(data).forEach(e => this.$store.state[namespace][e] = data[e])
        },
        $setLoader(value) {
            this.$store.state.Layout.showLoader = value
        },
        $getLoader() {
            return this.$store.state.Layout.showLoader
        },
        $makeRandomString(length, withNumber = false) {
            let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let result = ''

            withNumber ? chars += '0123456789' : null

            const charsLength = chars.length;
            for (let i = 0; i < length; i++) {
                result += chars.charAt(Math.floor(Math.random() * charsLength));
            }

            return result;
        },

        $validateMasckedCode(event) {
            (event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 9) ? event.currentTarget.select() :
                (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ? event.target?.nextSibling?.focus() : null;
        },

        $routingFromDatatable(action, row, routes) {

            let url = action.follow
            let sub_url = action.follow
            if (routes[action.follow] !== undefined) {
                url = routes[action.follow].options.base
                sub_url = routes[action.follow].options.base
                for (let el in routes[action.follow].options.binds) {
                    sub_url = url.replace('{' + routes[action.follow].options.binds[el] + '}', row[routes[action.follow].options.index[el]])
                    url = url.replace('{' + routes[action.follow].options.binds[el] + '}', ':' + routes[action.follow].options.binds[el])
                }
                url += `/${action.text}`
                sub_url += `/${action.text}`
            }
            return [url, sub_url]
        },

        $getMyhhasl() {
            return 'TipAbsoluteAppointHeartBrainPreventRestaurantCruelDarken5'
        },

        $getMyhhasl2() {
            return 'This1sTh3ToScA4ppF0rCl13ntWinD2023'
        },

        $getEncoded(str, hashKey = this.$getMyhhasl()) {
            return this.$CryptoJS.AES.encrypt(str, hashKey).toString()
            // return this.$CryptoJS.AES.encrypt(str, this.$getMyhhasl()).toString()
        },
        $getDecoded(str, hashKey = this.$getMyhhasl()) {
            return this.$CryptoJS.AES.decrypt(str, hashKey).toString(this.$CryptoJS.enc.Utf8)
        },

        $setTnk(p, tknName = appTknName) {
            // sessionStorage.setItem(appTknName, this.$getEncoded(p))
            appTknObj._app_tkn = this.$getEncoded(p, this.$getMyhhasl2());
            // sessionStorage.setItem(tknName, this.$getEncoded(JSON.stringify(appTknObj)));
            this.$setLocalStorageTTL([{name: tknName, value: this.$getEncoded(JSON.stringify(appTknObj)), ttl: 43200}])
        },
        $getTnk(tknName = appTknName) {
            let test = this.$getLocalStorageTTL(tknName) ? JSON.parse(this.$getDecoded(this.$getLocalStorageTTL(tknName))) : null;
            // let test = sessionStorage.getItem(tknName) ? JSON.parse(this.$getDecoded(sessionStorage.getItem(tknName))) : null;
            // console.log(test);
            // console.log(this.$getDecoded(test._app_tkn));
            return (test && test._app_tkn) ? this.$getDecoded(test._app_tkn, this.$getMyhhasl2()) : null;
            // return sessionStorage.getItem(appTknName) ? this.$getDecoded(sessionStorage.getItem(appTknName)) : null;
        },

        $getheaders(){
            return {
                authorization: `Bearer ${this.$getTnk()}`,
                accept: 'application/json',
                'content-type': 'application/json'
            }
        },

        $makeMapConfirmationActionButtons(confirm, data){
            let mapped = {
                // navigate: (val) => this.$navigate(data.onYes.navigate)
                navigate: (val) => this.$navigate(val)
            }
            // console.log(confirm[data])
            if(typeof(confirm[data]) === 'object'){
                let k = Object.keys(confirm[data])[0]
                if(mapped[k]!==undefined)
                    confirm[data] = mapped[k](confirm[data][k])
            }

        }
    }
};

export function replaceBinfing(str, data) {
    Object.keys(data).forEach(el => {
        str = str.replace(`:${el}`, data[el]);
    })
    return str;
}
