import PrimeVue from 'primevue/config';
import Toolbar from 'primevue/toolbar';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import PanelMenu from 'primevue/panelmenu';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import Card from 'primevue/card';
import ProgressBar from 'primevue/progressbar';
import TieredMenu from 'primevue/tieredmenu';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Steps from 'primevue/steps';
import Timeline from 'primevue/timeline';
import Badge from 'primevue/badge';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';
import Password from 'primevue/password';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputMask from 'primevue/inputmask';
import Divider from 'primevue/divider';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import Paginator from "primevue/paginator";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

import ToastService from 'primevue/toastservice'
import {FilterMatchMode} from 'primevue/api'

import StyleClass from 'primevue/styleclass';
import BadgeDirective from 'primevue/badgedirective';
import Ripple from 'primevue/ripple';

const InitPrimevue = (app) => {
    app.use(PrimeVue, {
        filterMatchModeOptions: {
            text: [
                FilterMatchMode.STARTS_WITH,
                FilterMatchMode.CONTAINS,
                FilterMatchMode.NOT_CONTAINS,
                FilterMatchMode.ENDS_WITH,
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS
            ],
            numeric: [
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.LESS_THAN,
                FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                FilterMatchMode.GREATER_THAN,
                FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            ],
            date: [
                FilterMatchMode.DATE_IS,
                FilterMatchMode.DATE_IS_NOT,
                FilterMatchMode.DATE_BEFORE,
                FilterMatchMode.DATE_AFTER
            ]
        }
    })
    app.use(ToastService)

    app.component( "PToolbar", Toolbar)
    app.component( "PSidebar", Sidebar)
    app.component( "PButton", Button)
    app.component( "PPanelMenu", PanelMenu)
    app.component( "PMenubar", Menubar)
    app.component( "PMenu", Menu)
    app.component( "PCard", Card)
    app.component( "PProgressBar", ProgressBar)
    app.component( "PTieredMenu", TieredMenu)
    app.component( "PAvatar", Avatar)
    app.component( "PAvatarGroup", AvatarGroup)
    app.component( "PSteps", Steps)
    app.component( "PTimeline", Timeline)
    app.component( "PBadge", Badge)
    app.component( "POverlayPanel", OverlayPanel)
    app.component( "PInputText", InputText)
    app.component( "PTextArea", Textarea)
    app.component( "PProgressSpinner", ProgressSpinner)
    app.component( "PPassword", Password)
    app.component( "PDataTable", DataTable)
    app.component( "PColumn", Column)
    app.component( "PInputMask", InputMask)
    app.component( "PDivider", Divider)
    app.component( "PToast", Toast)
    app.component( "PDialog", Dialog)
    app.component( "PFileUpload", FileUpload)
    app.component( "PPaginator", Paginator)
    app.component( "PAccordion", Accordion)
    app.component( "PAccordionTab", AccordionTab)

    app.directive( "styleclass", StyleClass)
    app.directive('badge', BadgeDirective);
    app.directive('ripple', Ripple);


}

export default InitPrimevue;