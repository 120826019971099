<template>
    <div :class="['page__footer', $store.state.Main.sidebar.sidebar_opened ? 'main__footer-sidebar_opened' : '']">
        <div class="footer__copyright">
            <span>{{ new Date().getFullYear() }}</span>
            <i class="far fa-copyright"></i>
            <span>Reiss Romoli</span>
        </div>
        <div class="footer__copyright version">
            <span>{{ $t_('APP__COPYRIGHT__VERSION') }}</span>
        </div>
        <template v-if="showPending">
            <PButton type="button" label="In attesa" @click="toggle" class="notifiche"/>
            <PBadge value="6" size="xlarge" severity="success"></PBadge>
            <POverlayPanel ref="footer_overlay" appendTo="body" :dismissable="true" :showCloseIcon="true">
                <img src="no-image.jpg" alt="No Image">
            </POverlayPanel>
        </template>
    </div>
</template>

<script>
export default {
    name: "FooterComponent",
    data: () => ({
        showPending: false
    }),

    methods: {
        toggle(event) {
            // console.log(this.$refs)
            this.$refs.footer_overlay.toggle(event);
        }
    }
}
</script>

<style scoped>

</style>