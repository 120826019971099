
const Account_getters = {
    getAccount(state) {
        return state
    },

    getAccountMenuItems(state) {
        return state.menuItems
    }
};

export default Account_getters;
