

import Account_getters from "./_getters";
import Account_mutations from "./_mutations";
import Account_actions from "./_actions";
import INITIAL_STATE from "@/constants/InitialState";

const Account = {

    namespaced: true,
    state: () => INITIAL_STATE.ACCOUNT(),

    getters:{
        ...Account_getters
    },
    mutations:{
        ...Account_mutations
    },
   actions:{
        ...Account_actions
    }

}

export default Account;
