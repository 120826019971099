<template>
  <v-list-item-content v-model="item" class="sidebar__item">
    <v-list-item v-text="item.label" v-if="item.url && item.external" :href="item.url"
                 :target="item.target"></v-list-item>
    <v-list-item v-text="item.label" v-else-if="item.to" :to="item.to"></v-list-item>
    <v-list-item v-text="item.label" v-else></v-list-item>
  </v-list-item-content>
</template>
<!--
 * Created by PhpStorm.
 * User: Dario Pietroleonardo
 * Date: 08/08/2022
 * Time: 10:01
 -->
<script>
export default {
  name: "SidebarItemComponent",
  props: ['item'],
  mounted() {
    console.log(this.item)
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/primevue/variables";

.sidebar__item {

  a{
    padding-left: 20px;
    padding-right: 20px;
  }

  .v-list-item {
    min-height: 54px;


    &:hover {
      background: rgba(233, 188, 165, 0.26) !important;
      border-radius: 25px !important;
      width: 100%;
    }

    color: #FFF !important;

    * {
      text-align: center;
    }

    &:before {
      border-radius: 25px !important;
    }

  }
}
</style>