<template>
  <div class="generic__page">
    <PCard :key="`tk__card_list_${idx}`" class="elevate card__list" v-for="(t,idx) in tickets" :closed="t.closed">
      <template #title>
        #{{ t.id }} - {{ $t_('TK__CARD__TITLE__SCANNER') }} - {{ t.scanner.serial_number }} {{
          t.pdv ? `- ${t.pdx}` : ''
        }}
      </template>
      <template #content>
        <div class="card__list__content">
          <div class="card__list__in_column">
            <p><span>{{ $t_('TK__CARD__LABEL__GROUP') }}:</span> {{ t.ticket_group }}</p>
            <p class="wrapped"><span>{{ $t_('TK__CARD__LABEL__DESCRIPTION') }}:</span> {{ t.state.description }}</p>
          </div>
          <div>
          </div>
          <div v-if="t.vForms.ticket.schema && t.vForms.ticket.values" class="card__list__vform">
            <v-form-base :schema="t.vForms.ticket.schema" :model="t.vForms.ticket.values"/>
          </div>
          <div v-if="t.links" class="card__list__actions">
            <PButton v-for="(act,ind) in t.links"
                     :key="`tk_card_list_${idx}_btn_${ind}`"
                     :icon="`${act.icon !== '' ? act.icon : Object.keys(primeIcons).includes(act.text) ? primeIcons[act.text] : 'pi pi-info'}`"
                     class="p-button-rounded"
                     :title="$t_(act.text).toUpper"
                     @click="clickAction(act)"/>
          </div>
        </div>
      </template>
    </PCard>
    <!--    <v-row v-if="actions" key="test_action_btn" style="margin-bottom: 1em;gap: 0.5em; justify-content: center;">-->
    <!--      <PButton label="File Upload" icon="fa-solid fa-upload" @click="clickUpload"/>-->
    <!--    </v-row>-->
    <!--    <PCard class="elevate" v-if="expand">-->
    <!--      <template #content>-->
    <!--        <ExpanderComponent :items="panels.items" :activeIndex="panels.activeIndex" :multiple="panels.multiple" />-->
    <!--      </template>-->
    <!--    </PCard>-->
    <!--    <PCard v-if="widgets.length > 0" class="widgets_container">-->
    <!--      <template #content>-->
    <!--        <v-row v-for="(bw, idx) in widgets" :key="`bottom_w_${bw.name}_${idx}`">-->
    <!--          <WidgetComponent v-if="idx <= 3" v-for="(itm, index) in bw.items"-->
    <!--                           :key="`bottom_w_${itm.name}_${idx}_${index}`" :item="itm"/>-->
    <!--        </v-row>-->
    <!--      </template>-->
    <!--    </PCard>-->
    <!--    <DTPaginatorComponent v-if="showPaginator" :paginate="paginator" @selectPage="selectPage"/>-->
  </div>
  <!--  <v-container>-->
  <!--    <v-row v-if="showWidgets && idx === 3" v-for="(w, idx) in widgets" :key="w.name + '_' + idx">-->
  <!--      <WidgetComponent v-for="(itm, index) in w.items" :key="`${itm.name}_${idx}_${index}`" :item="itm"/>-->
  <!--    </v-row>-->
  <!--  </v-container>-->
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import primeIcons from "@/constants/PrimeIcons";
import MOCK_DATA from "@/constants/mock_data";

export default {
  name: "Test",

  data: () => ({
    widgets: MOCK_DATA.widgets,
    panels: MOCK_DATA.panels,
    tickets: {},
    paginator: {},
    showWidgets: true,
    expand: true,
    actions: false
  }),

  computed: {
    primeIcons() {
      return primeIcons
    },

    showPaginator() {
      return this.paginator && this.paginator.per_page <= this.paginator.total;
    }
  },

  methods: {
    ...mapMutations({
      setGlobalObject: "Global/setObject",
      setTicketsObj: "Ticket/setObject"
    }),

    clickUpload() {
      this.setGlobalObject({dialog: MOCK_DATA.dialogs().UPLOAD, displayDialog: true})
    },

    selectPage(value) {
      alert("Ciao");
    },

    clickAction(action) {
      console.log(action, "---> Action");
      alert("Cliccato");
    }

  },

  mounted() {
    // this.setGlobalObject({dialog: MOK_DIALOG.UPLOAD, displayDialog: true})
    this.setGlobalObject({wip: true});
    this.tickets = MOCK_DATA.tickets(this.$t_).tickets;
    this.paginator = MOCK_DATA.tickets(this.$t_).paginator;
    // this.setTicketsObj(this.tickets);
  }
}
</script>

<style lang="scss">

.card__list {
  padding: 0 !important;
  overflow-x: auto;

  .v-label {
    color: rgba(0, 0, 0, .86) !important;
  }

  &[closed = "true"] {
    background-color: #eb1111;

    .card__list__content {
      &, .v-label {
        color: #fff0f0 !important;
      }
    }
  }

  .card__list__content {
    display: flex;
    align-items: baseline;
    padding: 0 1em;
    gap: 1em;
    white-space: nowrap;

    .card__list__in_column {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        &.wrapped {
          white-space: break-spaces;
        }
      }
    }

    .card__list__actions {
      display: flex;
      gap: .2em;

      .p-button {
        justify-content: center;
        max-height: 2em;
        max-width: 2em;
      }
    }

    //flex-wrap: wrap;

    //.card__list__vform {
    //  .v-input {
    //    padding: 0 !important;
    //    margin: 0;
    //  }
    //}

    span {
      font-weight: 800;
    }
  }
}

</style>