import mixin from "@/plugins/Dacho/mixin";

/**
 * Options:
 * - toastMutation: (the namespaced mutation name for set Toast Object)
 * - appTknName: (localStorage key for app token - default: '_app_option')
 */
export default {
    install: (app, options) => {

        // app.component();

        // app.directive();

        app.mixin(mixin(options));

        // app.provide();
    }
}