<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="input"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="value"
          :label="obj.schema.label"
          :readonly="obj.schema.readonly"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
        v-if="!obj.schema.readonly"
        v-model="input"

        @click:minute="$refs.menu.save(input)"
        width="256"
    ></v-time-picker>

  </v-menu>
</template>

<script>
export default {
  name: "v-custom-time",
  props: ['type', 'value', 'obj'],
  data(){
    return{
      menu:false,
      current:null
    }
  },
  watch:{
    current:{
      handler(){

      }
    },
    input:{
      handler(){

        this.current=this.input
      }
    }
  },
  computed:{
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {

  },
  methods:{
    saveData(){
      this.$emit('input', this.input)
      this.menu=false
      console.log(this.input)
    }
  }
}
</script>

<style scoped>

</style>