<template>
  <form class="upload__component" ref="uploadForm" enctype="multipart/form-data" novalidate>
    <div class="dropbox">
      <div class="input-file__container">
        <input ref="file_input" type="file" :multiple="multiple ?? false" :name="uploadFieldName" :disabled="isSaving"
               @change="filesChange"
               :maxlength="maxFileSize ?? 1000000"
               :accept="accept ?? '*'" class="input-file">
        <p @click="() => !isSaving ? $refs.file_input.click() : null" >{{ text }}</p>
        <i v-if="!isSaving" @click="$refs.file_input.click()" :class="icon"></i>
        <PProgressSpinner v-else-if="isSaving" strokeWidth="5"/>
      </div>
      <div v-if="fileCount > 0 && sendRoute && !isFailed" class="upload__btn">
        <PButton icon="fas fa-upload" label="Upload" @click="sendFiles"/>
      </div>
    </div>
  </form>
</template>

<script>

import {MSG_UPLOAD} from "@/constants/Messages";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {next} from "lodash/seq";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_READY = 4;

export default {
  name: "UploadComponent",
  props: ['accept', 'multiple', 'maxFileSize', 'items', 'sendRoute', 'parentProps'],
  data: () => ({
    files: [],
    uploadedFiles: [],
    uploadError: null,
    currentStatus: STATUS_INITIAL,
    uploadFieldName: 'file',
    // fileCount: 0,
    dataToPost: []
  }),

  computed: {
    fileCount() {
      return this.uploadedFiles && this.uploadedFiles.length ? this.uploadedFiles.length : 0;
    },
    icon() {
      switch (this.currentStatus) {
        case STATUS_READY: {
          return `fas ${this.fileCount > 1 ? 'fa-list-check' : 'fa-check'} upload_success`
        }
        case STATUS_SUCCESS: {
          return "fas fa-check upload_success"
        }
        case STATUS_FAILED:
          return "pi pi-times-circle upload_error"
        default:
          return "fas fa-upload"
      }
    },
    text() {
      switch (this.currentStatus) {
        case STATUS_SAVING:
          return MSG_UPLOAD.SAVING(this.$t_, this.fileCount)
          // return `Upload in corso di ${this.fileCount} file`
        case STATUS_READY:
          return MSG_UPLOAD.READY(this.$t_, this.fileCount)
        case STATUS_SUCCESS:
          return MSG_UPLOAD.SUCCESS(this.$t_, this.fileCount)
          // return `${this.fileCount} file caricato/i con successo`
        case STATUS_FAILED:
          return MSG_UPLOAD.FAILED(this.$t_)
          // return "Problemi durante l\'upload"
        default:
          return MSG_UPLOAD.INITIAL(this.$t_)
          // return "Trascina qui il file"
      }
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isReady() {
      return this.currentStatus === STATUS_READY;
    },
    isSuccess() {
      return this.sendRoute ? STATUS_READY : STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },

  methods: {
    ...mapMutations({
      setGlobalObject: "Global/setObject",
    }),
    ...mapActions({
      sendData: "Utilities/sendFile"
    }),

    reset() {
      // reset form to initial state
      this.uploadedFiles = this.items;
      this.uploadError = null;
      this.$refs.uploadForm.reset();
    },

    filesChange(event) {
      this.uploadedFiles = event.target.files;
      const accepted = event.target.accept.split(',');
      // this.fileCount = this.uploadedFiles.length;
      this.dataToPost = [];

      this.currentStatus = STATUS_SAVING;

      for (let i = 0; i < this.fileCount; i++) {
        if(!accepted.includes(`.${this.uploadedFiles[i].name.split('.').pop()}`)) {
          this.currentStatus = STATUS_FAILED;
          this.$refs.uploadForm.reset();
          return null;
        }
        let name = this.uploadedFiles[i].name.replace(/ /g, "__");
        let data = {
          icon: this.$getFileIcon(this.uploadedFiles[i].name),
          id: null,
          "item-text": this.uploadedFiles[i].name,
          "item-value": name,
          name: name,
          file: this.uploadedFiles[i]
        };

        (!this.sendRoute) ? this.$emit('update', data) : this.dataToPost.push(data)
      }

      setTimeout(() => {
        this.currentStatus = this.sendRoute ? STATUS_READY : STATUS_SUCCESS
        this.$refs.file_input.value = ""
      }, 500)

    },

    sendFiles() {
      let toSend = {};
      this.dataToPost.map(el => toSend[el.name] = el);

      this.sendData({config: this.parentProps, data: toSend, _token: this.$getTnk()});
      setTimeout(() => this.setGlobalObject({displayDialog: false}), 500)
    }

  },

  mounted() {
    this.currentStatus = STATUS_INITIAL;
    this.reset();
  }
}
</script>

<style scoped>

</style>