<template>
  <PToolbar :class="['main__header', 'elevate', sidebarOpened ? 'main__header-sidebar_opened' : '']"
            v-styleclass="{ selector: '@next', toggleClass: 'main__header-no_sidebar'}">
    <!--    <template #start>-->
    <!--      <PButton icon="pi pi-search" class="mr-2"/>-->
    <!--      <PButton icon="pi pi-calendar" class="p-button-success mr-2"/>-->
    <!--      <PButton icon="pi pi-times" class="p-button-danger"/>-->
    <!--    </template>-->
    <template #start v-if="!sidebarOpened">
      <div class="sidebar__logo title header__bar" @click="$store.commit('Main/setSidebarOpened', true)"
           v-tooltip="$t_('APP_TITLE_OPEN_SIDEBAR')">
        <a>
          <p style="margin-bottom: 0">To<span>Sca</span></p>
        </a>
      </div>
    </template>
    <template #end>
<!--      <NotifyHeaderButton/>-->
      <!--      <PButton icon="pi pi-bell" :badge="notifiche.length > 99 ? '99+' : notifiche.length.toString()"-->
      <!--               :badgeClass="`p-badge-danger ${notifiche.length >= 10 ? 'big' : ''}`"-->
      <!--               @click="toggle" aria-haspopup="true" aria-controls="notify_menu"></PButton>-->
      <!--      <POverlayPanel ref="notify_menu" :dismissable="true" :showCloseIcon="false">-->
      <!--        <PCard v-for="(n, idx) in notifiche" :key="`notify_${idx}`">-->
      <!--          <template #title>-->
      <!--            aaa {{n.title}}-->
      <!--          </template>-->
      <!--          <template #content>-->
      <!--            {{n.text}}-->
      <!--          </template>-->
      <!--        </PCard>-->
      <!--      </POverlayPanel>-->
<!--      <PButton icon="pi pi-search" class=""/>-->
      <!--      <PButton icon="pi pi-calendar" class="p-button-success mr-2"/>-->
      <!--      <PButton icon="pi pi-times" class="p-button-success"/>-->
      <ProfileHeaderButton/>
    </template>
  </PToolbar>
</template>

<script>
// import ProfileHeaderButton from "@/components/layout/ProfileHeaderButton.vue";
import {mapGetters} from "vuex";
// import NotifyHeaderButton from "@/components/layout/NotifyHeaderButton.vue";
export default {
  name: "HeaderComponent",
  // components: {NotifyHeaderButton, ProfileHeaderButton},

  data: () => ({
    notifiche: [
      {
        id: 0,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 1,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 2,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 3,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
      {
        id: 4,
        title: "Nuovo Dealer",
        text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium.",
        type: "danger"
      },
    ]
  }),

  computed: {
    ...mapGetters({
      sidebarOpened: "Main/getSidebarOpened",
    })
  },

  methods: {
    toggle(event) {
      this.$refs.notify_menu.toggle(event);
    }
  },

  mounted() {
    // Test notifiche >= 99
    // for(let i = 1; i <= 100; i++) {
    //   this.notifiche.push({id: 1, title: "Nuovo Dealer", text: "Nuclear vexatum iaceres sunt idoleums de barbatus consilium." , type: "danger"})
    // }
  }

}
</script>

<style scoped lang="scss">

div.sidebar__logo {
  cursor: pointer;

  a {
    p {
      color: #ff0000;

      span {
        color: orange;
      }
    }
  }
}


</style>