

import Utilities_getters from "./_getters";
import Utilities_mutations from "./_mutations";
import Utilities_actions from "./_actions";
import INITIAL_STATE from "@/constants/InitialState";

const Utilities = {

    namespaced: true,
    state: () => INITIAL_STATE.UTILITIES(),

    getters:{
        ...Utilities_getters
    },
    mutations:{
        ...Utilities_mutations
    },
   actions:{
        ...Utilities_actions
    }

}

export default Utilities;
