const MOCK_PANELS = {
    multiple: false,
    activeIndex: 0,
    items: [
        {
            icon: "fas fa-question-circle",
            title: "Panel 1",
            text: "Primus idoleums ducunt ad resistentia.Capio de fidelis nix, imperium idoleum!Emeritis adelphiss ducunt ad rumor.Vae, fortis quadra!Est raptus orexis, cesaris.A falsis, mons placidus tata."
        },
        {
            icon: "fas fa-question-circle",
            title: "Panel 2",
            text: "Vox, luba, et hippotoxota.Ortum una ducunt ad germanus orgia.Sunt devirginatoes captis gratis, audax scutumes.Ionicis tormentos peregrinatione in brevis chremisa!"
        },
        {
            icon: "fas fa-question-circle",
            title: "Panel 3",
            text: "Solems volare in albus tolosa!Axonas trabem!Fortis, primus seculas inciviliter talem de dexter, grandis visus.Nunquam anhelare cacula.Paluss sunt aususs de albus indictio.Sunt boreases reperire clemens, nobilis aususes.Est regius nuclear vexatum iacere, cesaris."
        }
    ]
}
export default MOCK_PANELS;