import COLORS from "@/constants/Colors";
import {MOCK_VFORM, MOCK_VFORM_UPLOAD} from "@/constants/mock_data/MockVForm";

const MOCK_WIDGETS = [
    {
        title: "",
        description: "",
        position: "top",
        items: [
            {
                layout: {
                    sm: "12",
                    lg: "4",
                    xl: "4",
                    md: "6",
                },
                name: "ticket_aperti",
                icon: "fa-solid fa-ticket",
                titleIcon: null,
                title: "Tickets aperti",
                align_title: "right",
                colors: [COLORS.ICONA_TICKET_APERTI],
                type: null,
                value: "36"
            },
            {
                layout: {
                    sm: "12",
                    lg: "4",
                    xl: "4",
                    md: "6",
                },
                name: "scanner_attivi",
                icon: "mdi mdi-scanner",
                title: "Scanner attivi",
                titleIcon: null,
                align_title: "right",
                colors: [COLORS.ICONA_SCANNER_ATTIVI],
                type: "",
                value: "2"
            },
            {
                layout: {
                    sm: "12",
                    lg: "4",
                    xl: "4",
                    md: "6",
                },
                name: "dealers",
                icon: "fa-solid fa-store",
                title: "Dealers",
                titleIcon: null,
                align_title: "right",
                colors: [COLORS.ICONA_DEALERS],
                type: null,
                value: "2g14h17m"
            },
        ]
    },
    {
        title: "",
        description: "",
        position: "top",
        items: [
            {
                layout: {
                    sm: "12",
                    lg: "4",
                    xl: "4",
                    md: "12",
                },
                name: "ticket_status",
                icon: null,
                title: "Tickets",
                titleIcon: null,
                align_title: null,
                colors: [],
                type: "progress_list",
                items: [
                    {title: "Spedizioni", value: 87, currency: "", color: "#f00", valueColor: "#fff"},
                    {title: "Doa", value: 100, currency: "%", color: "#ff0", valueColor: "inherit"},
                    {title: "Rientri", value: 50, currency: "", color: "cyan", valueColor: "#fff"},
                    {title: "Supporto", value: 92, valueColor: "#fff"},
                    {title: "Sostituzioni", value: 8, currency: "%", color: "#f0f", valueColor: "#fff"},
                ]
            },
            {
                layout: {
                    sm: "12",
                    lg: "8",
                    xl: "8",
                    md: "12",
                },
                name: "ultimi_ticket",
                icon: null,
                title: "Ultimi tickets",
                titleIcon: null,
                align_title: "",
                colors: null,
                type: "datatable",
                items: [],
                datatable: {
                    data: [
                        {
                            "id": 1,
                            "name": "utente1",
                            "email": "mario.mennella@rrdl.it",
                            "email_verified_at": null,
                            "two_factor_code": "39572",
                            "two_factor_expires_at": "2023-01-19 12:52:39",
                            "created_at": "2022-10-14T15:56:35.000000Z",
                            "updated_at": "2023-01-19T11:42:39.000000Z",
                            "deleted_at": null,
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/1",
                                    "follow": "frontend.users.show",
                                    "props": {
                                        "base_model": "user",
                                        "find": 1,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/1\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/1",
                                    "props": {
                                        "base_model": "user",
                                        "find": 1,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/1\/edit"
                                    },
                                    "follow": "frontend.users.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/1",
                                    "flw": null,
                                    "follow": "frontend.users.destroy",
                                    "text": "delete",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }, {
                            "id": 2,
                            "name": "utente2",
                            "email": "marco.arena@ssgrr.com",
                            "email_verified_at": null,
                            "two_factor_code": null,
                            "two_factor_expires_at": null,
                            "created_at": "2022-10-14T15:56:35.000000Z",
                            "updated_at": "2023-02-02T10:51:31.000000Z",
                            "deleted_at": null,
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/2",
                                    "follow": "frontend.users.show",
                                    "props": {
                                        "base_model": "user",
                                        "find": 2,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/2\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/2",
                                    "props": {
                                        "base_model": "user",
                                        "find": 2,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/2\/edit"
                                    },
                                    "follow": "frontend.users.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/2",
                                    "flw": null,
                                    "follow": "frontend.users.destroy",
                                    "text": "delete",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }, {
                            "id": 3,
                            "name": "ciaffciaff",
                            "email": "dario.pietroleonardo@ssgrr.com",
                            "email_verified_at": null,
                            "two_factor_code": null,
                            "two_factor_expires_at": null,
                            "created_at": "2022-12-19T09:28:22.000000Z",
                            "updated_at": "2023-02-03T09:11:50.000000Z",
                            "deleted_at": null,
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/3",
                                    "follow": "frontend.users.show",
                                    "props": {
                                        "base_model": "user",
                                        "find": 3,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/3\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/3",
                                    "props": {
                                        "base_model": "user",
                                        "find": 3,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/3\/edit"
                                    },
                                    "follow": "frontend.users.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/3",
                                    "flw": null,
                                    "follow": "frontend.users.destroy",
                                    "text": "delete",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }, {
                            "id": 4,
                            "name": "ciaffciaff1",
                            "email": "agnese.salutari@ssgrr.com",
                            "email_verified_at": null,
                            "two_factor_code": "54687",
                            "two_factor_expires_at": "2023-01-24 16:37:14",
                            "created_at": "2022-12-19T09:28:22.000000Z",
                            "updated_at": "2023-01-24T15:27:14.000000Z",
                            "deleted_at": null,
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/4",
                                    "follow": "frontend.users.show",
                                    "props": {
                                        "base_model": "user",
                                        "find": 4,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/4\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/4",
                                    "props": {
                                        "base_model": "user",
                                        "find": 4,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/4\/edit"
                                    },
                                    "follow": "frontend.users.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/4",
                                    "flw": null,
                                    "follow": "frontend.users.destroy",
                                    "text": "delete",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }, {
                            "id": 5,
                            "name": "ciaffciaff11",
                            "email": "davide.nurzia@ssgrr.com",
                            "email_verified_at": null,
                            "two_factor_code": "62704",
                            "two_factor_expires_at": "2023-01-19 14:15:30",
                            "created_at": "2022-12-20T10:25:20.000000Z",
                            "updated_at": "2023-01-19T13:05:30.000000Z",
                            "deleted_at": null,
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/5",
                                    "follow": "frontend.users.show",
                                    "props": {
                                        "base_model": "user",
                                        "find": 5,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/5\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/5",
                                    "props": {
                                        "base_model": "user",
                                        "find": 5,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/5\/edit"
                                    },
                                    "follow": "frontend.users.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/5",
                                    "flw": null,
                                    "follow": "frontend.users.destroy",
                                    "text": "delete",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }, {
                            "id": 6,
                            "name": "ciaffciaff2",
                            "email": "ciaffciaff2@ciaff.ciaff",
                            "email_verified_at": null,
                            "two_factor_code": null,
                            "two_factor_expires_at": null,
                            "created_at": "2022-12-20T10:25:20.000000Z",
                            "updated_at": "2022-12-20T10:25:20.000000Z",
                            "deleted_at": null,
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/6",
                                    "follow": "frontend.users.show",
                                    "props": {
                                        "base_model": "user",
                                        "find": 6,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/6\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/6",
                                    "props": {
                                        "base_model": "user",
                                        "find": 6,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/6\/edit"
                                    },
                                    "follow": "frontend.users.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/6",
                                    "flw": null,
                                    "follow": "frontend.users.destroy",
                                    "text": "delete",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }, {
                            "id": 7,
                            "name": "ciaffciaff3",
                            "email": "ciaffciaff3@ciaff.ciaff",
                            "email_verified_at": null,
                            "two_factor_code": "83765",
                            "two_factor_expires_at": "2023-01-24 16:34:24",
                            "created_at": "2022-12-20T10:25:20.000000Z",
                            "updated_at": "2023-01-24T15:24:24.000000Z",
                            "deleted_at": null,
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/7",
                                    "follow": "frontend.users.show",
                                    "props": {
                                        "base_model": "user",
                                        "find": 7,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/7\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/7",
                                    "props": {
                                        "base_model": "user",
                                        "find": 7,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user\/7\/edit"
                                    },
                                    "follow": "frontend.users.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/users\/7",
                                    "flw": null,
                                    "follow": "frontend.users.destroy",
                                    "text": "delete",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }],
                    "headers": [
                        {"field": "id", "header": "ID", "sortable": false, "color": ""}, {
                            "field": "name",
                            "header": "Username",
                            "sortable": false,
                            "color": ""
                        }, {
                            "field": "email",
                            "header": "Email",
                            "sortable": false,
                            "color": ""
                        }, {
                            "field": "two_factor_code",
                            "header": "TRANS_MODEL_DATA_APPMODELSUSER_TWO_FACTOR_CODE",
                            "sortable": false,
                            "color": ""
                        }, {
                            "field": "two_factor_expires_at",
                            "header": "TRANS_MODEL_DATA_APPMODELSUSER_TWO_FACTOR_EXPIRES_AT",
                            "sortable": false,
                            "color": ""
                        }, {"color": "", "field": "actions", "header": "#", "sortable": false}],
                    "buttons": null,
                    "actions": [
                        // {
                        //     "icon": null,
                        //     "color": "#FF7F24",
                        //     "iconColor": "",
                        //     "items": [],
                        //     "to": "user\/global-form",
                        //     "url": null,
                        //     "label": "APP_UI_ACTS_CREATE",
                        //     "follow": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/user"
                        // }
                    ],
                    "footer": [],
                    "paginate": {
                        "current_page": 1,
                        "first_page_url": "https:\/\/tosca.adel.cloud\/api\/users?page=1",
                        "from": 1,
                        "last_page": 1,
                        "last_page_url": "https:\/\/tosca.adel.cloud\/api\/users?page=1",
                        "links": [{
                            "url": null,
                            "label": "&laquo; Previous",
                            "active": false
                        }, {
                            "url": "https:\/\/tosca.adel.cloud\/api\/users?page=1",
                            "label": "1",
                            "active": true
                        }, {"url": null, "label": "Next &raquo;", "active": false}],
                        "next_page_url": null,
                        "path": "https:\/\/tosca.adel.cloud\/api\/users",
                        "per_page": 2,
                        "prev_page_url": null,
                        "to": 7,
                        "total": 7
                    }
                }
            }
        ]
    },
    {
        title: "",
        description: "",
        position: "top",
        items: [
            {
                layout: {
                    sm: "12",
                    lg: "4",
                    xl: "4",
                    md: "12",
                },
                name: "ticket_status_2",
                icon: null,
                title: "Tickets 2",
                titleIcon: null,
                align_title: null,
                colors: [],
                type: "progress_list",
                items: [
                    {title: "Spedizioni", value: 87, currency: "", color: "#f00", valueColor: "#fff"},
                    {title: "Doa", value: 100, currency: "%", color: "#ff0", valueColor: "inherit"},
                    {title: "Rientri", value: 50, currency: "", color: "cyan", valueColor: "#fff"},
                    {title: "Supporto", value: 92, valueColor: "#fff"},
                    {title: "Sostituzioni", value: 8, currency: "%", color: "#f0f", valueColor: "#fff"},
                ]
            },
            {
                layout: {
                    sm: "12",
                    lg: "8",
                    xl: "8",
                    md: "12",
                },
                name: "ultimi_ticket_2",
                icon: null,
                title: "Ultimi tickets 2",
                titleIcon: null,
                align_title: "",
                colors: null,
                type: "datatable",
                items: [],
                datatable: {
                    "data": [
                        {
                            "id": 6,
                            "type": "TYPE_1",
                            "province": {
                                "id": 66,
                                "name": "L'Aquila "
                            },
                            "municipality": {
                                "id": 66049,
                                "name": "L'Aquila"
                            },
                            "address": "Via E. B",
                            "actions": {
                                "show": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/warehouses\/6",
                                    "follow": "frontend.warehouses.show",
                                    "props": {
                                        "base_model": "warehouse",
                                        "find": 6,
                                        "type": "show",
                                        "saveble": false,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/warehouse\/6\/show"
                                    },
                                    "component": "GenericVForm",
                                    "text": "show",
                                    "action": "showThis",
                                    "click": "showThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "edit": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/warehouses\/6",
                                    "props": {
                                        "base_model": "warehouse",
                                        "find": 6,
                                        "type": "edit",
                                        "saveble": true,
                                        "flw": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/warehouse\/6\/edit"
                                    },
                                    "follow": "frontend.warehouses.show",
                                    "component": "GenericVForm",
                                    "text": "edit",
                                    "action": "editThis",
                                    "click": "editThis",
                                    "class": "",
                                    "icon": ""
                                },
                                "delete": {
                                    "to": "https:\/\/tosca.adel.cloud\/api\/warehouses\/6",
                                    "flw": null,
                                    "follow": "frontend.warehouses.destroy",
                                    "text": "delete",
                                    "component": "GenericIndex",
                                    "action": "deletehis",
                                    "click": "deleteThis",
                                    "class": "",
                                    "icon": ""
                                }
                            }
                        }
                    ],
                    "headers": [
                        {
                            "field": "id",
                            "header": "ID",
                            "sortable": false,
                            "color": ""
                        },
                        {
                            "field": "type",
                            "header": "Tipo",
                            "sortable": false,
                            "color": ""
                        },
                        {
                            "field": "province",
                            "header": "Provincia",
                            "sortable": false,
                            "color": ""
                        },
                        {
                            "field": "municipality",
                            "header": "Citt\u00e0",
                            "sortable": false,
                            "color": ""
                        },
                        {
                            "field": "address",
                            "header": "Indirizzo",
                            "sortable": false,
                            "color": ""
                        },
                        {
                            "color": "",
                            "field": "actions",
                            "header": "#",
                            "sortable": false
                        }
                    ],
                    "buttons": null,
                    "actions": [
                        {
                            "icon": null,
                            "color": "#FF7F24",
                            "iconColor": "",
                            "items": [],
                            "to": "warehouse\/global-form",
                            "url": null,
                            "label": "APP_UI_ACTS_CREATE",
                            "follow": "https:\/\/tosca.adel.cloud\/api\/v1\/global-schema\/warehouse"
                        }
                    ],
                    "footer": [],
                    "paginate": []
                }
            }
        ]
    },
    {
        title: "",
        description: "",
        position: "bottom",
        items: [
            {
                layout: {
                    sm: "12",
                    lg: "4",
                    xl: "4",
                    md: "12",
                },
                name: "last_ticket",
                icon: null,
                title: "Ultimo Ticket VForm",
                titleIcon: null,
                align_title: null,
                colors: [],
                type: "timeline",
                items: [
                    {
                        title: 'Spedito',
                        value: '15/10/2020 10:30',
                        status: 'Spedito',
                        date: '15/10/2020 10:30',
                        icon: 'mdi mdi-truck',
                        bgColor: "#673AB7",
                        color: '#FFF',
                        image: 'game-controller.jpg',
                        disabled: false
                    },
                    {
                        title: 'Consegnato a Mario Rossi',
                        value: '15/10/2020 14:00',
                        status: 'Consegnato a Mario Rossi',
                        date: '15/10/2020 14:00',
                        icon: 'mdi mdi-package-variant-closed',
                        bgColor: "#FF9800",
                        disabled: false
                    },
                    {
                        title: 'Installato',
                        value: '15/10/2020 16:15',
                        status: 'Installato',
                        date: '15/10/2020 16:15',
                        icon: 'pi pi-link',
                        bgColor: '#4FCAC6',
                        disabled: true
                    },
                    {
                        title: 'Attivato',
                        value: '16/10/2020 10:00',
                        status: 'Attivato',
                        date: '16/10/2020 10:00',
                        icon: 'pi pi-check',
                        bgColor: '#E31079',
                        disabled: true
                    }//'#607D8B'}
                ]
            },
            {
                layout: {
                    sm: "12",
                    lg: "8",
                    xl: "8",
                    md: "12",
                },
                name: "vform-test",
                icon: null,
                title: "VForm Upload",
                titleIcon: "pi pi-database",
                align_title: null,
                colors: [],
                type: "form",
                items: null,
                datatable: null,
                form: MOCK_VFORM
            }
        ]
    },
    {
        title: "",
        description: "",
        position: "bottom",
        items: [
            {
                layout: {
                    sm: "12",
                    lg: "12",
                    xl: "12",
                    md: "12",
                },
                name: "upload_widget",
                icon: null,
                title: "Allegati",
                titleIcon: "mdi mdi-paperclip",
                align_title: null,
                colors: [],
                type: "upload",
                items: [
                    {
                        "id": 1,
                        "icon": "fas fa-file-word",
                        "item-text": "API Schema.docx",
                        "item-value": "API__Schema.docx",
                        "name": "API__Schema.docx",
                        "file": null
                    },
                    // {
                    //     "id": 2,
                    //     "item-text": "documento2.docx",
                    //     "item-value": 2,
                    //     "name": "doc2",
                    //     "icon": null,
                    //     "file": null
                    // },
                    // {
                    //     "id": 3,
                    //     "item-text": "documento3.xls",
                    //     "item-value": 3,
                    //     "name": "doc3",
                    //     "icon": null,
                    //     "file": null
                    // },
                    // {
                    //     "id": 4,
                    //     "item-text": "documento4.csv",
                    //     "item-value": 4,
                    //     "name": "doc4",
                    //     "icon": null,
                    //     "file": null
                    // }
                ]
            },
        ]
    },
    // {
    //     title: "",
    //     description: "",
    //     items: [
    //         {
    //             layout: {
    //                 sm: "12",
    //                 lg: "12",
    //                 xl: "12",
    //                 md: "12",
    //             },
    //             name: "vform-test",
    //             icon: null,
    //             title: "VForm test",
    //             titleIcon: "pi pi-database",
    //             align_title: null,
    //             colors: [],
    //             type: "form",
    //             items: null,
    //             datatable: null,
    //             form: MOCK_VFORM
    //         }
    //     ]
    // }
]

export default MOCK_WIDGETS;