<template>
    <div v-if="ready" class="auth__container">
      <router-view :key="$route.path"></router-view>
    </div>
    <LoaderComponent v-else :value="true" />
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Auth",

  data: () => ({
    ready: false
  }),

  watch: {
    logged: {
      handler() {
        setTimeout(() => {
          this.logged ? this.$navigateName('backend') : this.ready = true //this.$navigateName('backend')
        }, 1200)
      }
    }
  },

  computed: {
    ...mapGetters({
      logged: "Main/getLogged"
    }),
  },

  mounted() {
    setTimeout(() => {
      this.logged ? this.$navigateName('backend') : this.ready = true//this.$navigateName('backend')
    }, 200)
  },

}
</script>

<style scoped>

</style>