<template>
  <div>
    <PCard class="generic__page elevate" v-if="canShow">
      <template #title v-if="server_response.title">
        <p :class="`generic__page-title ${contentType ?? ''}`">
          {{ server_response.title ?? 'Warehouse' }} <span
            v-if="server_response.subTitle">{{ server_response.subTitle }}</span>
        </p>
      </template>
      <template #content>
        <div class="generic__page-content">
          <StepperComponent v-if="contentType === 'stepper'" :baseModel="base_model" :updateStore="updateStore"
                            :savable="savable" :sendData="sendData" :showActions="true"/>

          <TabsComponent v-else-if="contentType === 'tab'" :items="server_response.items" :updateStore="updateStore"
                         :showActions="false"/>

          <h3 v-else style="text-align: center;">{{ $t_('APP__TITLE__SHOW_DATA_NOT_POSSIBLE') }}</h3>

        </div>
      </template>
    </PCard>
    <PCard v-if="showTickets" class="generic__page elevate" style="margin-top: 1em;">
      <template #title>
        <p :class="`generic__page-title `">
          {{ $t_("APP__TITLE__STORICO_TK") }}
        </p>
      </template>
      <template #content>
        <div class="generic__page-content">
          <template v-for="item in getFilteredList">
            <v-card class="pa-2">
              <v-card-title>
                  {{item}}
              </v-card-title>
              <v-card-text>
                <ExpanderComponent :items="getCurrentItemAccordion(item)" :key="`_elem_tickets_type_${item}`"> </ExpanderComponent>
              </v-card-text>
            </v-card>

          </template>

        </div>
      </template>
    </PCard>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import initialState from "@/constants/InitialState";
import MOCK_DATA from "@/constants/mock_data";
// import WidgetStepSlot from "@/components/widgets/slots/WidgetStepSlot.vue";
// import ExpanderComponent from "@/components/common/expander/ExpanderComponent.vue";

export default {
  name: "GenericVForm",
  // components: {ExpanderComponent, WidgetStepSlot},
  props: ["base_model", "type", "find", "flw", "savable"],
  data() {
    return {
      server_response: null,
      canShow: false,
      showTickets: false,
      contentType: 'tab',
      // stepper: 0,
      // steps: 0,
      // vformArrayId: 0,
      // showActions: true,
    }
  },

  beforeRouteLeave: function (to, from, next) {
    setTimeout(() => {
      !this.statusApplication.status ? this.resetStore() : null;
    }, 800)
    next()
  },
  watch: {
    server_response: {
      handler() {
        this.canShow = null !== this.server_response;
        this.showTickets = (this.server_response && this.server_response.tickets && this.server_response.tickets.length > 0);
      },
      deep: true
    },
  },
  filters: {
    increment_value: function (e) {
      return e++
    }
  },
  computed: {
    ...mapGetters({
      step: "Global/getCurrentStep",
      // item: "Global/getCurrentItem",

    }),
    getFilteredList: function(){
      return  [...new Set([].concat(this.server_response.tickets.map(e => e.pdv + ' ' + e.scanner)))]
    },

  },

  methods: {
    ...mapMutations({
      setObject: "Global/setObject",
      setItem: "Global/setitems",
      updateEl: "Global/updateValueStore",
      resetStore: "Global/resetStore",
      statusApplication: "Main/getStatusApplication"
    }),
    getCurrentItemAccordion(fil){
      return this.server_response.tickets.map(e => e.pdv + ' ' + e.scanner === fil ? e : null).filter(e=>e!=null)
    },



    getModel() {
      this.resetStore();
      let url = this.flw === undefined ? `/api/v1/global-schema/${this.base_model}` : this.flw;
      axios({
        method: "get",
        url: url,
        headers: this.$getTnk()
            ? {Authorization: `Bearer ${this.$getTnk()}`} : null

      })
          .then(res => {
            this.server_response = res.data
            this.steps = res.data.items.length
            this.contentType = res.data.type
            this.setItem(res.data.items)
          })
          .catch(error => {
            console.log(error.message, "---> GenericIndex")
            this.$toast.add({
              ...MOCK_DATA.toasts(this.$t_).FETCH.error,
              group: this.base_model
            });
          });
    },

    sendData(item) {
      if (item) {
        let dialog = MOCK_DATA.dialogs(this.$t_).QUESTION;
        dialog.text = this.$t_("APP__DIALOG__TEXT__SAVE");
        dialog.toast_group = this.base_model;
        dialog.onYes = () => {
          axios.post(`/api/v1/global-schema/${this.base_model}`, item, {
            headers: this.$getTnk()
                ? {Authorization: `Bearer ${this.$getTnk()}`} : null,

          }).then(res => res.data).then(res => {

            this.setObject({step: 1});
            this.setObject({item: Object.keys(res).map((k) => res[k].label ? res[k] : ({label: k, ...res[k]}))})

            this.$toast.add({
              severity: 'success',
              summary: this.$t_('APP__MESSAGE__TITLE__SAVED__OK'),
              detail: this.$t_('APP__MESSAGE__SAVED__OK'),
              life: 3000,
              group: this.base_model
            });
            if (!this.statusApplication.status)
              this.$store.dispatch('Main/initRequests', {_token: this.$getTnk()}, {root: true})
          })
              .catch(error => {
          //       console.log(error.message);
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t_('APP__MESSAGE__TITLE__SAVED__KO'),
                  detail: this.$t_('APP__MESSAGE__SAVED__KO'),
                  life: 3000,
                  group: this.base_model
                });
              })
        }

        this.setObject({dialog: dialog, displayDialog: true})
      }
    },
    /*
      BLOCK FORM UPDATE STORE
     */
    updateStore(props) {
      const {on, id, key, value, params, obj, data, schema, parent, index, event, type, values} = props
      let a = null

      let findKey = ['item_value', 'item-value']
      // console.log(value instanceof Object)
      if (value instanceof Object) {
        for (let i in findKey)
          a = value[findKey[i]] !== undefined && null === a ? value[findKey[i]] : a
      } else a = value
      key !== undefined && value !== undefined ?
          this.updateEl({
            step: this.step - 1,
            key: key,
            value: a
          }) : null

    },
    /*
    END BLOCK
     */
    /*
    UTL STEPPER
     */
    // prevStep(n) {
    //   if (n > 0 && n <= this.steps) {
    //     // this.stp = n - 1
    //     this.setObject({step: this.stepper})
    //     this.stepper--
    //   }
    // },
    // nextStep(n) {
    //   let str = `custom_form_${n}`
    //   Object.keys(this.$refs).forEach(r => {
    //     if (r === str) {
    //       if (this.$refs[r][0].validate())
    //         if (n >= 0 && n < this.steps) {
    //           this.stepper++
    //           this.setObject({step: this.stepper + 1})
    //         }
    //     }
    //   })
    // },
    /*
    END
     */
  },

  beforeMount() {
    this.$store.commit("Global/initToast", this.$props.base_model)
  },

  destroyed() {
    // this.$toast.removeGroup(this.base_model);
    this.$destroyToast(this.base_model, initialState.GLOBAL().toast);
  },

  mounted() {
    // console.log("eccomi");
    this.getModel()
  }

}
</script>

<style scoped lang="scss">

@import "src/assets/primevue/variables";

.generic__page {

  .p-card-body {
    .p-card-content {

    }
  }

  &-title {
    margin: 1.5rem auto;
    color: $icona-scanner-attivi;

    &.tab {
      color: $unnamed-color-504f54;
    }

    font: normal normal normal 20px/26px Roboto;
    letter-spacing: 0;
    text-transform: capitalize;

    span {
      color: $unnamed-color-c5c5c5;
    }
  }

  &-content {
    margin-top: 1.5rem;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

}


</style>