
const Ticket_actions = {

    async fetchData({commit, dispatch, state}, data) {
        let {apiPath, method, headers, params = null} = data;

        let url = params ? `${apiPath}?params=${params}` : apiPath;
        let filter = (params && params.filter);
         // console.log(data);
        // debugger;
        return await axios({
            url: url,
            method: method ?? "GET",
            headers: headers
        })
            .then(res => res.data)
            .then(res => {
                (filter) ? commit("setObject", {tickets: res.tickets, paginator: res.paginator})
                    : commit("setObject", {...res});
                commit("setHistory", res)
                return res;
            })
    },

    async inifiniteScroll({commit, dispatch, state}, data){
        var call = true
        let {apiPath, method, headers, params = null, oth=null} = data;

        let url = params ? `${apiPath}?params=${params}` : apiPath;
        let filter = (params && params.filter);

        if(null!== oth && oth.id !== undefined){
            let index =   state.tickets.findIndex(ee => ee.id === oth.id)
            if (index !== -1) call=false
        }

        if(call){
            return await axios({
                url: url,
                method: method ?? "GET",
                headers: headers
            })
                .then(res => res.data)
                .then(res => {
                    (filter) ? commit("setUpdateAppendObject", {tickets: res.tickets, paginator: res.paginator})
                        : commit("setUpdateAppendObject", res);
                    commit("setHistory", res)
                    return res;
                })
        }

    },




    partialUpdate({commit, dispatch, state}, data){
        let {apiPath, method, headers, params = null} = data;
        axios({
            url: apiPath,
            method: method ?? "POST",
            headers: headers,
            data: params
        }).then(res=>{
            commit("setLocalHistoryElements",{
                tickets: res.data.ticket,
                localUpdateIndex: data.localUpdateIndex,
                updateIndex: data.updateInHistory
            })
        })
    }



}

export default Ticket_actions;
