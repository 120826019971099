<template>
  <!--    <div v-if="ready" class="auth__container">-->
  <PCard>
    <template #title>
      <p>To<span>Sca</span></p>
    </template>
    <template #subtitle>
      {{ $t_('AUTH__SUBTITLE__LOGIN') }}
    </template>
    <template #content>
      <form>
        <div :class="{'p-inputgroup': true, 'invalid': !emailValid}">
          <PInputText required type="text" :placeholder="$t_('AUTH__PLACEHOLDER__USERNAME')" class="border-left"
                      v-model="email"/>
          <i class="pi pi-user icon border-right"/>
        </div>
        <PPassword required :placeholder="$t_('AUTH__PLACEHOLDER__PASSWORD')" v-model="passwd"
                   :inputClass="`border-all ${passwdValid ? '' : 'invalid'}`" toggleMask
                   promptLabel="Inserire la password" weakLabel="Debole" mediumLabel="Media" strongLabel="Forte">
          <template #footer>
            <PDivider/>
            <p class="mt-2">Suggerimenti</p>
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
              <li>Almeno una lettera minuscola</li>
              <li>Almeno una lettera maiuscola</li>
              <li>Almeno un numero</li>
              <li>Minimo 6 caratteri</li>
            </ul>
          </template>
        </PPassword>
        <!--              <PPassword placeholder="Password" class="border-left"/>-->
        <!--              <PButton :icon="`pi pi-${showPwd ? 'eye' : 'home'}`" class="inner-button border-right" @click="toggleShowPwd"/>-->

        <div class="auth__actions">
          <div class="p-inputgroup">
            <PButton :disabled.sync="disableLogin" class="border-all" type="submit" @click="sendData"
                     v-tooltip.top="$t_('AUTH__BUTTON__TITLE__LOGIN')">{{ $t_("AUTH__BUTTON__TITLE__LOGIN") }}
            </PButton>
          </div>
          <div class="p-inputgroup right">
            <PButton class="border-all p-button-link" type="button" @click="$navigateName('reset-password')"
                     v-tooltip.top="$t_('AUTH__BUTTON__TITLE__FORGOT_PASSWORD')">{{
                $t_("AUTH__BUTTON__TITLE__FORGOT_PASSWORD")
              }}
            </PButton>
          </div>
        </div>

      </form>
    </template>
  </PCard>
  <!--    </div>-->
  <!--    <LoaderComponent :value="!ready" />-->
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Login",

  data: () => ({
    ready: false,
    email: '',
    emailValid: false,
    passwd: '',
    passwdValid: false,
    resendUrl: localStorage.getItem('verify_resend'),
    sendUrl: localStorage.getItem(('verify_send')),
    token: null, //sessionStorage.getItem(('jwt_token')),
    verify: false,
  }),

  watch: {
    logged: {
      handler() {
        setTimeout(() => {
          this.logged ? this.$navigateName('backend') : this.ready = true //this.$navigateName('backend')
        }, 1200)
      }
    },
    email: {
      handler() {
        //FIX: non va inviata la mail ma la username con un minlen
        this.emailValid = this.$minlen(this.email, 5);
        //this.emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,5})+$/.test(this.email)
        // this.emailValid = ^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$/g.test(this.email)
      }
    },
    passwd: {
      handler() {
        this.passwdValid = this.passwd.length >= 6
      }
    },
    verify: {
      handler() {
        this.verify ? this.$navigateName('verify') : this.resetInputs();
      }
    }
  },

  computed: {
    ...mapGetters({
      logged: "Main/getLogged"
    }),

    disableLogin() {
      return !this.emailValid || !this.passwdValid
    }
  },

  methods: {
    ...mapMutations({
      setMainObject: "Main/setObject",
    }),
    // toggleShowPwd() {
    //   this.showPwd = !this.showPwd
    // }

    // getFromLocalStorage() {
    //   this.token = this.$getTnk()
    //   this.sendUrl = this.$getLocalStorageTTL('verify_send')
    //   this.resendUrl = this.$getLocalStorageTTL('verify_resend')
    // },

    resetInputs() {
      this.email = ''
      this.passwd = ''
    },

    sendData(event) {
      event.preventDefault()
      let data = {
        email: this.email,
        password: this.passwd
      }
      this.setMainObject({ready: false})

      axios.post('/api/v1/login', data)
          // .then((res) => res.data)
          .then(response => {
            let res = response.data;

            if(res.redirect){
              this.$navigate(`/${res.name}/${res.p}`)
            }else{
              this.token = res.token

              this.$setTnk(res.token);
              this.sendUrl = process.env.MODE === 'development' ? `${res.send}?watch=watch` : res.send
              this.$setLocalStorageTTL([{name: 'verify_send', value: this.sendUrl}])
              this.resendUrl = res.resend
              this.$setLocalStorageTTL([{name: 'verify_resend', value: this.resendUrl}])
              this.verify = (res.token && res.send && res.resend);

            }
            this.resetInputs()


          })
          .catch((error) => console.log(error.message))
           .finally(() => this.setMainObject({ready: true}))
    }
  },

  mounted() {
    this.token = this.$getTnk();
    this.resendUrl && this.sendUrl && this.token ? this.$navigateName('verify') : this.resetInputs()

    setTimeout(() => {
      this.logged ? this.$navigateName('backend') : this.ready = true//this.$navigateName('backend')
      // this.logged ? this.$navigateName('backend') : this.ready = true//this.$navigateName('backend')
    }, 200)
  },

}
</script>

<style scoped>

</style>