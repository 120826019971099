<template>
  <PAccordion :activeIndex="active" :multiple="multiple">
    <PAccordionTab class="custom__accordion" v-for="(i, idx) in items" :key="`${i.title}_${idx}`">
      <template #header>
        <p class="header_text"><i v-if="i.icon" :class="i.icon"></i>{{ i.stato }}</p>
      </template>
      <slot v-if="(i.history && i.history.length > 0)" name="content" :slotProps="i">
        <ul class="custom__accordion__list">
          <li v-for="(tk, index) in i.history" style="display: flex">
            <div><span>{{ $t_('APP__LABEL__DATE') }}:</span> {{ tk.date }}</div>
            <div><span>{{ $t_('APP__LABEL__STATUS') }}:</span> {{ tk.state.description }}</div>
          </li>
        </ul>
      </slot>
      <slot v-else name="content" :slotProps="i">
        <p>{{ i.text }}</p>
      </slot>
      <div>
        <PButton class="" v-tooltip="$t_('APP__BUTTON__TITLE__DETAILS')"
                 @click="() =>$router.push(`/details-ticket/${i.id}/view`)">
          {{ $t_('APP__BUTTON__LABEL__DETAILS') }}
        </PButton>
      </div>
    </PAccordionTab>
  </PAccordion>
</template>

<script>
export default {
  name: "ExpanderComponent",
  props: ['items', 'activeIndex', "multiple"],
  data: () => ({
    active: null
  }),



  mounted() {

    this.active = this.activeIndex;
  }
}
</script>

<style scoped lang="scss">
</style>