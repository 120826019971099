

import Datatable_getters from "./_getters";
import Datatable_mutations from "./_mutations";
import Datatable_actions from "./_actions";
import initialState from "@/constants/InitialState";

const Datatable = {

    namespaced: true,
    state: () => initialState.DATATABLE(),

    getters:{
        ...Datatable_getters
    },
    mutations:{
        ...Datatable_mutations
    },
   actions:{
        ...Datatable_actions
    }

}

export default Datatable;
