<template>
  <div class="backend__container not-found__container">
    <div class="error_image">
      <img src="@/assets/common/images/404-error.jpg"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "ErrorPage",
  props: ['errorCode'],

  mounted() {
    // console.log(this.errorCode)
  }
}
</script>

<style scoped>

</style>