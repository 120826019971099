

import Global_getters from "./_getters";
import Global_mutations from "./_mutations";
import Global_actions from "./_actions";
import initialState from "@/constants/InitialState";

const Global = {

    namespaced: true,
    state: () => initialState.GLOBAL(),

    getters:{
        ...Global_getters
    },
    mutations:{
        ...Global_mutations
    },
   actions:{
        ...Global_actions
    }

}

export default Global;
