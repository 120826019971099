
const Account_actions = {


    sendLoginCredentials({commit, dispatch, state},obj){
        axios.post('/api/v1/login', obj).then(res=>{

        }).catch(err=>{

        })
    },

    restorePassword({commit, dispatch, state}, obj){

    },

    changePassword({commit, dispatch, state}, obj){

    },

    sendTwoFactor({commit, dispatch, state}, obj){

    }


}

export default Account_actions;
