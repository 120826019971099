<template>
  <div v-if="item" class="card__menu_header-component">
    <i v-if="item.icon" :class="item.icon" :style="{color: item.iconColor ?? 'auto'}"></i>
    <a v-tooltip.top="$t_(item.label)" @click="toggle" aria-haspopup="true" :aria-controls="`overlay_dtheadermenu_${reference}`"
       :style="{'color': item.color ?? 'auto'}">{{ $t_(item.label) }} <i
        v-if="(this.item.items && this.item.items.length > 0)"
        :class="`pi pi-fw pi-chevron-down dropdown__${reference}`"></i></a>
    <PTieredMenu v-if="(this.item.items && this.item.items.length > 0)" :id="`overlay_dtheadermenu_${reference}`"
                 :ref="reference"
                 :model="item.items" :popup="true"/>
  </div>
</template>

<script>

export default {
  name: "DTComponent",
  props: ['item', 'reference'],

  data: () => ({
    opened:false
  }),

  methods: {
    toggle(event) {
      let link = this.item.to ?? `#`;
      // console.log([this.$refs, this.reference]);
      // (link === '#' && this.item.url) ? link = this.item.url ?? '#' : null;
      (this.item.items && this.item.items.length > 0) ? this.$refs[this.reference].toggle(event) : this.$navigate(link);
    },
  }
}
</script>

<style scoped>

</style>