<template>
  <v-col
      v-if="item"
      :cols="(item.layout && item.layout.cols) ? item.layout.cols : 12"
      :sm="(item.layout && item.layout.sm) ? item.layout.sm : 12"
      :lg="(item.layout && item.layout.lg) ? item.layout.lg : 12"
      :xl="(item.layout && item.layout.xl) ? item.layout.xl : 12"
      :md="(item.layout && item.layout.md) ? item.layout.md : 12"
  >
    <WidgetContainer :item-data="item">
      <template v-if="item.type==='progress_list'" #widget_content_slot>
        <div class="widget" v-for="el in item.items">
          <div class="widget-content">
            <p class="widget-title">{{ el.title ?? "LABEL_NO_TITLE" }}</p>
            <PProgressBar :value="el.value"
                          :style="{'--progressbar-value-color': el.valueColor, '--progressbar-color': el.color}"/>
          </div>
        </div>
      </template>
      <template v-else-if="item.type==='timeline'" #widget_content_slot>
        <WidgetStepSlot :events="item.items"/>
      </template>
      <template v-else-if="item.type==='datatable'" #widget_content_slot>
        <WidgetDatatableSlot :datatable="item.datatable"/>
      </template>
      <template v-else-if="item.type==='form' && item.form && item.form.schema" #widget_content_slot>
        <v-form-base :schema="item.form.schema" :model="item.form.values" @input="updateStore"/>
      </template>
      <template v-else-if="item.type==='upload'" #widget_content_slot>
        <v-custom-file-list :value="item.items" />
      </template>
      <template v-else-if="item.type==='upload_marco'" #widget_content_slot>
        <UploadFiles :value="item.items" />
      </template>
      <template v-else-if="item.type==='tree'" #widget_content_slot>
        <WidgetTreeSlot :items="item.items" />
      </template>

      <template v-else-if="item.type==='c_table'" #widget_content_slot>
        <DatatableExpands :items="item.items" ></DatatableExpands>
      </template>

    </WidgetContainer>
  </v-col>
</template>

<script>
import WidgetContainer from "@/components/widgets/slots/WidgetContainer.vue";
import DatatableExpands from "@/components/widgets/slots/DatatableExpands.vue";

export default {
  name: "WidgetComponent",
  components: {DatatableExpands, WidgetContainer},
  props: ['item'],

  data: () => ({
    showMe: true
  }),

  watch: {
    // item: {
    //   handler() {
    //     this.item.items ? console.log(this.item.items, "---> Updated") : null;
    //   }, deep: true
    // }
  },

  methods: {
    updateFileStore(props) {
      const {on, id, key, value, params, obj, data, schema, parent, index, event, type, values} = props;
      // console.log(props, "---> Props FileStore");
    },
    updateStore(props) {
      const {on, id, key, value, params, obj, data, schema, parent, index, event, type, values} = props;
      // console.log(props, "---> Props VFormStore");
    }
  }

}
</script>

<style scoped>

</style>