
const Import_getters = {

    getImport(state) {
        return state.items
    }

};

export default Import_getters;
