<template>
  <v-app>
    <v-navigation-drawer
        class="main__sidebar elevation-20"
        permanent
        height="100vh"
        app
        v-model="sidebarOpened"
    >
      <v-list>
        <LeftLogo/>
      </v-list>

      <v-list
          nav
          dense
      >
        <v-list-item-group>
          <v-list-item
              v-if="menuItems"
              v-for="(item, i) in menuItems"
              :key="i"
          >
<!--            <v-list-item-content class="sidebar__item">-->
              <SidebarItemGroupComponent v-if="item.items && item.items.length > 0" :key="`sidebar_item_group_${i}`"
                                         :item="item"/>
              <SidebarItemComponent v-else :key="`sidebar_item_${i}`" :item="item"/>
<!--            </v-list-item-content>-->
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>
<!--
 * Created by PhpStorm.
 * User: Dario Pietroleonardo
 * Date: 22/06/2022
 * Time: 15:06
 -->
<script>
import {mapGetters} from "vuex";
// import SidebarItemComponent from "@/components/layout/sidebar/SidebarItemComponent";
// import LeftLogo from "@/components/layout/LeftLogo.vue";
// import SidebarItemGroupComponent from "@/components/layout/sidebar/SidebarItemGroupComponent.vue";

export default {
  name: "VuetifySidebar",
  // components: {
  //   SidebarItemGroupComponent,
  //   SidebarItemComponent,
  //   LeftLogo
  // },

  data: () => ({
    expandedKeys: {},
    sidebarOpened: true,
    options: [],
    menuItems: [],
  }),

  computed: {
    ...mapGetters({
      sidebar: "Main/getSidebar"
    })
  },

  mounted() {
    // this.expandedKeys[this.menuItems[0].key] = true;
    this.sidebarOpened = this.sidebar.sidebar_opened
    this.options = this.sidebar.options ?? []
    this.menuItems = this.sidebar.items ?? []
    // console.log(this.menuItems)
  },

  watch: {
    sidebarOpened: {
      handler(value) {
        this.$store.commit("Main/setSidebarOpened", value)
      }
    }
  },

}
</script>

<style scoped lang="scss">
@import "@/assets/primevue/variables";

//$sidebar_background: transparent linear-gradient(180deg, $icona-scanner-attivi 0%, #A040E9B3 51%, #8A2387 100%) 0% 0% no-repeat padding-box;
//$sidebar_title_font_size: 16px;
//$sidebar_title_padding_top: 8px;
//$sidebar_title_margin_bottom: 40px;
//$sidebar_selection_radius: 15px;

.main__sidebar {
  &.v-navigation-drawer {
    padding: 1em;
    background: $main_gradient_background;
    width: 20rem !important;
  }

  .v-list-item--link {
    border-radius: 25px !important;

    &:before {
      background-color: transparent;
    }
  }

}

</style>