import initialState from "@/constants/InitialState";

const Main_mutations = {
    setConfigInitial(state, data) {
        // let ss = data.sidebar
        // // ss.items.push({
        // //     "name": "frontend.test.index",
        // //     "label": "VForm TEST",
        // //     "key": "2341904927",
        // //     "uri": "/api/test",
        // //     "click": [],
        // //     "method": "GET",
        // //     "binds": [],
        // //     "to": "/test"
        // // });
        let usmen = data.userMenu.map(e => {
            e.uri = null;
            e.to = null;
            return e;
        })

        Object.assign(state.sidebar, data.sidebar)
        Object.assign(state.userMenu, usmen)

        Object.assign(state.configs, data.configs)
        Object.assign(state.options, data.options ?? {})
    },

    setSidebarOpened(state, data) {
        state.sidebar.sidebar_opened = data
    },

    setSidebarObject(state, data) {
        Object.keys(data).forEach(e => state.sidebar[e] = data[e])
        // Object.keys(data).forEach(e => (typeof data[e] === "object") ? Object.assign(state.sidebar[e], data[e]) : state.sidebar[e] = data[e])
    },

    setObject(state, data) {
        Object.keys(data).forEach(e => state[e] = data[e])
        // Object.keys(data).forEach(e => (typeof data[e] === "object") ? Object.assign(state[e], data[e]) : state[e] = data[e])
    },

    setInstall(state,data){
        Object.assign(state.install,data)

    },

    resetState(state) {
        console.log(initialState.MAIN());
        state = initialState.MAIN();
        console.log(initialState.MAIN());
    }
}

export default Main_mutations;
