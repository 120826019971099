<template>
  <PCard class="tabs__component">
    <template #content>
      <v-tabs
          v-model="tab"
          background-color="transparent"
      >
        <v-tab
            class="tabs__header"
            v-for="(item, idx) in items"
            :key="`tab_header_${idx}`"
        >
          <div class="container">
            <i v-if="item.icon" :class="item.icon"></i> {{ $t_(item.label) | filterLabel }}
          </div>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="(item, idx) in items"
            :key="`tab_content_${idx}`"
        >
          <PCard class="tabs__content">

            <template #content>
              <v-form v-if="item.schema" :ref="`custom_form_${idx}`" lazy-validation @submit.prevent>
                <template v-if="item.type!==undefined && item.type==='array'">
                  <GenericVArraySchema :showActions="showActions" :key="`vform_array_${idx}`" :items="item"
                                       @update="updateStore"/>
                </template>
                <template v-else>

                  <v-form-base :schema="$getRulesInput(item.schema)" :model="item.values" @input="updateStore"
                  />
                </template>
              </v-form>
              <DTComponent v-else-if="item.datatable" :datatable="item.datatable" />
            </template>
<!--            <template #footer>-->
<!--              <div class="actions">-->
<!--                <v-btn-->
<!--                    class="modify"-->
<!--                    dark-->
<!--                    :disabled="!showActions"-->
<!--                    large-->
<!--                    @click="() => ({})"-->
<!--                >-->
<!--                  {{ $t_("APP__LABEL_MODIFY_BTN") }}-->
<!--                </v-btn>-->
<!--              </div>-->
<!--            </template>-->
          </PCard>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </PCard>
</template>

<script>

export default {
  name: "TabsComponent",
  props: ["items", "updateStore", "showActions"],
  data() {
    return {
      tab: 0,
    }
  },

  mounted() {
  }
}
</script>

<style scoped>

</style>