<template>
  <div :class="`custom__file_list__container ${direction}`">
    <div class="file__list">
      <v-list two-line class="wrapped">
        <v-list-item
            v-for="(file, idx) in input"
            :key="`${file['name']}_${idx}`"
        >
          <v-list-item-avatar>
            <v-icon
                color="#707070"
                dark
                v-text="file.icon ?? $getFileIcon(file['item-text'])"
            ></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="file['item-text']"></v-list-item-title>

            <!--        <v-list-item-subtitle v-text="file.subtitle"></v-list-item-subtitle>-->
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="removeItem(idx)" class="upload_btn">
              <v-icon color="red darken-3">mdi-close-circle</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
    <div class="file_upload__container">
      <UploadComponent @update="updateFiles" :multiple="true"/>
    </div>
  </div>
</template>

<script>

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: "v-custom-file-list",
  props: ['type', 'value', 'obj'],
  data: () => ({
    // present: []
  }),

  watch: {
    input: {
      handler() {
        // console.log(this.value);
      }
    }
  },
  computed: {
    input: {
      get() {
        // console.log(this.$props);
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    direction() {
      return this.type ? '' : "list_under"
    }
  },

  methods: {

    removeItem(index) {
      // For testing
      // this.input = this.input.substr(0,index) + this.input.substring(index + 1, this.input.length);

      // this.present.includes(this.value[index].name) ? this.present.splice(this.present.indexOf(this.value[index].name), 1) : null;
      this.value.splice(index, 1);
    },

    updateFiles(val) {
      (this.value.filter(el => el.name === val.name).length > 0) ? null : this.value.push(val);
      // (this.value.filter(el => el.name === val.name).length > 0) ? this.present.includes(val.name) ? null : this.present.push(val.name) : this.value.push(val);
    }
  },
}
</script>

<style scoped>

</style>