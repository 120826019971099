<template>
  <v-app>
    <router-view v-if="ready" @routeEvents="testa"/>
    <LoaderComponent :value="!ready"/>
    <ConfirmComponent v-if="displayConfirm"/>


  </v-app>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Sidebar from 'primevue/sidebar';
export default {
  name: "Main",

  computed: {
    ...mapGetters({
      ready: "Main/getReady",
      displayConfirm: "Global/getDisplayDialog",
    }),
  },
  methods:{
    testa(e){
      // console.log(e)
    }
  }
}
</script>

<style scoped>

</style>