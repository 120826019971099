

const Account_mutations = {

    setAccount(state, data) {
        Object.assign(state, data)
    },

    setAccountMenu(state, data) {
        Object.assign(state.menuItems, data ?? [])
    },

    setObject(state, data) {
        Object.keys(data).forEach(e => state[e] = data[e])
        // Object.keys(data).forEach(e => (typeof data[e] === "object") ? Object.assign(state[e], data[e]) : state[e] = data[e])
    },
}

export default Account_mutations;
