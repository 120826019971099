

const Utilities_mutations = {

    setObject(state, data) {
        Object.keys(data).forEach(e => state[e] = data[e])
        // Object.keys(data).forEach(e => (typeof data[e] === "object") ? Object.assign(state[e], data[e]) : state[e] = data[e])
    },

    initUtilities(state, data) {
        Object.assign(state, data)
    }

}

export default Utilities_mutations;
