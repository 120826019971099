import MOCK_DATA from "@/constants/mock_data";
import initialState from "@/constants/InitialState";
import GenericVForm from "@/pages/generic/GenericVForm.vue";
import GenericIndexPage from "@/pages/generic/GenericIndexPage.vue";
import CreateTicket from "@/pages/CreateTicket.vue";

export default ({toastMutation, appTknName = '_app_option'}) => ({


    methods: {

        /**
         * @param {array} - array of obj {keyName, keyValue, ttl}.
         * # keyName - A key to identify the value.
         * # keyValue - A value associated with the key.
         * # ttl- Time to live in seconds (default 60).
         */
        $setLocalStorageTTL(items = []) {
            items.map(el => {
                let data = {
                    value: el.value,
                    ttl: Date.now() + (el.ttl >= 0 ? el.ttl * 1000 : 60000)
                }
                localStorage.setItem(el.name, JSON.stringify(data));
            })
        },
        /**
         * @param {string} keyName - A key to identify the value.
         * @param {any} keyValue - A value associated with the key.
         * @param {number} ttl- Time to live in seconds (default 60).
         */
        // $setLocalStorageTTL(keyName, keyValue, ttl = 60) {
        //     const data = {
        //         value: keyValue,                  // store the value within this object
        //         ttl: Date.now() + (ttl * 1000),   // store the TTL (time to live)
        //     }
        //
        //     // store data in LocalStorage
        //     localStorage.setItem(keyName, JSON.stringify(data));
        // },
        /**
         * @param {string} keyName - A key to identify the data.
         * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
         */
        $getLocalStorageTTL(keyName) {
            const data = localStorage.getItem(keyName);
            if (!data) {     // if no value exists associated with the key, return null
                return null;
            }

            const item = JSON.parse(data);

            // If TTL has expired, remove the item from localStorage and return null
            if (Date.now() > item.ttl) {
                localStorage.removeItem(keyName);
                return null;
            }

            // return data if not expired
            return item.value;
        },

        $removeFromLocalStorage(items = [], removeTkn = false, tknName = appTknName) {
            removeTkn ? sessionStorage.removeItem(tknName) : null;
            items.map(el => {
                localStorage.removeItem(el);
            })
            // localStorage.removeItem('verify_send')
            // localStorage.removeItem('verify_resend')
        },

        /**
         *
         * @param severity Severity of the message (success, info, warn, error).
         * @param summary Title of the message.
         * @param detail Text of the message.
         * @param closable Whether the message can be closed manually using the close icon.
         * @param live Delay in milliseconds to close the message automatically.
         * @param styleClass Style class of the message.
         * @param contentStyleClass Style class of the content.
         * @param onConfirm fn for confirmation toast type.
         * @param onReject fn for confirmation toast type.
         */
        $showToast(toast) {
            // let toast = {};//initialState.GLOBAL.toast;
            // typeof props === 'object' ? Object.assign(toast, props) : toast = props;

            // Object.keys(toast).includes("group") ? null : toast.group = initialState.GLOBAL.toast.group;
            // this.$store.state.Global.toast = toast;
            // props.onConfirm ? toast.group = "confirmation" : null;
            // // props.onReject ? toast.onReject = props.onReject : null;
            // props.removeAll ? this.$toast.removeAllGroups() : props.removeMe ? this.$toast.removeGroup(toast.group) : null;
            toast.goup ? null : toast.group = initialState.GLOBAL().toast.group;
            this.$toast.add(toast);
            setTimeout(() => {
                this.$destroyToast(toast.group, initialState.GLOBAL().toast);
            }, toast.life ? (toast.life + 100) : 3100)
        },

        $destroyToast(group, toast) {
            group ? this.$toast.removeGroup(group) : null;
            toast ? this.$store.commit(toastMutation, {displayToast: false, toast: toast}) : null;
        },

        $deleteSession(props) {
            const {redirect = () => window.location.replace('/'), params = null} = props;

            const group = "topbar";

            let dialog = MOCK_DATA.dialogs(this.$t_).QUESTION;
            dialog.text = this.$t_("SESSION__DIALOG__TEXT");
            dialog.toast_group = group;
            dialog.yesLabel = this.$t_('APP__DIALOG__BUTTON_LABEL__CONFIRM')
            dialog.onYes = () => {
                sessionStorage.clear();
                this.$removeFromLocalStorage([appTknName])
                this.$showToast({
                    // this.$toast.add({
                    // this.setGlobalObj({
                    //   toast: {
                    severity: 'success',
                    summary: this.$t_('APP__MESSAGE__TITLE__DELETE_SESSION__OK'),
                    detail: this.$t_('APP__MESSAGE__DELETE_SESSION__OK'),
                    life: 3000,
                    group: group
                    // }
                });
                // setTimeout(() => {
                //     this.$destroyToast(group, initialState.GLOBAL().toast);
                // }, 2100)

                redirect ? setTimeout(redirect(), 3500) : null;
            };
            dialog.onNo = () => {
                // this.$showToast({
                // this.$toast.add({
                //     severity: 'error',
                //     summary: this.$t_('APP__MESSAGE__TITLE__DELETE_SESSION__KO'),
                //     detail: this.$t_('APP__MESSAGE__DELETE_SESSION__KO'),
                //     life: 2000,
                //     group: group
                // });
                // setTimeout(() => {
                //     this.$destroyToast(group, initialState.GLOBAL().toast);
                // }, 2100)
            }
            dialog.onClose = () => {
                // this.$toast.removeGroup(group);
                // this.$destroyToast(group, initialState.GLOBAL().toast);
            }
            this.$store.commit(toastMutation, {dialog: dialog, displayDialog: true});

        },

        $getFileIcon(fileName) {
            if (fileName) {
                let extension = fileName.split('.')
                let icn;

                switch (extension[extension.length - 1]) {
                    case 'pdf':
                        return "fas fa-file-pdf";
                    case 'csv':
                        return "fas fa-file-csv";
                    case 'xls':
                    case 'xlsx':
                        return "fas fa-file-excel";
                    case 'doc':
                    case 'docx':
                        return "fas fa-file-word";
                    default: {
                        return "fas fa-file";
                    }
                }
            }
        },

        $createBase64Image(fileObject, callback) {
            const reader = new FileReader();

            reader.onload = (e) => {
                callback(new Blob([new Uint8Array(e.target.result)], {type: fileObject.type}));
                // callback(e.target.result);
            };
            reader.readAsDataURL(fileObject);
        },

        /**
         *
         * @param type:  "kebab"(default), "snake"
         */
        $transformString(text, type = "kebab") {
            switch (type) {
                case "snake":
                    return text.replace(/[ _-]+/g, '-').toLowerCase();
                default:
                    return text.replace(/[ _-]+/g, '_').toLowerCase();
            }
        },

        $clickAction(data, row) {

            let configs = this.$store.state.Main.configs;
            let routes = this.$store.state.Routing_Route.store_route;

            if (data.action !== 'deletehis') {
                this.$store.commit('Routing_Route/setCurrentSyncServer', data, {root: true});
                let navigation = this.$routingFromDatatable(data, row, routes);
                let name = data.follow.replaceAll('.', '') + '_' + data.text;
                let cmp = {
                    'GenericVForm': GenericVForm,
                    'GenericIndexPage': GenericIndexPage,
                    'CreateTicket': CreateTicket
                }
                // console.log(this.$route.matched)
                // console.log(this.$router.getRoutes())
                // console.log(navigation)

                let rou = {
                    name: name,
                    path: navigation[0],
                    component: cmp[data.component],
                    props: name.includes('details') ? {
                        ...data.props,
                        config: configs[data.follow],
                        apiPath: data.to
                    } : data.props
                };
                let index = this.$router.getRoutes().findIndex(e => e.name === name)
                // console.log(index);
                let timeout = 500;
                if (index === -1) {
                    // console.log([this.$router.getRoutes(), rou.name]);
                    this.$router.addRoute('backend', rou, () => {
                    });
                } else {
                    Object.keys(this.$router.getRoutes()[index].props.default).forEach(e => {
                        if (data.props[e] !== undefined)
                            this.$router.getRoutes()[index].props.default[e] = data.props[e]
                    })
                    // console.log(this.$router.getRoutes()[index])
                    // this.$router.getRoutes()[index].props.default = {...data.props}
                }
                // console.log(navigation, ' mixin dario')
                setTimeout(() => {
                    this.$navigate(`/${navigation[1]}`)
                    // console.log(this.$router.getRoutes());

                }, timeout)

            } else {
                let dialog = MOCK_DATA.dialogs(this.$t_).WARNING;
                dialog.text = this.$t_("DATATABLE__DIALOG__TEXT__DELETE__RECORD"),
                    dialog.onYes = () => {
                        this.$showToast({
                            severity: 'info',
                            summary: "ToSca",
                            detail: "Cancellazione disabilitata lato Client",
                            life: 3000
                        });
                        // if (configs[data.follow] && configs[data.follow].headers) {
                        //     axios.delete(data.to, {
                        //         headers: configs[data.follow].headers
                        //     }).then(res => {
                        //         this.$showToast({
                        //             severity: 'success',
                        //             summary: this.$t_('APP__MESSAGE__TITLE__DELETED__OK'),
                        //             detail: this.$t_('APP__MESSAGE__DELETED__OK'),
                        //             life: 3000
                        //         });
                        //         if (!res.data.install.status) {
                        //             this.$store.dispatch('Main/initRequests',{_token: this.$getTnk()},{root:true})
                        //         }
                        //
                        //     })
                        //         .catch(error => {
                        //             console.log(error.message);
                        //             this.$showToast({
                        //                 severity: 'error',
                        //                 summary: this.$t_('APP__MESSAGE__TITLE__DELETED__KO'),
                        //                 detail: this.$t_('APP__MESSAGE__DELETED__KO'),
                        //                 life: 3000
                        //             });
                        //         })
                        //         .finally(() => {
                        //             // this.callApi();
                        //             // this.genericCall();
                        //         })
                        // } else {
                        //     this.$showToast({
                        //         severity: 'error',
                        //         summary: this.$t_('APP__MESSAGE__TITLE__DELETED__KO'),
                        //         detail: this.$t_('APP__MESSAGE__DELETED__KO'),
                        //         life: 3000
                        //     });
                        //     console.log("No api headers found")
                        // }
                    }
                this.$store.commit("Global/setObject", {dialog: dialog, displayDialog: true}, {root: true});
            }
        },

        $updateVFormStore(props) {
            console.log(props);
            debugger;
            const {on, id, key, value, params, obj, data, schema, parent, index, event, type, values} = props
            let a = null

            let findKey = ['item_value', 'item-value']
            // console.log(value instanceof Object)
            if (value instanceof Object) {
                for (let i in findKey)
                    a = value[findKey[i]] !== undefined && null === a ? value[findKey[i]] : a
            } else a = value
            // key !== undefined && value !== undefined ?
            //     this.$store.commit("Global/updateValueStore",{
            //         step: this.step - 1,
            //         key: key,
            //         value: a
            //     }, {root: true}) : null

        },

        $castedSplit(stringa) {
            let to_return = stringa

            if(typeof stringa !== 'object') {
                to_return = stringa.split('.').map(e => "^\g".match(e)?parseInt(e):e);
            }

            return to_return;
        },

        /**
         *
         * @param obj :Object
         * @param keys :String
         */
        $checkObjExist(obj, keys, finded = true) {
            if(keys === '') return true
            let keyArray = this.$castedSplit(keys);

            if(keyArray.length >= 1 && obj[keyArray[0]] !== undefined) {
                return this.$checkObjExist(obj[keyArray[0]], keyArray.splice(1), true);
            }
            return !(keyArray.length > 0);
            // if (keyArray.length > 0 && finded === true) {
            //     if (Object.keys(obj).includes(keyArray[0])) {
            //        return this.$checkObjExist(obj[keyArray[0]], keyArray.splice(1).join('.'), true);
            //     }
            // }
            //     return false
        }

    }
})