<template>
  <v-spacer>
    <p v-if="title" :class="`custom__v-spacer ${classes}`" :style="styles"><i v-if="icon" :class="icon"></i> {{title}}</p>
  </v-spacer>
</template>

<script>
export default {
  name: "v-custom-spacer",
  props: ['type', 'value', 'obj'],

  computed: {
    icon(){
      return this.value.icon
    },
    title(){
      return this.value && this.value.title ? this.value.title : ""
    },
    classes(){
      return this.value.class ?? ""
    },
    styles() {
      return this.value.style ?? ""
    }

  },
}
</script>

<style scoped>

</style>