
const Ticket_getters = {

    getTickets(state) {
        return state.tickets
    },

    getPaginator(state) {
        return state.paginator
    },

    getHistory(state) {
        return state.history
    },


    getOpenRightSidebar(state){
        return state.openRightSidebar
    },

    getRightSidebar(state) {
        return state.aside;
    },

    getCurrentPropsEl(state){
        return state.currentProps
    },

    getSchemaData(state){
        return state.schema
    },
    getModelEl(state){
        return state.model
    },
    getMaxTT(state){
        return Math.max(...[].concat(state.tickets.map(e=>e.tt)))
    },
    getMaxId(state){
        return Math.max(...[].concat(state.tickets.map(e=>e.id)))
    }

};

export default Ticket_getters;
