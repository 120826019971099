<template>
  <MainWrapper/>
  <!--  <v-idle-->
  <!--      @idle="onidle"-->
  <!--      :loop="true"-->
  <!--      :duration="(options && options.idle && options && options.idle.duration) ? parseInt(options.idle.duration) : 300"/>-->
</template>

<script>
// import Main from "@/components/Main.vue";
// import GenericIndexPage from "@/pages/generic/GenericIndexPage.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'App',
  // components: {
  //   Main,
  // GenericIndexPage
  // },

  watch: {
    logged: {
      handler() {
        // setTimeout(() => {
        this.setMainObject({ready: false})
        !this.logged ? this.$navigateName('login') : this.$navigate(this.$router.currentRoute.fullPath) //this.$navigateName('backend')
        // }, 200)
      }
    },
  },

  computed: {
    ...mapGetters({
      logged: "Main/getLogged",
      options: "Main/getOptions",
      install: "Main/getStatusApplication"
    }),
  },

  methods: {
    ...mapMutations({
      setMainObject: "Main/setObject"
    }),
    ...mapActions({
      init: "Main/initRequests"
    }),

    initialize() {
      this.init({_token: this.$getTnk()})
    },

    onidle() {
      this.$removeFromLocalStorage(["verify-send", "verify-resend"],true);
      this.initialize()
    },
    onremind(time) {
      // alert seconds remaining to 00:00
      alert(time);
    }
  },

  mounted() {
    this.initialize()


    setTimeout(() => {
      (this.$router.currentRoute.name === "reset-password" || this.$router.currentRoute.name === "reset-link")
          ? this.$navigate(this.$router.currentRoute.fullPath)
          : !this.logged ? this.$navigateName('login') :
              ((!this.install.status) ? this.$navigate(this.install.run) :
                  this.$navigate(this.$router.currentRoute.fullPath))
    }, 1200)
  },

}
</script>