<template>
  <!--    <div v-if="ready" class="auth__container">-->
    <PCard>
        <template #title>
            <p>To<span>Sca</span></p>
        </template>
        <template #subtitle>
            {{ $t_('AUTH__SUBTITLE__RESET_PASSWORD') }}
        </template>
        <template #content>
            <div class="auth__retype_password">
                <div key="old_pwd_key" :class="{'p-inputgroup': true, 'invalid': !oldPwdValid}">
                    <PPassword required :placeholder="$t_('AUTH__PLACEHOLDER__OLD_PASSWORD')" v-model="oldPwd"
                               :inputClass="`border-all ${oldPwdValid ? '' : 'invalid'}`" toggleMask
                               :promptLabel="$t_('AUTH__PLACEHOLDER__OLD_PASSWORD')" :feedback="false">
                    </PPassword>
                </div>
                <div key="new_pwd_key" :class="{'p-inputgroup': true, 'invalid': !newPwdValid}">
                    <PPassword required :placeholder="$t_('AUTH__PLACEHOLDER__NEW_PASSWORD')" v-model="newPwd"
                               :inputClass="`border-all ${newPwdValid ? '' : 'invalid'}`" toggleMask
                               :promptLabel="$t_('AUTH__PLACEHOLDER__NEW_PASSWORD')"
                               :weakLabel="$t_('AUTH__LABEL__PASSWORD_WEAK')"
                               :mediumLabel="$t_('AUTH__LABEL__PASSWORD_MEDIUM')"
                               :strongLabel="$t_('AUTH__LABEL__PASSWORD_STRONG')">
                        <template #footer>
                            <PDivider/>
                            <p class="mt-2">{{ $t_('AUTH__TITLE__PASSWORD_SUGGESTIONS') }}</p>
                            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_ONE_LETTER_LOW') }}</li>
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_ONE_LETTER_UPPER') }}</li>
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_ONE_NUMBER') }}</li>
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_MIN_CHAR') }}: 6</li>
                            </ul>
                        </template>
                    </PPassword>
                </div>
                <div key="check_pwd_key" :class="{'p-inputgroup': true, 'invalid': !checkPwdValid}">
                    <PPassword required :placeholder="$t_('AUTH__PLACEHOLDER__RETYPE_PASSWORD')"
                               v-model="checkPwd"
                               :inputClass="`border-all ${checkPwdValid ? '' : 'invalid'}`" toggleMask
                               :promptLabel="$t_('AUTH__PLACEHOLDER__RETYPE_PASSWORD')"
                               :weakLabel="$t_('AUTH__LABEL__PASSWORD_WEAK')"
                               :mediumLabel="$t_('AUTH__LABEL__PASSWORD_MEDIUM')"
                               :strongLabel="$t_('AUTH__LABEL__PASSWORD_STRONG')">
                        <template #footer>
                            <PDivider/>
                            <p class="mt-2">{{ $t_('AUTH__TITLE__PASSWORD_SUGGESTIONS') }}</p>
                            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_ONE_LETTER_LOW') }}</li>
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_ONE_LETTER_UPPER') }}</li>
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_ONE_NUMBER') }}</li>
                                <li>{{ $t_('AUTH__LABEL__PASSWORD_MIN_CHAR') }}: 6</li>
                            </ul>
                        </template>
                    </PPassword>
                </div>
            </div>

            <div class="auth__actions">
                <div class="p-inputgroup">
                    <PButton :disabled.sync="disableSend" class="border-all secondary-btn"
                             v-tooltip.top="$t_('AUTH__BUTTON__TITLE__SEND__RESET_PASSWORD')"
                             @click.prevent="sendReset">
                        {{ $t_('AUTH__BUTTON__TITLE__SEND__RESET_PASSWORD') }}
                    </PButton>
                </div>
            </div>

        </template>
    </PCard>
  <!--    </div>-->
  <!--    <LoaderComponent :value="!ready" />-->
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MOCK_DATA from "@/constants/mock_data";

export default {
    props: ["token"],
    data: () => ({
        ready: false,
        oldPwd: '',
        oldPwdValid: '',
        newPwd: '',
        newPwdValid: '',
        checkPwd: '',
        checkPwdValid: '',
        tokens: null,
        sendUrl: '/action-user/resetter/making/',
    }),

    watch: {
        logged: {
            handler() {
                setTimeout(() => {
                    this.logged ? this.$navigateName('backend') : this.ready = true //this.$navigateName('backend')
                }, 1200)
            }
        },
        oldPwd: {
            handler() {
                this.oldPwdValid = this.oldPwd.length >= 6;
            }
        },
        newPwd: {
            handler() {
                this.newPwdValid = this.newPwd.length >= 6;
            }
        },
        checkPwd: {
            handler() {
                this.checkPwdValid = (this.checkPwd.length >= 6 && this.checkPwd === this.newPwd);
            }
        },
    },

    computed: {
        ...mapGetters({
            logged: "Main/getLogged"
        }),

        disableSend() {
            return !this.oldPwdValid || !this.newPwdValid || !this.checkPwdValid;
        },

    },

    methods: {
        ...mapMutations({
            setMainObject: "Main/setObject",
            setGlobalObj: "Global/setObject",
        }),

        resetInputs() {
            this.oldPwd = '';
            this.newPwd = '';
            this.checkPwd = '';
        },

        sendReset() {
            let dialog = MOCK_DATA.dialogs(this.$t_).INFO;
            dialog.text = this.$t_("AUTH__DIALOG__TEXT_MODIFY__PWD");
            dialog.loader = {
                mode: "indeterminate",
                fakeProgress: true,
                fakeMessages: [
                    this.$t_('AUTH__DIALOG__PROGRESS_REQUEST_TAKEN'),
                    this.$t_('AUTH__DIALOG__PROGRESS_REQUEST_PROCESSING'),
                    this.$t_('AUTH__DIALOG__PROGRESS_COMPLETED')
                ],
                fakeDelay: 1000,
                closeOnEnd: () => {
                     this.$navigateName('login');
                     this.setMainObject({ready: true});
                }
            }
            dialog.hideButtons = true;
            this.setGlobalObj({dialog: dialog});
            this.setGlobalObj({displayDialog: true});
            // this.setMainObject({ready: false})
            // this.oldPwd = '';
            // this.newPwd = '';
            // this.checkPwd = '';
            axios.post(`${this.sendUrl}${this.token}`, {
                    old: this.oldPwd,
                    password: this.newPwd,
                }
                // , {
                //   headers: {
                //     Authorization: `Bearer ${this.token}`
                //   }
                // }
            )
                .then(res => {
                    this.setMainObject({ready: true})
                    if (!res.err) {
                        this.$navigateName('backend');
                    } else {
                        this.oldPwd = '';
                        this.newPwd = '';
                        this.checkPwd = '';
                    }
                    // console.log("eccomi")
                    // this.$navigateName('login');


                })
                .catch(error => console.log(error.message))
                .finally(() => this.setGlobalObj({displayDialog: true}))

        }
    },

    mounted() {
        // sessionStorage.removeItem('_reset_options');
        // console.log(this.$route.params, "---> Route param");
        // this.sendReset();
    },

    beforeMount() {
        // console.log(document.referrer, '---> Referrer');
    }

}
</script>

<style scoped>

</style>