import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import Account from "./Account";
import Main from "./Main";
import Utilities from "./Utilities";
import Global from "./Global";
import Routing_Route from "./Routing/Route";
import Widgets from "./Widgets";
import Datatable from "./Datatable";
import Ticket from "./Ticket";
import Import from "./Import";
//prepend_import

Vue.use(Vuex)
Vue.use(VueRouter)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Account,
    Main,
    Utilities,
	Global,
	Routing_Route,
	Widgets,
	Datatable,
	Ticket,
	Import,
	//prepend_module
  }
})
