<template>
  <div class="generic__page" style="row-gap:0">
    <div class="card__menu_header" style="padding: 0">
      <v-container>
      <v-row dense>
        <v-col cols="3">
          <DTHeaderComponent v-for="(hitem, idx) in $store.state.Ticket.actions" :item="hitem"
                             :reference="`dt_header_menu_${idx}`"
                             :key="`dt_header_menu_${idx}`"/>
        </v-col>

      </v-row>
      <v-row dense>
        <v-col v-if="aside" cols="12">
          <v-sheet elevation="6" max-width="100%" height="60px">
            <v-tabs
                background-color="transparent"

                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-form-base class="pt-2" style="flex-wrap:nowrap" id="my-bases" :key="vformKey" v-if="aside" :schema="aside.schema" :model="aside.values"
                           @input="updateElements"

              >
                <template v-for="i in Object.keys(aside.schema)">

                  <v-tab
                    :key="`elem_tab_${i}`"  :slot="`slot-item-key-${i}`" class="slot"
                  >
                   {{i}}
                  </v-tab>

                </template>


              </v-form-base>
            </v-tabs>
          </v-sheet>

        </v-col>
      </v-row>
      </v-container>

    </div>

    <template v-if="!canShow">

      <v-skeleton-loader
          :key="`tk_skeleton_${key}`"
          v-for="(i, key) in max_item"
          class="mx-auto"
          width="100%"
          type="card"
      ></v-skeleton-loader>
    </template>

    <template v-else>

      <v-virtual-scroll
          :bench="benched"
          :items="curTickets"
          height="79vh"
          item-height="205px"
          @scroll="checkPage"

      >
        <template v-slot:default="{ item,index }">
          <!--          <v-lazy
                        :options="{
                    threshold: .1
                  }"
                        min-height="200"
                        transition="fade-transition"
                        v-if="tickets.length"
                        :key="`tk__card_list_${index}__update__`"
                    >-->
          <PCard style="max-width:98%" :id="item.id"
                 v-model="item.isActive" class="elevate card__list" :closed="item.closed"
                 :ref="`ticket_nav_${item.id}`">
            <template #title >
              <v-layout row wrap>
                <v-flex xs6>
                  <div class="subtitle-2">
                    <span>#{{ item.id }}</span> - <span :style="item.closed ? 'color: #F00;' : ''">{{ item.stato }}</span>
                    <div>
                      <p class="mb-0" v-if="item.scanner!==null">{{ $t_('TK__CARD__TITLE__SCANNER') }}
                        {{ item.scanner.serial_number }}</p>
                      <p class="mb-0 custom__p-ellipsis" v-tooltip.bottom="item.pdv ? `PDV ${item.pdv}` : ''">{{ item.pdv ? `PDV ${item.pdv}` : '' }}</p>
                      <p v-if="item.description" v-tooltip.bottom="item.description"
                         class="mb-0 custom__p-ellipsis">{{ $t_('APP__LABEL__DESCRIPTION') }}: {{ item.description}}</p>
                    </div>

                  </div>
                </v-flex>
                <v-flex xs6>
                  <template v-if="!item.closed" >
                    <div class="subtitle-1" style="line-height:20px;text-align:right">
                      {{ $t_('TK__CARD__LABEL__REMAINING__TIME') }}:
                      {{ item.actions.time_close.value }}
                      {{ item.actions.time_close.format }}
                      <v-row dense style="align-items: center">
                        <v-col cols="4">
                          <v-switch
                              v-model="item.vForms.ticket.values.closed"
                              :class="item.vForms.ticket.schema.state_id.closed"
                              :label="$t_('TK__FORM__SWITCH__LABEL__STATUS')"
                              @change="updateTicket({closed:item.vForms.ticket.values.closed}, index, item.id) "
                          />
                        </v-col>
                        <v-col cols="8">
                          <v-select
                              v-model="item.vForms.ticket.values.state_id"
                              dense
                              :class="item.vForms.ticket.schema.state_id.class"
                              filled
                              color="purple"
                              rounded
                              item-text="item_text"
                              item-value="item_value"
                              :items="item.vForms.ticket.schema.state_id.items"
                              @change="updateTicket({state_id:item.vForms.ticket.values.state_id}, index, item.id) "
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </template>

                </v-flex>
              </v-layout>

            </template>
            <template #footer>

              <v-layout row wrap class="pl-5 pr-5">
                <v-flex xs12 sm6 class="pb-5 pt-5">
                                    <span class="p-buttonset" style="display: inline-flex" v-if="item.links">
                                        <PButton :key="`tk_card_list_${index}_btn_${item.id}`"
                                                 icon="pi pi-info"
                                                 class="p-button-rounded btn__details p-button-sm"
                                                 :title="$t_('APP__BUTTON__TITLE__DETAILS')"
                                                 :v-tooltip.top="$t_('APP__BUTTON__TITLE__DETAILS')"
                                                 @click="showDetails(item)">
                                            {{ $t_('APP__BUTTON__TITLE__DETAILS') }}
                                        </PButton>
                                        <PButton v-for="(act,ind) in item.links"
                                                 :key="`tk_card_list_${index}_btn_${ind}`"
                                                 :icon="`${act.icon !== '' ? act.icon : Object.keys(primeIcons).includes(act.text) ? primeIcons[act.text] : 'pi pi-info'}`"
                                                 class="p-button-rounded p-button-sm p-button-secondary"
                                                 :title="$t_(act.text)"
                                                 v-tooltip.top="`${$t_(act.text)}`"
                                                 @click="$clickAction(act, item)"/>
                                    </span>
                </v-flex>
                <v-flex xs12 sm6 class="text-right">
                  <div v-if="item.vForms.ticket.schema && item.vForms.ticket.values"
                       class="card__list__vform">

                    <v-row dense>
                      <v-col cols="12">
                        <v-combobox
                                    multiple
                                    append-icon="mdi mdi-circle-double"
                                    dense
                                    class="pr-2"
                                    :light="false"
                                    placeholder="Inserisci i Tags"
                                    label="Tags"
                                    color="purple"
                                    deletable-chips
                                    :disabled="item.closed"
                                    small-chips
                                    :items="['esc:ReissRomoli','nrm:GeselOp']"
                                    :value="item.tags"
                                    @change="sendTagsElements(item)"
                                    v-model="item.tags">
                          <template v-slot:selection="data">
                            <v-chip
                                :key="JSON.stringify(data.item)"
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                label
                                small
                                :disabled="data.disabled"
                                @click:close="data.parent.selectItem(data.item)"
                            >
                              <v-avatar
                                  class="orange white--text"
                                  left
                                  v-text="data.item.slice(0, 1).toUpperCase()"
                              ></v-avatar>
                              {{ data.item }}

                            </v-chip>
                          </template>
                        </v-combobox>


                      </v-col>

                    </v-row>

                  </div>



                </v-flex>
              </v-layout>

              <v-row dense style="margin-top:-20px;" class="">
                <v-col cols="6">
                  <template v-if="null!==item.children">
                                                  <span class="subtitle-1" style="font-size: xx-small !important;">
                                                    {{ $t_('TK__LABEL__LINKED__TK') }}: {{ item.children.length }}
                                                  </span>
                  </template>
                  <template v-if="null!==item.derived_from">
                    <span class="subtitle-1" style="font-size: xx-small !important;">
                                                    {{ $t_("TK__LABEL__DERIVED__FROM") }}: <a
                        @click="findElement(item.derived_from.id)">#{{
                        item.derived_from.id
                      }}</a>
                                                  </span>
                  </template>

                </v-col>
                <v-col cols="6" class="align-right">
                  <template v-if="item.attachments!==null">
                    <span v-for="(ii,kk) in item.attachments"  class="subtitle-1" style="font-size: xx-small !important;">
                      {{map[kk]}}: {{ii | booleanAttachments}} <v-icon small :color="ii?'green':'warning'">
                      {{ii | checkIcon}}
                    </v-icon>
                    </span>
                  </template>
                </v-col>
              </v-row>
            </template>
          </PCard>
          <!--          </v-lazy>-->
        </template>
      </v-virtual-scroll>


    </template>
    <!--    <DTPaginatorComponent :paginate="paginator" @selectPage="selectPage"/>-->
    <PButton
        if="!showAside"
        v-if="true == false"
        icon="pi pi-filter"
        class="p-button-sm right__sidebar_btn"
        v-tooltip.left="$t_('TK__LABEL__RIGHT_SIDEBAR__FILTER')"
        @click="setTicketsObj({openRightSidebar: true})"
    />
    <PSidebar v-if="true == false" position="right" :visible.sync="$store.state.Ticket.openRightSidebar"
              :modal="false" :dismissable="false" :showCloseIcon="true"
              role="region">
      <v-form-base class="pt-5" :key="vformKey" v-if="aside" :schema="aside.schema" :model="aside.values"
                   @input="updateElements"

      />
      <PButton :disabled="!aside" class="border-all" @click="setFilter"
               v-tooltip.bottom="$t_('TK__LABEL__APPLY__FILTER')">{{ $t_("TK__LABEL__APPLY__FILTER") }}
      </PButton>
    </PSidebar>
  </div>
</template>

<script>
import primeIcons from "@/constants/PrimeIcons";


import {mapActions, mapGetters, mapMutations} from "vuex";
import initialState from "@/constants/InitialState";
import MOCK_DATA from "@/constants/mock_data";
const httBQ = require('http-build-query');
export default {
  name: "Tickets",
  data: () => ({
    map:{
      attachment_ddt: "DDT",
      attachment_doa: "DOA",
      attachmend_ddt_rientro: "DDT Rientro",
      attachmend_other: "Altro",
    },
    canShow: false,
    showSidebar: true,
    max_item: 4,
    keyed: 0,
    curTickets: {},
    apiFilter: '',
    vformKey: 0,
    benched: 6,
    pages: [],
    localFilter:{},
    interval: null,

  }),
  props: [
    'title',
    'titleIcon',
    'routeName',
    'apiPath',
    'method',
    'config',
    'ui',
    'page_id'
  ],

  watch: {
    tickets: {
      handler() {
        // console.log(this.tickets.length, '------_> SONO IN TICKETS')
        this.curTickets = this.tickets
        // console.log(this.curTickets)
        this.canShow = this.tickets.length > 0
      }, deep: true
    }
  },

  filters: {
    currentState: function (e) {
      return e['item_text']
    },
    booleanAttachments: function(e){
      return e?'Presente':'Non presente'
    },
    checkIcon: function(e){
      return e?'mdi-check':'mdi-alert'
    }

  },

  computed: {
    ...mapGetters({
      tickets: "Ticket/getTickets",
      paginator: "Ticket/getPaginator",
      history: "Ticket/getHistory",
      getState: "Utilities/getStates",
      showAside: "Ticket/getOpenRightSidebar",
      aside: "Ticket/getRightSidebar",
      getMaxTt: "Ticket/getMaxTT",
      getMaxId: "Ticket/getMaxId",
    }),

    cnf() {
      return (null === this.config || this.config === undefined) ? {headers: this.$getheaders()} : this.config;
    },

    primeIcons() {
      return primeIcons
    },

    sideFilter() {
      let filter = {};
      this.vformKey > 0 ? this.vformKey-- : this.vformKey++;
      Object.keys(this.aside.values).filter(e => Boolean(this.aside.values[e])).map(e => filter[e] = this.aside.values[e]['item-value'] !== undefined
          ? this.aside.values[e]['item-value'] : this.aside.values[e])
      this.setTicketsObj({
        aside: {...this.aside, values: {page: null, ...filter}},
        filterData: Object.keys(filter).length > 0 ? filter : null
      });
      return filter;
    }
  },
  mounted() {
    // this.setTicketsObj(MOCK_DATA.tickets(this.$t_));
    // console.log('SONO QUI',this.$options.props)
    this.apiFilter = this.apiPath;

    if (!this.tickets.length) {
      this.fetchData({})
      this.setIntervalElement()
    } else {
      this.curTickets = this.tickets
      this.canShow = true;
    }


    // !this.history || this.history.length <= 0 ? this.setTicketsObj({history: {"page1": MOCK_DATA.tickets(this.$t_).tickets}}) : null;
  },

  beforeMount() {
    this.$store.commit("Global/initToast", 'topbar')
  },

  destroyed() {
    // this.setTicketsObj({aside: initialState.TICKETS().aside});
    this.setTicketsObj({openRightSidebar: initialState.TICKETS().openRightSidebar});

  },
  methods: {
    ...mapMutations({
      setTicketsObj: "Ticket/setObject",
      setCurrentPage: "Ticket/setCurrentPage",
      setGlobalObj: "Global/setObject"
    }),

    ...mapActions({
      fetchTickets: "Ticket/fetchData",
      partialUpdate: "Ticket/partialUpdate",
      infiniteScrollAct: "Ticket/inifiniteScroll"
    }),
    setIntervalElement(){
      if(null===this.interval){
        this.interval=setInterval(()=>{

          this.infiniteScrollAct({
            apiPath: `/api/tickets?tt=${this.getMaxTt}&cid=${this.getMaxId}`,
            method: "GET",
            headers: this.cnf.headers,
            params: null,
          })
        },parseInt(this.$store.getters['Main/getOptions'].polling.ttl));
      }

    },
    checkPage(e) {
      // console.log(e.target.scrollTop, e.target.scrollHeight, e.target.offsetHeight, e.target.scrollTop + e.target.offsetHeight);

      // console.log(parseInt((parseInt(e.target.scrollTop + e.target.offsetHeight)/e.target.scrollHeight)*100))

      // console.log(this.paginator.current_page);

      if (parseInt((parseInt(e.target.scrollTop + e.target.offsetHeight) / e.target.scrollHeight) * 100) >= 60) {
        if (this.paginator.current_page < this.paginator.total) {
          this.selectPage({page: this.paginator.next_page_url})
          let url = this.paginator.next_page_url+'&'+httBQ(this.localFilter)
          if (!this.pages.includes(url)) {
            this.pages.push(url)
            this.infiniteScrollAct({
              apiPath: url,
              method: this.method ?? "GET",
              headers: this.cnf.headers,
              params: null,
            })

          }


        }
      }


      // console.log(e.target.scrollTop, e.target.scrollHeight);
      // console.log(e.target.scrollTop);
    },

    findElement(e){

      this.infiniteScrollAct({
        apiPath: `${this.apiPath}?id=${e}`,
        method: this.method ?? "GET",
        headers: this.cnf.headers,
        params: null,
        oth:{
          id: e
        }
      })
    },
    intersectionState(t) {
      return this.getState.filter(e => t.includes(e.id))
    },

    pageNavigation(el, pos) {
      var element = this.$refs[el];
      var top = element.offsetTop;

      window.scrollTo(pos, top);

    },

    updateElements({ on, key, obj, params }){

      this.localFilter[key] = obj.value!==null?obj.value['item-value']:null
        console.log(this.localFilter)
        this.localFilter=Object.fromEntries(
            Object.entries(this.localFilter)
                .filter(([_, value]) => value != null)
                .map(([key, value]) => [
                    key,
                    value === Object(value) ? removeNull(value) : value,
                ]),
        );

      if(Object.entries(this.localFilter).filter(([k,e])=>e!==null).length){
        clearInterval(this.interval)
          this.interval=null;
      }else{
        this.setIntervalElement()
      }
      this.setFilter()


      //console.log(on,key,obj,params)
    },

    setFilter() {

      this.apiFilter = this.apiPath.split(['?'])[0] + `?${httBQ(this.localFilter)}`;
      this.fetchData({});
    },

    sendFilter() {
      this.$router.currentRoute.name !== "frontend.tickets.index" ? this.$router.push({
        name: "frontend.tickets.index_paginatore",
        params: {id: 1}
      }) : null;
      this.fetchData({});

    },

    showDetails(item) {
      let dialog = MOCK_DATA.dialogs(this.$t_).INFO;
      dialog.text = [{title: this.$t_('TK__CARD__LABEL__GROUP'), text: item.ticket_group},
        {title: this.$t_('TK__CARD__LABEL__GROUP_CURRENT_STATE'), text: item.stato},
        {title: this.$t_('TK__CARD__LABEL__DESCRIPTION'), text: item.state.description}];

      this.setGlobalObj({dialog: dialog});
      this.setGlobalObj({displayDialog: true});
    },

    fetchData({params = null}) {

      this.setCurrentPage(1)
      this.fetchTickets({
        apiPath: this.apiFilter,
        method: this.method ?? "GET",
        headers: this.cnf.headers,
        params: params,
      })


    },
    sendTagsElements(el){
      axios({
        url: '/api/ticket-tags/'+el.id,
        method: "PUT",
        headers: this.cnf.headers,
        data: el
      }).then(res=>{

      })
    },

    updateTicket(props, index, tkId) {
      // let cnf = this.config
      // if (null === this.config || undefined === this.config) {
      //   cnf = {headers: this.$getheaders()}
      // }
      const {key, value} = props;
      this.tickets[index][key] = value;
      this.setTicketsObj({tickets: this.tickets});
      // console.log(this.tickets[index].vForms.ticket.values, key, value, tkId,this.apiPath)
      this.partialUpdate({
        apiPath: `/api/tickets/${tkId}`,
        method: "PUT",
        headers: this.cnf.headers,
        params: props,
        localUpdateIndex: index,
        updateInHistory: true
      })
    },

    selectPage(data) {
      // this.setTicketsObj({aside: {...this.aside, values: {...this.aside.values, page: data.page}}});
      this.page = data.page
      /*if(this.history[data.page]!==undefined){
        this.setTicketsObj(this.history[data.page]);
        this.setCurrentPage(data.page)
      }else{
        this.fetchTickets({
          apiPath: data.url,
          method: 'GET',
          headers: this.config.headers,
          params: null
        })
      }*/
    },

  },


}
</script>

<style scoped lang="scss">

.right__sidebar_btn {
  position: fixed;
  right: 0;
}

.card__list {
  &.elevate {
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.2) !important;
  }

  p {
    &.custom__p-ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 1.5rem;
    }
  }
}

</style>