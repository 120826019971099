<template>
  <!--    <div  class="auth__container">-->
  <PCard>
    <template #title>
      <p>To<span>Sca</span></p>
    </template>
    <template #subtitle>
      {{ $t_('AUTH__SUBTITLE__OTP_VERIFY') }}
    </template>
    <template #content>
      <form>
        <p class="auth__verify-description">
          Inserisci il codice di autenticazione che hai ricevuto per email.
        </p>
        <div :class="{'p-inputgroup': true, 'invalid': false, 'number__mask': true}">
          <PInputMask v-for="idx in  5" :id="`mask_input_code_${idx}`" :key="`mask_input_code_${idx}`" mask="9"
                      v-model="value[idx]" @keyup="makeCode" @click="onCodeClick" @change="makeCode"
                      @keydown.enter="sendVerify"/>
        </div>

        <div class="auth__actions">
          <div class="p-inputgroup">
            <PButton :disabled="!resendUrl" class="border-all secondary-btn" @click="resendCode"
                     v-tooltip.top="$t_('AUTH__BUTTON__TITLE__RESEND_CODE')">{{ $t_("AUTH__BUTTON__LABEL__RESEND_CODE") }}
            </PButton>
          </div>
          <div class="p-inputgroup">
            <PButton :disabled="(emailCode.length !== 5 || !sendUrl)" type="submit" class="border-all"
                     @click="sendVerify"
                     v-tooltip.top="$t_('AUTH__BUTTON__TITLE__VERIFY')">{{ $t_('AUTH__BUTTON__LABEL__VERIFY') }}
            </PButton>
          </div>
          <div class="p-inputgroup right">
            <PButton class="border-all p-button-link" v-tooltip.top="$t_('AUTH__BUTTON__TITLE__GO_BACK')" @click="goBack">
              {{ $t_('AUTH__BUTTON__LABEL__GO_BACK') }}
            </PButton>
          </div>
        </div>
      </form>
    </template>
  </PCard>
  <!--    </div>-->
  <!--    <LoaderComponent :value="!ready" />-->
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "LoginVerify",

  data: () => ({
    ready: false,
    emailCode: '',
    value: {},
    resendUrl: localStorage.getItem('verify_resend'),
    sendUrl: localStorage.getItem(('verify_send')),
    token: null,
  }),

  watch: {
    resendUrl: {
      handler() {
        !this.resendUrl || !this.sendUrl || !this.resendUrl ? this.$navigateName('login') : null
      }
    }
    // logged() {
    //   setTimeout(() => {
    //     this.logged ? this.$navigateName('backend') : this.ready = true //this.$navigateName('backend')
    //   }, 1200)
    // }
  },

  computed: {
    ...mapGetters({
      logged: "Main/getLogged"
    }),
  },

  methods: {
    ...mapMutations({
      initConfig: "Main/setConfigInitial",
      setMainObject: "Main/setObject",
      setAccount: "Account/setAccount",
      // setDashboard: "Dashboard/setDashboard"
    }),
    ...mapActions({
      init: "Main/initRequests"
    }),

    initialize() {
      this.init({_token: this.$getTnk(), reload: true})
    },

    makeCode(e) {
      //FIXME (Risposta nell'altro FIXME): madr a che serve questa chiamata????
      this.getFromLocalStorage();

      this.$validateMasckedCode(e);


      if (!this.resendUrl || !this.sendUrl || !this.token) {
        this.$navigateName('login')
      } else {
        this.emailCode = Object.values(this.value).join('')
      }
    },

    onCodeClick(e) {
      e.currentTarget.select();
    },

    getFromLocalStorage() {
      // this.token = this.$getTnk()//sessionStorage.getItem('jwt_token')
      this.sendUrl = this.$getLocalStorageTTL('verify_send')
      this.resendUrl = this.$getLocalStorageTTL('verify_resend')
    },

    // removeFromLocalStorage() {
    //   // localStorage.removeItem('jwt_token')
    //   localStorage.removeItem('verify_send')
    //   localStorage.removeItem('verify_resend')
    // },

    goBack() {
      this.$removeFromLocalStorage(["verify_send", "verify_resend"], true);
      this.$navigateName('login');
    },

    sendVerify() {
      // console.log((emailCode.length !== 5 || !sendUrl),"ciccio");
      if (this.emailCode.length !== 5 || !this.sendUrl) return null;

      this.getFromLocalStorage()
      this.setMainObject({ready: false})
      axios.post(this.sendUrl, {
        code: this.emailCode,
      }, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
          .then(res => res.data)
          .then(res => {
            this.$setTnk(res.token);
            // sessionStorage.setItem('jwt_token', res.token)
            // console.log(res, "---> Verify")
            // this.initConfig(res.webUi)
            this.$removeFromLocalStorage(["verify_send", "verify_resend"])
            this.initialize()
            // this.setMainObject({logged: res.isLogged})
            // this.setMainObject({ready: true})
            this.$navigate(`/${process.env.BASE_URL}`)
          })
          .catch((error) => {
            console.log(error.message)
          })
          .finally(() => this.setMainObject({ready: true}))
    },

    resendCode() {
      this.setMainObject({ready: false})
      //FIXME (Risposta): madr a che servono tutti sti getFromLocal se stanno nel VDOM prop? -- É una fn che mi verifica il TTL aggiunto da me alle chiavi ed eventualmente me le cancella
      this.getFromLocalStorage()

      axios.get(this.resendUrl, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
          .finally(() => this.setMainObject({ready: true}))
    }
  },

  mounted() {
    this.token = this.$getTnk();
    // setTimeout(() => {
    //   this.logged ? this.$navigateName('backend') : this.ready = true//this.$navigateName('backend')
    // }, 200)
    !this.resendUrl || !this.sendUrl || !this.token ? this.$navigateName('login') : null

    document.getElementById('mask_input_code_1').focus()
  },

}
</script>

<style scoped>

</style>