<template>

  <div>
    <div v-if="items!==false" v-for="(itm, idx) in items">
      <PCard :key="`widget_tree_container_${idx}`"
             :class="`elevate tk__`">
        <template #content>

          <div class="tk__card__container_left">
            <a class="title__link__tk"  @click.prevent="$router.push(`/details-ticket/${itm.id}/view`,()=>{})">
              <span>#{{ itm.id }}</span>
            </a>
            <p>
              <span>{{ $t_('TK__CARD__LABEL__GROUP') }}:</span>{{ itm.ticket_group }}
            </p>
            <p>
              <span>{{ $t_('TK__CARD__LABEL__STATE') }}:</span>{{ itm.stato }}
            </p>
          </div>


        </template>


      </PCard>
      <template v-if="itm.children.length > 0">
        <RecursiveIndent class="ml-5" :items="itm.children"></RecursiveIndent>
      </template>

    </div>

  </div>


</template>

<script>
export default {
  name: "RecursiveIndent",
  props:["items"],
  mounted() {
    // console.log(this.items)
  }

}
</script>

<style scoped>

</style>