

import Widgets_getters from "./_getters";
import Widgets_mutations from "./_mutations";
import Widgets_actions from "./_actions";
import initialState from "@/constants/InitialState";

const Widgets = {

    namespaced: true,
    state: () => initialState.WIDGETS(),

    getters:{
        ...Widgets_getters
    },
    mutations:{
        ...Widgets_mutations
    },
   actions:{
        ...Widgets_actions
    }

}

export default Widgets;
