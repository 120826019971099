export default {
    "show": "pi pi-eye",
    "edit": "pi pi-pencil",
    "delete": "pi pi-trash",
    "view": "fas fa-info",
    "dialog-info": "pi pi-info-circle",
    "dialog-question": "pi pi-question-circle",
    "dialog-error": "pi pi-exclamation-triangle",
    "dialog-warning": "pi pi-exclamation-triangle",
    "dialog-upload": "pi pi-upload",
    "ticket": "fa-solid fa-ticket",
}