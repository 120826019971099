<template>
  <PTimeline :value="events" class="stepper">
    <template #marker="slotProps">
      <div :class="slotProps.item.disabled ? 'disabled' : ''">
            <span class="step-marker shadow-2"
                  :style="{backgroundColor: slotProps.item.bgColor ?? 'auto', color: slotProps.item.color ?? 'auto'}">
              <i class="mdi mdi-truck"></i>
<!--              <i :class="slotProps.item.icon"></i>-->
            </span>

      </div>
    </template>
    <template #opposite="slotProps">
      <div :class="slotProps.item.disabled ? 'disabled' : ''">
        <small class="p-text-secondary">{{ slotProps.item.disabled ? '' : slotProps.item.date }}</small>
      </div>
    </template>
    <template #content="slotProps">
      <div :class="slotProps.item.disabled ? 'disabled' : ''">
        <p class="step-title">
          {{ slotProps.item.state.description }}
        </p>
        <!--        <p class="step-actions" >-->
        <!--          <a href="#" title="Dettagli">dettagli</a>-->
        <!--        </p>-->
        <PButton :disabled="slotProps.item.disabled" class="p-button-link" :title="$t_('TK__TIMELINE_DETAILS_TITLE')"
                 @click="clickDetails(slotProps.item)">
          {{ $t_('TK__TIMELINE_DETAILS_LABEL') }}
        </PButton>
      </div>
    </template>
    <template #connector>
      <div class="p-timeline-event-connector"></div>
    </template>
  </PTimeline>
</template>

<script>
import MOCK_DATA from "@/constants/mock_data";

export default {
  name: "WidgetStepSlot",
  props: ['events'],
  // data: () => ({
  //   events: [
  //     {
  //       status: 'Spedito',
  //       date: '15/10/2020 10:30',
  //       icon: 'mdi mdi-truck',
  //       bgColor: "#673AB7",
  //       color: '#FFF',
  //       image: 'game-controller.jpg'
  //     },
  //     {
  //       status: 'Consegnato a Mario Rossi',
  //       date: '15/10/2020 14:00',
  //       icon: 'mdi mdi-package-variant-closed',
  //       bgColor: "#FF9800"
  //     },
  //     {status: 'Installato', date: '15/10/2020 16:15', icon: 'pi pi-link', bgColor: '#4FCAC6'},
  //     {status: 'Attivato', date: '16/10/2020 10:00', icon: 'pi pi-check', bgColor: '#E31079'}
  //   ]
  // })

  methods: {
    clickDetails(item) {
     // console.log(item);
      let dialog = MOCK_DATA.dialogs(this.$t_).INFO;
      dialog.title = item.status;
      dialog.text = item.state.description; //"Urbss sunt itineris tramitems de alter candidatus.Cum accentor observare, omnes lunaes demitto domesticus, castus buboes.Elogiums mori in clemens hamburgum!";
      this.$store.commit("Global/setObject", {dialog: dialog});
      this.$store.commit("Global/setObject", {displayDialog: true});
    }
  }
}
</script>

<style scoped>

</style>