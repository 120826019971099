<template>
  <div class="generic__page">
    <div class="card__menu_header">

      <v-layout row wrap v-if="dati!==null">

        <v-flex xs12>

          <v-card class="pa-1">
            <v-card-title>
              <h1>Bind Event:: {{dati.name}}</h1>
            </v-card-title>

            <v-card-text>

              <v-layout row wrap>
                <v-flex xs8 class="pr-1">
                  <h4>Oggetto Email</h4>
                  <v-text-field class="ma-2" xs12 v-model="dati.setting.model.object"></v-text-field>
                  <h4>Corpo Email</h4>
                 <VueEditor v-model="dati.setting.model.text_mail" />
                </v-flex>
                <v-flex xs4 class="pl-1">
                  <div v-for="i in loop" :key="i.t">
                    <h4>Mail {{i.t}}</h4>
                    <v-combobox multiple chips clearable
                                v-model="dati.setting.model.data[i.e]"></v-combobox>

                  </div>
                </v-flex>
              </v-layout>

            </v-card-text>



            <v-card-actions>
              <v-btn @click="updateData" color="orange" absolute right>
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>




        </v-flex>


        <v-flex xs12>
          <v-expansion-panels>
            <v-expansion-panel
                v-for="item in Object.keys(dati.variables)"
                :key="item"
            >
              <v-expansion-panel-header>
                <h5>Variabili per {{item}}</h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <div v-for="el in dati.variables[item]" :key="el">
                  Var: <span>{{el}}</span>
                </div>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>


      </v-layout>


    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "ZEvents",
  props:["id"],
  data(){
    return{
      headers:null,
      dati:null,
      loop:[
        {"t":"A","e":"to"},
        {"t":"Cc","e":"cc"},
        {"t":"Bcc","e":"bcc"},
      ]
    }
  },
  components: {
    VueEditor
  },

  mounted() {
    this.headers=this.$getheaders();
    this.getData();
  },
  methods:{
    getData(){

      axios({
        method:'GET',
        url:`/api/events/${this.id}`,
        headers:this.headers
      }).then(res=>{
        this.dati=res.data;
      })


    },
    updateData(){

      axios({
        method:'PUT',
        url:`/api/events/${this.id}`,
        headers:this.headers,
        data: this.dati
      }).then(res=>{
        this.dati=res.data
      })

    }
  }


}
</script>

<style scoped>

</style>