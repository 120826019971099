
const Datatable_getters = {

    getDatatable(state) {
        return state
    },
    getPaginator(state){

        return state.paginate
    }

};

export default Datatable_getters;
