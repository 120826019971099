<template>
  <div :class="`custom__file_list__container ${direction}`">
    <div class="file__list_">
      <v-list two-line class="wrap_ped">
        <v-list-item
            v-for="(file, idx) in input"
            :key="`${file['name']}_${idx}`"
        >
          <v-list-item-avatar>
            <v-icon
                v-if="file['file_name']"
                color="#707070"
                dark
                @click.prevent="openAttachment(file)"
                v-text="file.icon ?? $getFileIcon(file['file_name']) ?? 'fa fa-file'"
            ></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <!--            :value="getFileObject(file)"-->
            <v-file-input

                show-size
                :multiple="file.multiple"
                :label="file['file_name'] ?? file['item-text']"
                @mousedown="current = idx"
                @change="uploadFile"
                :clearable="!file['file_name']"
                :append-icon="file['file_name'] ? 'mdi mdi-close' : null"
                @click:append="clearFile"
            ></v-file-input>


            <!--        <v-list-item-subtitle v-text="file.subtitle"></v-list-item-subtitle>-->
          </v-list-item-content>

          <v-list-item-action>
            <!--            <v-btn icon @click="removeItem(idx)" class="upload_btn">-->
            <!--              <v-icon color="red darken-3">mdi-close-circle</v-icon>-->
            <!--            </v-btn>-->
          </v-list-item-action>
        </v-list-item>
      </v-list>

    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFiles",
  props: ['type', 'value', 'obj'],
  data: () => ({
    // present: []
    current: null,
  }),

  watch: {
    input: {
      handler() {
        // console.log(this.value);
      }
    }
  },
  computed: {
    input: {
      get() {
        // console.log(this.$props);
        return this.value;
      },
      set(val) {
        // console.log(val);
        this.$emit('input', val)
      }
    },

    direction() {
      return this.type ? '' : "list_under"
    }
  },
  methods: {
    getFileObject(file) {
      if (file.original_url !== undefined)
        return window.URL.createObjectURL(file.original_url)
      else return ''
    },
    openAttachment(el) {
      // console.log(el);
      el.original_url ? window.open(el.original_url) : null;
    },
    removeItem(index) {
      // For testing
      // this.input = this.input.substr(0,index) + this.input.substring(index + 1, this.input.length);

      // this.present.includes(this.value[index].name) ? this.present.splice(this.present.indexOf(this.value[index].name), 1) : null;
      this.value.splice(index, 1);
    },

    updateFiles(val) {
      (this.value.filter(el => el.name === val.name).length > 0) ? null : this.value.push(val);
      // (this.value.filter(el => el.name === val.name).length > 0) ? this.present.includes(val.name) ? null : this.present.push(val.name) : this.value.push(val);
    },
    uploadFile(ev) {
      // console.log([ev, this.value[this.current], this.current], "---> Ev");
      let formData = new FormData();
      (ev && Object.keys(ev).length > 0) ? ev.forEach(el => formData.append(this.current, el)) : formData.append(this.current, ev);
      // formData.append(this.current, ev);
      // console.log(this.$route.params)
      let cnf = this.$getheaders();
      cnf['content-type'] = "multipart/form-data"
      axios.post(`/api/v1/attach-ticket/${this.$route.params.id}`, formData, cnf)
          .then(res => res.data)
          .then(res => {
            (res.media && Object.keys(res.media).includes(this.current)) ? this.value[this.current] = res.media[this.current] : this.value[this.current] = {
          "name": null,
        }
      })

    },

    clearFile() {
      this.value[this.current] !== undefined ? this.value[this.current] = {
        "name": null,
      } : null;
      this.uploadFile(null);
    }
  },
}
</script>

<style scoped>

</style>