<template>
  <v-stepper v-model="stepper" alt-labels >
    <v-stepper-header style="{'--steps': item.length}"
                      v-if="item.length > 1"
    >
      <template v-for="(v,n) in item">
        <v-stepper-step
            :key="`${n}-step`"
            :complete="stepper > n"
            :step="n + 1"

            :class="stepper === n ? 'v-stepper__step--active custom':''"
        >
          {{ $t_(item[n].label) | filterLabel }}
        </v-stepper-step>

        <v-divider
            v-if="n !== item.length - 1"
            :key="n"
        ></v-divider>
      </template>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
          v-for="(v,n) in item"
          :key="`${n}-content`"
          :step="n"
      >
        <v-card
            class="mb-12 stepper__content"
        >
          <v-form :ref="`custom_form_${n}`" lazy-validation @submit.prevent>
            <template v-if="item[n].type!==undefined && item[n].type==='array'">
              <GenericVArraySchema :showActions="showActions" :key="`vform_array_${n}`" :items="item[n]"
                                   @update="updateStore"/>
            </template>
            <template v-else>

              <v-form-base :schema="$getRulesInput(item[n].schema)" :model="item[n].values" @input="updateStore"
              />
            </template>
          </v-form>
        </v-card>

        <div class="stepper__footer-actions">
          <v-btn
              v-if="n >= 1"
              class="prev"
              dark
              large
              @click="prevStep(n)"
          >
            {{ $t_("APP__LABEL_PREV_BTN") }}
          </v-btn>
          <v-btn
              v-if="n < item.length-1"
              class="next"
              dark
              large
              @click="nextStep(n)"
          >
            {{ $t_("APP__LABEL_NEXT_BTN") }}
          </v-btn>
          <v-btn
              v-else-if="savable===undefined || savable"
              class="save"
              dark
              :disabled="!showActions"
              large
              @click="sendData(item)"
          >
            {{ $t_("APP__LABEL_SAVE_BTN") }}
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";

export default {
  name: "StepperComponent",
  props: ["baseModel", "updateStore", "savable", "sendData", "showActions"],
  data: () => ({
    stepper: 0,
  }),

  computed: {
    ...mapGetters({
      step: "Global/getCurrentStep",
      item: "Global/getCurrentItem",
    })
  },

  methods: {
    ...mapMutations({
      setObject: "Global/setObject",
    }),

    prevStep(n) {
      if (n > 0 && n <= this.item.length) {
        // this.stp = n - 1
        this.setObject({step: this.stepper})
        this.stepper--
      }
    },
    nextStep(n) {
      let str = `custom_form_${n}`
      Object.keys(this.$refs).forEach(r => {
        if (r === str) {
          // console.log(this.$refs[r][0].validate());
          if (this.$refs[r][0].validate())
            if (n >= 0 && n < this.item.length) {
              this.stepper++
              this.setObject({step: this.stepper + 1})
              // this.stp = n + 1
            }
        }
      })
    },

  },

  mounted() {
    // console.log(this.item);
  }

}

</script>

<style scoped lang="scss">

</style>