import { render, staticRenderFns } from "./DatatableExpands.vue?vue&type=template&id=1670ee20&scoped=true&"
import script from "./DatatableExpands.vue?vue&type=script&lang=js&"
export * from "./DatatableExpands.vue?vue&type=script&lang=js&"
import style0 from "./DatatableExpands.vue?vue&type=style&index=0&id=1670ee20&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1670ee20",
  null
  
)

export default component.exports