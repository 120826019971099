<template>
  <div v-if="user" class="mr-5 ml-10 profile__header-container">
    <PAvatar :image="noImage" shape="circle" size="large" v-tooltip.bottom="(user && user.name) ? user.name : 'Anonimous'"
             class="profile__header-avatar align-center"/>
    <div class="profile__header-drop_down">
      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <PButton type="button" class="pa-0 bg-transparent profile__header-drop_down-content"
                   aria-haspopup="true" aria-controls="profile_menu"
                   style="min-width: auto;" icon="fa fa-caret-down"
                   v-bind="attrs"
                   v-on="on"
          />
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <PAvatar :image="noImage" v-tooltip="(user && user.name) ? user.name : $t_('APP__TITLE__GUEST_USER')"/>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                    user.name && user.name.trim().length > 0 ? user.name : $t_('APP__TITLE__GUEST_USER')
                  }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="user.email">{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item-group
                color="primary"
            >
              <v-list-item
                  v-for="(el, idx) in menuItems"
                  :key="`user_menu_item_${idx}`"
              >
                <v-list-item-content style="text-transform: capitalize;" v-if="el.click && el.click.length > 0" @click="clickAction(el)">
                  <v-list-item-title v-text="$t_(el.label)"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title v-text="$t_(el.label)"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import initialState from "@/constants/InitialState";

export default {
  name: "ProfileHeaderButton",

  data: () => ({
    noImage: `${process.env.BASE_URL}no-image.jpg`,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),

  watch: {
    user: {
      handler() {
        // console.log(this.user)
      }
    }
  },

  computed: {
    ...mapGetters({
      user: "Account/getAccount",
      menuItems: "Main/getUserMenu",
      getAllRoutes: "Routing_Route/getAllRoutes"
    })
  },

  methods: {
    ...mapMutations({
      setGlobalObj: "Global/setObject",
      setMainObj: "Main/setObject",
    }),

    toggle(event) {
      this.$refs.profile_menu.toggle(event);
    },

    findEv(e) {
      // console.log(e)
    },

    clickAction(value) {
      if (value.click.length > 0) {
        Object.keys(this).includes('$' + value.click[0]) ? this['$' + value.click[0]](() => this.followLink(value)) : this.followLink(value);
      }
    },

    followLink(value) {
      if (this.getAllRoutes[value.name] !== undefined) {
        let obj = this.getAllRoutes[value.name];
        axios({
          method: obj.options.method,
          url: '/api/' + obj.options.base,
          headers: obj.headers,
        }).then(res => {
          this.setMainObj(initialState.MAIN());
          setTimeout(() => {
            this.setMainObj({ready: true});
          }, 200)
          this.$navigateName('login');
        })

      }
    }
  },

  mounted() {

  }
}
</script>

<style scoped>

</style>