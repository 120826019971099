<template>

  <v-layout row wrap>
    <template v-if="show">
      <v-flex lg8 md8 sm12 class="">

        <v-card class="mr-2 pa-4">
          <v-card-title>
            Info Ticket
          </v-card-title>
          <v-card-text>
            <v-text-field
                v-model="local_values.delivery_referent"
                :label="local_schema.delivery_referent.label"
                :value="local_values.delivery_referent"
                :color="local_schema.delivery_referent.color"
                :class="local_schema.delivery_referent.class"
            >

            </v-text-field>

            <v-textarea
                v-model="local_values.other_referent"
                :label="local_schema.other_referent.label"
                :value="local_values.other_referent"
                :color="local_schema.other_referent.color"
                :class="local_schema.other_referent.class"
                hint="Scrivimi"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-btn class="vform__btn primary full-width" block @click="canSendCreateUpdate">
              Salva Dati
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-flex>

      <v-flex lg4 md4 sm12 class="">


        <v-card class="ml-2 pa-4">
          <v-card-text>
            <v-combobox
                v-model="local_values.pdv_id"
                :items="getOnlyPdv"
                hide-selected
                clearable
                :label="local_schema.pdv_id.label"
                :color="local_schema.pdv_id.color"
                :class="local_schema.pdv_id.class"
                :item-text="local_schema.pdv_id['item-text']"
                :item-value="local_schema.pdv_id['item-value']"
            >
            </v-combobox>

            <v-combobox
                v-model="local_values.scanner_id"
                :items="getOnlyScanner"
                hide-selected
                clearable
                :label="local_schema.scanner_id.label"
                :color="local_schema.scanner_id.color"
                :class="local_schema.scanner_id.class"
                :item-text="local_schema.scanner_id['item-text']"
                :item-value="local_schema.scanner_id['item-value']"
            >
            </v-combobox>
            <v-combobox
                v-model="local_values.warehouse_id"
                :items="getOnlyWarehouse"
                hide-selected
                v-if="local_values.scanner_id===null && local_values.pdv_id===null"
                :disabled="getOnlyWarehouse.length===0 || (local_values.scanner_id===null && local_values.pdv_id===null) "
                clearable
                :label="local_schema.warehouse_id.label"
                :color="local_schema.warehouse_id.color"
                :class="local_schema.warehouse_id.class"
                :item-text="local_schema.warehouse_id['item-text']"
                :item-value="local_schema.warehouse_id['item-value']"
            >
            </v-combobox>

            <v-divider></v-divider>

            <v-combobox
                v-model="local_values.ticket_group_id"
                :items="getOnlyGroupByScan"
                hide-selected

                clearable
                :label="local_schema.ticket_group_id.label"
                :color="local_schema.ticket_group_id.color"
                :class="local_schema.ticket_group_id.class"
                :item-text="local_schema.ticket_group_id['item-text']"
                :item-value="local_schema.ticket_group_id['item-value']"
            >
            </v-combobox>

            <v-combobox
                v-model="local_values.state_id"
                :items="getOnlyAccetableStates"
                hide-selected
                :disabled="local_values.ticket_group_id==='' || local_values.ticket_group_id===null"
                clearable
                :label="local_schema.state_id.label"
                :color="local_schema.state_id.color"
                :class="local_schema.state_id.class"
                :item-text="local_schema.state_id['item-text']"
                :item-value="local_schema.state_id['item-value']"
            >
            </v-combobox>
          </v-card-text>

        </v-card>



      </v-flex>

    </template>

    <v-flex xs12 class="mt-2">
      <PCard v-if="showTickets" class="generic__page elevate" style="margin-top: 1em;">
        <template #title>
          <p :class="`generic__page-title `">
            {{ $t_("APP__TITLE__STORICO_TK") }}
          </p>
        </template>
        <template #content>
          <div class="generic__page-content">
            <template v-for="item in getFilteredList">
              <v-card class="pa-2">
                <v-card-title>
                  {{item}}
                </v-card-title>
                <v-card-text>
                  <ExpanderComponent :items="getCurrentItemAccordion(item)" :key="`_elem_tickets_type_${item}`"> </ExpanderComponent>
                </v-card-text>
              </v-card>

            </template>

          </div>
        </template>
      </PCard>

    </v-flex>


  </v-layout>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
const httBQ = require('http-build-query');
export default {
  name: "CreateTicket",
  props:["isNew","id"],

  beforeDestroy() {

    this.resetSchema({});
  },
  data(){
    return{

      show:false,
      local_schema:{},
      local_values:{},
      locatePath:{
        isNew: '/api/v1/global-schema/ticket',
        edit: '/api/v1/global-schema/ticket/:id/edit'
      },
      showTickets: false,
      server_response:{},
    }
  },
  computed:{
    ...mapGetters({
      fromLocal:"Ticket/getCurrentPropsEl",
      schema:"Ticket/getSchemaData",
      values:"Ticket/getModelEl",
    }),

    getOnlyScanner(){
      if(this.local_values.pdv_id === '' || null===this.local_values.pdv_id)
        return this.local_schema.scanner_id.items
      else{
        let el = this.local_schema.scanner_id.items.filter(e=>parseInt(e.pdv_id) === parseInt(this.local_values.pdv_id.id??this.local_values.pdv_id))
        let appindm=[];

        this.local_schema.scanner_id.items.filter(e=>e.pdv_id===null && null!==e.warehouse_id &&  [21,22].includes(e.warehouse_id))
            .forEach(e=>appindm.push(e))


        appindm.forEach(e=>el.push(e))

        if(el.length === 1)
          this.local_values.scanner_id = el[0]
        return el;

      }
    },
    getOnlyPdv(){
      if(this.local_values.scanner_id === '' || null===this.local_values.scanner_id)
        return this.local_schema.pdv_id.items
      else{

        if(null===this.local_values.pdv_id)
          return this.local_schema.pdv_id.items


        let el = this.local_schema.pdv_id.items.filter(e=>parseInt(e.id) === parseInt(this.local_values.pdv_id.id??this.local_values.scanner_id.pdv_id))
        if(el.length === 1)
          this.local_values.pdv_id = el[0]
        return el;
      }
    },
    getOnlyWarehouse(){
      if(this.local_values.warehouse_id === '' || null===this.local_values.warehouse_id)
        return this.local_schema.warehouse_id.items
      else{
        if(null===this.local_values.warehouse_id)
          return this.local_schema.warehouse_id.items


        let el = this.local_schema.warehouse_id.items.filter(e=>parseInt(e.id) === parseInt(this.local_values.warehouse_id.id??this.local_values.warehouse_id))
        if(el.length === 1)
          this.local_values.warehouse_id = el[0]
        return el;
      }
    },
    getOnlyGroupByScan(){
      let el = this.local_schema.ticket_group_id.items;
      if(this.local_values.scanner_id&&
         this.local_values.scanner_id['item-text'] &&
          this.local_values.scanner_id['item-text'].includes('* '))
        el = el.filter(e=>e.id===2)

 

      return el;
    },
    getOnlyAccetableStates(){
      let el =  this.$store.getters[this.local_schema.state_id.items]
      // console.log(el)
      // console.log(typeof(this.local_schema.ticket_group_id),this.local_values.ticket_group_id,this.local_schema.ticket_group_id.length)
      if( this.local_schema.ticket_group_id  === '' || null===this.local_values.ticket_group_id){
        this.local_values.state_id = null;
        el = []
      }else{
        if(this.local_schema.ticket_group_id === null )el=[]
        else{
          if(!Array.isArray(this.local_values.ticket_group_id.accetable))
            el = []
          else{

            el = el.filter(e=>this.local_values.ticket_group_id.accetable.includes(e.id))

          }
          if(el.length === 1)
            this.local_values.state_id = el[0]
        }
      }

      return el;
    },


    /******MIX UTL*****/
    getFilteredList: function(){
      return  [...new Set([].concat(this.server_response.tickets.map(e => e.pdv + ' ' + e.scanner)))]
    },


  },
  watch:{
    local_values:{
      handler(){
        // console.log(this.local_values)
      },
      deep:true
    },

    /******FROM SERVER ****/
    server_response: {
      handler() {
        this.canShow = null !== this.server_response;
        this.showTickets = (this.server_response && this.server_response.tickets && this.server_response.tickets.length > 0);
      },
      deep: true
    },


    /*********END ***********/


  },
  mounted() {
    this.getModel();
  },
  methods:{
    ...mapMutations({
      setModel:"Ticket/setModel",
      setSchema:"Ticket/setSchema",
      resetSchema:"Ticket/resetOnlyUiProps",
      aggiornaDati:"Ticket/setUpdateAppendObject"
    }),
    getModel(){
      let loc = {
        ...this.fromLocal,
        ...{simple: true}
      }
      // console.log(this.$options.propsData)
      // let url = `/api/v1/global-schema/ticket?${httBQ(loc)}`;
      let url = this.locatePath.isNew;
      if(this.isNew!==undefined && !this.isNew)
        url = this.locatePath.edit.replace(':id',this.id)
      url +=`?${httBQ(loc)}`

      axios({
        method: "get",
        url: url,
        headers: this.$getTnk()
            ? {Authorization: `Bearer ${this.$getTnk()}`} : null

      }).then(res => {
        // console.log(res.data)
        this.setGlobalData(res)

        // console.log(this.local_values, 'sosoososo');
        // this.setPrimaryObjects()
        this.show = !this.show;


      }).catch(err=>{

      })
    },

    getCurrentItemAccordion(fil){
      return this.server_response.tickets.map(e => e.pdv + ' ' + e.scanner === fil ? e : null).filter(e=>e!=null)
    },

    canSendCreateUpdate(){
      let v = {...this.local_values}
      let send=true;
      let map= ['pdv_id','scanner_id','ticket_group_id','state_id'];
      map.map(e =>
          v[e]!==null && v[e].id!==undefined?v[e]=v[e].id:null
      )
      map.map(e=>
          ((v[e]===null || v[e]==='') && send)?send=false:null
      )


      if(send)
        this.sendCreateUpdate(v)

    },
    sendCreateUpdate(data){

      axios({
        method:'POST',
        url: '/api/v1/global-schema/mem/tickets?simple=1',
        headers: this.$getTnk()
            ? {Authorization: `Bearer ${this.$getTnk()}`} : null,
        data: data
      }).then(res=>{

        //Aggiorna il ticket locale key: ticket


        //aggiorna lo stato locale
        this.$router.push('/tickets')
        this.setGlobalData(res)
        let tmp = res.data
        tmp.tickets = res.data.ticket;
        this.aggiornaDati(tmp)

      }).catch(err=>{

      })


    },

    setGlobalData(res){
      this.server_response=res.data
      this.local_schema=res.data.schema
      this.local_values=res.data.values
      if(Number.isInteger(this.local_values.ticket_group_id))
        this.local_values.ticket_group_id=this.local_schema.ticket_group_id.items.filter(e=>e.id===this.local_values.ticket_group_id)[0]

      if(Number.isInteger(this.local_values.state_id)){

        this.local_values.state_id=this.$store.getters[this.local_schema.state_id.items].filter(e=>e.id===this.local_values.state_id)[0]
      }

      if(this.local_values.warehouse_id===undefined)
        this.local_values.warehouse_id=null

      if(Number.isInteger(this.local_values.warehouse_id))
        this.local_values.warehouse_id=this.local_schema.warehouse_id.items.filter(e=>e.id===this.local_values.warehouse_id)[0]

      if(Number.isInteger(this.local_values.pdv_id))
        this.local_values.pdv_id=this.local_schema.pdv_id.items.filter(e=>e.id===this.local_values.pdv_id)[0]

      if(Number.isInteger(this.local_values.scanner_id))
        this.local_values.scanner_id=this.local_schema.scanner_id.items.filter(e=>e.id===this.local_values.scanner_id)[0]
    }




  }
}
</script>

<style scoped>

</style>